import React from "react";
import I18n from "shared/lib/I18n";
import { displayAsString } from "shared/functions/Date";
import "./Data.css";

const Data = ({ review, id_lang, locale }) => {
    return (
        <div className="p-4">
            <div className="row">
                <div className="col">
                    <div>
                        <strong>
                            <I18n t="album.view.reviews.writer" />
                        </strong>
                        <span className="mx-1">:</span>
                        {review.author.id
                            ? `${review.author.fullname} - ${review.author.signature}`
                            : "Music Story"}
                    </div>
                    <div>
                        <strong>
                            <I18n t="album.view.reviews.updated_at" />
                        </strong>
                        <span className="mx-1">:</span>
                        {displayAsString(review.updated_at, locale)}
                    </div>
                </div>
                <div className="col text-right">
                    {!["JA", "CD_JOURNAL"].includes(id_lang) && (
                        <div>
                            <span className="mr-1">{review.nb_words}</span>
                            <strong>
                                <I18n t="album.view.reviews.words" />
                            </strong>
                        </div>
                    )}
                    <div>
                        <span className="mr-1">{review.nb_characters}</span>
                        <strong>
                            <I18n t="album.view.reviews.signs" />
                        </strong>
                    </div>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col">
                    <strong className="d-inline-block mb-3">
                        <I18n t="album.view.reviews.review" />
                    </strong>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: review.review_body,
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default Data;

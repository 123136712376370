import React from "react";
import { Button, InputGroup } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import { Controller } from "react-hook-form";
import InputSelect from "shared/components/Form/InputSelect";
import Loader from "shared/components/Loader";

const Translation = ({
    location,
    control,
    isSubmitting,
    isLoading,
    handleSubmit,
    updReviewLangFrom,
    lang,
    reviewLangOptions,
    isReference,
    setToTranslate,
}) => {
    return (
        <Controller
            control={control}
            name="id_lang"
            render={({ field: { onChange, value, ref } }) => (
                <InputGroup className="w-auto">
                    <InputSelect
                        placeholder={I18n.getTranslation(
                            location,
                            "artist.management.reviews.translation.placeholder"
                        )}
                        ref={ref}
                        value={
                            value !== null
                                ? reviewLangOptions(lang).find(
                                      (option) =>
                                          String(option.value) === String(value)
                                  )
                                : null
                        }
                        options={reviewLangOptions(lang)}
                        onChange={(opt) => {
                            onChange(opt ? opt.value : null);
                            updReviewLangFrom(opt ? opt.value : null);
                            setToTranslate(!!opt);
                        }}
                        isDisabled={isReference}
                        isClearable={true}
                    />
                    <InputGroup.Append>
                        <Button
                            variant="primary"
                            disabled={value === null}
                            onClick={!isSubmitting ? handleSubmit : null}
                        >
                            {isSubmitting || isLoading ? (
                                <Loader size="1x" />
                            ) : (
                                <I18n t="utils.button.translate" />
                            )}
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            )}
        />
    );
};

export default Translation;

import React from "react";
import { NavLink } from "shared/lib/I18n";
import I18n from "shared/lib/I18n";
import { Tab, Nav } from "react-bootstrap";
import ArtistTitle from "./ArtistTitle";
import Profile from "./Profile";
import Editorial from "./Editorial";
import Biography from "./Biography";
import Review from "./Review";
import Matching from "./Matching";
import Curation from "./Curation";
import Getty from "./Getty";
import Label from "./Label";
import Charts from "./Charts";
import Tracking from "./Tracking";
import Discography from "./Discography";
import { Helmet } from "react-helmet";
import "./ArtistManagement.css";

const ArtistManagement = ({ selectedTab, onSelectTab, user, location }) => {
    return (
        <div className="content-wrap artist-management">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "artist.search.breadcrumb"
                )} - ${I18n.getTranslation(
                    location,
                    "artist.management.breadcrumb"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb mb-2">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item">
                        <NavLink to={`/artist`}>
                            <I18n t="artist.search.breadcrumb" />
                        </NavLink>
                    </li>
                    <li className="breadcrumb-item active">
                        <I18n t="artist.management.breadcrumb" />
                    </li>
                </ol>
                <Tab.Container
                    id="artist-management-tab"
                    activeKey={selectedTab}
                    defaultActiveKey="profile"
                    onSelect={onSelectTab}
                    mountOnEnter
                >
                    <div className="row border-bottom border-light">
                        <div className="col">
                            <ArtistTitle />
                            <Nav variant="pills">
                                <Nav.Item>
                                    <Nav.Link eventKey="profile">
                                        <I18n t="artist.management.profile" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="editorial">
                                        <I18n t="artist.management.editorial" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="biography">
                                        <I18n t="artist.management.biography" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="reviews">
                                        <I18n t="artist.management.reviews.head" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="discography">
                                        <I18n t="artist.management.discography" />
                                    </Nav.Link>
                                </Nav.Item>
                                {user.hasRight(
                                    "artist.manage.picture.curation"
                                ) && (
                                    <Nav.Item>
                                        <Nav.Link eventKey="curation">
                                            <I18n t="artist.management.curation" />
                                        </Nav.Link>
                                    </Nav.Item>
                                )}
                                {user.hasRight(
                                    "artist.manage.picture.getty"
                                ) && (
                                    <Nav.Item>
                                        <Nav.Link eventKey="getty">
                                            <I18n t="artist.management.getty" />
                                        </Nav.Link>
                                    </Nav.Item>
                                )}
                                {user.hasRight(
                                    "artist.manage.picture.label"
                                ) && (
                                    <Nav.Item>
                                        <Nav.Link eventKey="labelPicture">
                                            <I18n t="artist.management.labelPicture" />
                                        </Nav.Link>
                                    </Nav.Item>
                                )}
                                {user.hasRight("artist.matching.partners") && (
                                    <Nav.Item>
                                        <Nav.Link eventKey="matching">
                                            <I18n t="artist.management.matching" />
                                        </Nav.Link>
                                    </Nav.Item>
                                )}
                                <Nav.Item>
                                    <Nav.Link eventKey="charts">
                                        <I18n t="artist.management.charts" />
                                    </Nav.Link>
                                </Nav.Item>
                                {user.hasRight(
                                    "artist.tracking.plannings",
                                    "artist.tracking.pictures",
                                    "artist.tracking.logs"
                                ) && (
                                    <Nav.Item>
                                        <Nav.Link eventKey="tracking">
                                            <I18n t="artist.management.tracking" />
                                        </Nav.Link>
                                    </Nav.Item>
                                )}
                            </Nav>
                        </div>
                    </div>
                    <Tab.Content className="nav-content overflow-hidden">
                        <Tab.Pane eventKey="profile" className="nav-pane">
                            <Profile />
                        </Tab.Pane>
                        <Tab.Pane eventKey="editorial" className="nav-pane">
                            <Editorial />
                        </Tab.Pane>
                        <Tab.Pane eventKey="biography" className="nav-pane">
                            <Biography />
                        </Tab.Pane>
                        <Tab.Pane eventKey="reviews" className="nav-pane">
                            <Review />
                        </Tab.Pane>
                        {user.hasRight("artist.disco.manage") && (
                            <Tab.Pane
                                eventKey="discography"
                                className="nav-pane"
                            >
                                <Discography />
                            </Tab.Pane>
                        )}
                        {user.hasRight("artist.manage.picture.curation") && (
                            <Tab.Pane eventKey="curation" className="nav-pane">
                                <Curation />
                            </Tab.Pane>
                        )}
                        {user.hasRight("artist.manage.picture.getty") && (
                            <Tab.Pane eventKey="getty" className="nav-pane">
                                <Getty />
                            </Tab.Pane>
                        )}
                        {user.hasRight("artist.manage.picture.label") && (
                            <Tab.Pane
                                eventKey="labelPicture"
                                className="nav-pane"
                            >
                                <Label />
                            </Tab.Pane>
                        )}
                        {user.hasRight("artist.matching.partners") && (
                            <Tab.Pane eventKey="matching" className="nav-pane">
                                <Matching />
                            </Tab.Pane>
                        )}
                        <Tab.Pane eventKey="charts" className="nav-pane">
                            <Charts />
                        </Tab.Pane>
                        {user.hasRight(
                            "artist.tracking.plannings",
                            "artist.tracking.pictures",
                            "artist.tracking.logs"
                        ) && (
                            <Tab.Pane eventKey="tracking" className="nav-pane">
                                <Tracking />
                            </Tab.Pane>
                        )}
                    </Tab.Content>
                </Tab.Container>
            </main>
        </div>
    );
};

export default ArtistManagement;

import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router";
import Rev from "./Rev";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import RichTextEditor from "react-rte";
import swal from "sweetalert";

const schema = yup.object().shape({
    id_author: yup.number().nullable().required("utils.form.required"),
});

const EnhancedRev = ({
    lang,
    locale,
    albumId,
    reviewLangOptions,
    fetchReviewLangOptions,
    ...props
}) => {
    const { api } = useAPI();
    const [review, setReview] = useState(RichTextEditor.createEmptyValue());
    const [author, setAuthor] = useState();
    const [isReference, setIsReference] = useState(false);
    const [isOfficial, setIsOfficial] = useState(false);
    const [isTranslation, setIsTranslation] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [toTranslate, setToTranslate] = useState(false);
    const location = useLocation();
    const {
        control,
        handleSubmit,
        reset,
        getValues,
        formState: { isSubmitting, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            id_author: null,
        },
    });

    const updateReview = (review, isTranslation = 0) => {
        if (review) {
            setReview(RichTextEditor.createValueFromString(review, "html"));
            setIsTranslation(isTranslation);
        } else {
            setReview(RichTextEditor.createEmptyValue());
        }
    };

    const getReview = useCallback(() => {
        let cancelled = false;
        setIsLoading(true);
        api.get(`album/${albumId}/review/${lang}`)
            .then((response) => {
                if (!cancelled) {
                    reset(response);
                    setIsReference(response.is_reference || false);
                    setIsOfficial(response.is_official || false);
                    updateReview(response.review);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    reset();
                    setIsLoading(false);
                }
            });
        return () => {
            cancelled = true;
        };
    }, [lang, albumId]);

    useEffect(getReview, [getReview]);

    const [authorOptions, setAuthorOptions] = useState([]);

    const getReviewAuthors = useCallback(() => {
        api.get(`album/${albumId}/review/${lang}/authors`)
            .then((response) => {
                setAuthor(response.find(({ principal }) => principal)?.id);
                setAuthorOptions(
                    response.map(({ id, fullname, signature, date_maj }) => {
                        const label = [fullname, signature, date_maj].filter(
                            Boolean
                        );

                        return {
                            value: id,
                            label: label.join(" - "),
                        };
                    })
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [albumId, lang]);

    useEffect(getReviewAuthors, [getReviewAuthors]);

    const onSubmit = (data) => {
        const convertedReview = review.toString("html");
        const expandedData = {
            ...data,
            review: convertedReview !== "<p><br></p>" ? convertedReview : null,
        };
        const postData = () => {
            return api
                .put(
                    `album/${albumId}/review/${lang}`,
                    { locale, isTranslation },
                    expandedData
                )
                .then((response) => {
                    reset(response);
                    setIsReference(response.is_reference || false);
                    setIsOfficial(response.is_official || false);
                    getReview();
                    fetchReviewLangOptions();
                    setIsTranslation(0);
                    toast.success(
                        I18n.getTranslation(
                            location,
                            `artist.management.reviews.update.success`
                        )
                    );
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(
                        I18n.getTranslation(
                            location,
                            `artist.management.reviews.update.error`,
                            error.message
                        )
                    );
                });
        };

        if (data.review && !isTranslation && !isReference && !isOfficial) {
            return swal({
                title: I18n.getTranslation(
                    location,
                    "artist.management.reviews.update.alert.title"
                ),
                text: I18n.getTranslation(
                    location,
                    "artist.management.reviews.update.alert.text"
                ),
                icon: "warning",
                buttons: {
                    cancel: {
                        text: I18n.getTranslation(
                            location,
                            "utils.sweetalert.cancel"
                        ),
                        visible: true,
                        closeModal: true,
                    },
                    confirm: {
                        text: I18n.getTranslation(
                            location,
                            "utils.sweetalert.confirm"
                        ),
                        closeModal: true,
                    },
                },
            }).then((isConfirm) => {
                if (!isConfirm) {
                    return;
                }

                return postData();
            });
        } else {
            return postData();
        }
    };

    return (
        <Rev
            review={review}
            setReview={setReview}
            getReview={getReview}
            author={author}
            setAuthor={setAuthor}
            lang={lang}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            errors={errors}
            control={control}
            isSubmitting={isSubmitting}
            onSubmit={onSubmit}
            authorOptions={authorOptions}
            location={location}
            getValues={getValues}
            updateReview={updateReview}
            isReference={isReference}
            isOfficial={isOfficial}
            toTranslate={toTranslate}
            reviewLangOptions={reviewLangOptions}
            setToTranslate={setToTranslate}
            {...props}
        />
    );
};

export default EnhancedRev;

import { UPLOAD_MAX_SIZE } from "shared/constants/file";

export default {
    dashboard: "Metadata Monitor",
    logo: "Metadata Monitor",
    error_boundary: {
        message: "Oups ! Une erreur est survenue.",
        submessage: "Nous travaillons dessus au plus vite",
        back: "Retour à l'accueil",
    },
    security: {
        login: {
            title: "Connexion",
            username: "E-mail",
            password: "Mot de passe",
            noAccount: "Vous n'avez pas de compte ?",
            create: "Inscription",
            forgot: "Mot de passe oublié ?",
        },
        request: {
            title: "Demande",
            username: "Adresse e-mail",
            message:
                "Un e-mail a été envoyé. Il contient un lien sur lequel il vous faudra cliquer pour réinitialiser votre mot de passe.\n\nSi vous ne recevez pas un email, vérifiez votre dossier spam ou essayez à nouveau.",
            button: "Réinitialiser le mot de passe",
            login: "Retour à la page de connexion",
        },
        reset: {
            title: "Modifier le mot de passe",
            password: "Nouveau mot de passe",
            success: "Le mot de passe a été réinitialisé avec succès",
            error: "Impossible de réinitialiser le mot de passe",
            button: "Modifier le mot de passe",
            invalid_password: "Mot de passe invalide",
            login: "Retour à la page de connexion",
        },
        register: {
            title: "Ouvrir un compte",
            subtitle: {
                trial: "Envie d'essayer nos données ? Commencez dès aujourd'hui un essai gratuit de 2 semaines.",
                client: "En tant que client, vous avez déjà accès au Metadata Monitor. Votre compte sera vérifié pour vous fournir un accès correspondant.",
            },
            is_client: "Déjà client ?",
            email: "Adresse e-mail",
            firstname: "Prénom",
            lastname: "Nom",
            password: "Mot de passe",
            account_type: {
                title: "Type de compte",
                music_service: "Music service",
                cmo: "CMO",
                label_distributor: "Label / Distributor",
                artist_manager: "Artist / Manager",
            },
            role: {
                title: "Rôle",
                music_editor: "Music editor",
                marketing: "Marketing",
                catalog_management: "Catalog Management",
                product_management: "Product Management",
                tech: "Tech",
                executive: "Executive",
                other: "Other",
                promotion: "Promotion",
                business_development: "Business development",
                a_and_r: "A&R",
                supply_chain: "Supply chain",
                analytics: "Analytics",
            },
            describe: "Précision",
            language: "Langue",
            label: "Label",
            company: "Société",
            success:
                "Votre compte a bien été créé. Veuillez valider votre adresse email pour finaliser votre inscription et accéder au Metadata Monitor.",
            error: "Impossible de créer le compte",
            errorAllreadyAccount: () => (
                <>
                    Inscription impossible car un compte est déjà associé à
                    cette adresse email, essayez de{" "}
                    <a href="https://metadata.music-story.com/fr/login">
                        vous connecter
                    </a>{" "}
                    directement ou de{" "}
                    <a href="https://metadata.music-story.com/fr/resetting/request">
                        réinitialiser votre mot de passe
                    </a>
                    .
                </>
            ),
            button: "Créer un compte",
            allreadyAccount: "Vous avez déjà un compte ?",
            agreeBefore: "En vous inscrivant, vous acceptez les",
            termsOfService: "conditions d'utilisation",
            agreeAnd: "et",
            privacyPolicy: "Politique de confidentialité",
            invalid_password: "Mot de passe invalide",
        },
        access: {
            denied: "Identifiants invalides. Essayez de vous connecter avec votre adresse e-mail.",
            expired: "Compte expiré",
            lock: "Compte bloqué",
        },
        validate: {
            success:
                "Votre inscription a bien été finalisée, vous pouvez vous connecter.",
            error: "Impossible de valider le compte",
        },
    },
    user: {
        breadcrumb: "Utilisateurs",
        manage: {
            breadcrumb: "Management",
            user: {
                head: "Utilisateurs",
                empty: "Aucun Résultats",
                id: "Id",
                username: "Nom",
                is_client: "Déjà client ?",
                firstname: "Prénom",
                lastname: "Nom",
                email: "Email",
                user_roles: "Droits",
                label: "Label associé",
                api_limit_per_day: "Limite de requêtes API par jour",
                api_limit_per_second: "Limite de requêtes API par seconde",
                image_server: "Serveur images",
                author: "Auteur",
                account_type: {
                    title: "Type de compte",
                    music_service: "Music service",
                    cmo: "CMO",
                    label_distributor: "Label / Distributor",
                    artist_manager: "Artist / Manager",
                },
                role: {
                    title: "Rôle",
                    music_editor: "Music editor",
                    marketing: "Marketing",
                    catalog_management: "Catalog Management",
                    product_management: "Product Management",
                    tech: "Tech",
                    executive: "Executive",
                    other: "Other",
                    promotion: "Promotion",
                    business_development: "Business development",
                    a_and_r: "A&R",
                    supply_chain: "Supply chain",
                    analytics: "Analytics",
                },
                describe: "Précision",
                language: "Langue",
                company: "Société",
                last_login: "Dernière connexion",
                expires_at: "Date d'expiration",
                token: "Token",
                expires: "Expiré",
                action: "Action",
                search: "Rechercher...",
            },
            create: {
                title: "Ajouter un utilisateur",
                success: "Utilisateur ajouté",
                error: (message) =>
                    `Erreur lors de l'ajout de l'utilisateur: ${message}`,
            },
            update: {
                title: "Modifier un utilisateur",
                success: "Utilisateur modifié",
                error: (message) =>
                    `Erreur lors de la modification de l'utilisateur: ${message}`,
            },
            change_password: {
                title: "Modifier le mot de passe",
                password: "Mot de passe",
                comfirm: "Confirmer le mot de passe",
                success: "Mot de passe modifié",
                error: (message) =>
                    `Erreur lors de la modification du mot de passe: ${message}`,
            },
            promote: {
                title: "Promouvoir l'utilisateur",
                alert: {
                    title: "Êtes-vous sûr ?",
                    text: "De vouloir promouvoir cet utilisateur ?",
                },
                success: "Utilisateur promu",
                error: (message) =>
                    `Erreur lors de la promotion de l'utilisateur: ${message}`,
            },
            unpromote: {
                title: "Destituer l'utilisateur",
                alert: {
                    title: "Êtes-vous sûr ?",
                    text: "De vouloir destituer cet utilisateur ?",
                },
                success: "Utilisateur destitué",
                error: (message) =>
                    `Erreur lors de la destitution de l'utilisateur: ${message}`,
            },
            lock: {
                title: "Verrouiller l'utilisateur",
                alert: {
                    title: "Êtes-vous sûr ?",
                    text: "De vouloir verrouiller cet utilisateur ?",
                },
                success: "Utilisateur verrouillé",
                error: (message) =>
                    `Erreur lors du verrouilage de l'utilisateur: ${message}`,
            },
            unlock: {
                title: "Déverrouiller l'utilisateur",
                alert: {
                    title: "Êtes-vous sûr ?",
                    text: "De vouloir déverrouiller cet utilisateur ?",
                },
                success: "Utilisateur déverrouillé",
                error: (message) =>
                    `Erreur lors du déverrouillage de l'utilisateur: ${message}`,
            },
            generate_token: {
                success: "Token généré",
                error: (message) =>
                    `Erreur lors de la génération du token: ${message}`,
            },
        },
        role: {
            breadcrumb: "Roles",
            manage: {
                breadcrumb: "Management",
                role: {
                    head: "Roles",
                    empty: "Aucun Résultats",
                    id: "Id",
                    libelle: "Libellé",
                    rights: "Droits",
                    action: "Action",
                    search: "Rechercher...",
                },
                create: {
                    title: "Ajouter un role",
                    success: "Role ajouté",
                    error: (message) =>
                        `Erreur lors de l'ajout du role: ${message}`,
                },
                update: {
                    title: "Modifier un role",
                    success: "Role modifié",
                    error: (message) =>
                        `Erreur lors de la modification du role: ${message}`,
                },
                delete: {
                    title: "Supprimer un role",
                    alert: {
                        title: "Êtes-vous sûr ?",
                        text: "De vouloir supprimer ce role ?",
                    },
                    success: "Role supprimé",
                    error: (message) =>
                        `Erreur lors de la suppression du role: ${message}`,
                },
            },
        },
        right: {
            breadcrumb: "Droits",
            manage: {
                breadcrumb: "Management",
                right: {
                    head: "Droits",
                    empty: "Aucun Résultats",
                    id: "Id",
                    reference: "Référence",
                    libelle: "Libellé",
                    action: "Action",
                    search: "Rechercher...",
                },
                create: {
                    title: "Ajouter un droit",
                    success: "Droit ajouté",
                    error: (message) =>
                        `Erreur lors de l'ajout du droit: ${message}`,
                },
                update: {
                    title: "Modifier un droit",
                    success: "Droit modifié",
                    error: (message) =>
                        `Erreur lors de la modification du droit: ${message}`,
                },
                delete: {
                    title: "Supprimer un droit",
                    alert: {
                        title: "Êtes-vous sûr ?",
                        text: "De vouloir supprimer ce droit ?",
                    },
                    success: "Droit supprimé",
                    error: (message) =>
                        `Erreur lors de la suppression du droit: ${message}`,
                },
            },
        },
    },
    author: {
        breadcrumb: "Auteurs",
        manage: {
            breadcrumb: "Management",
            author: {
                head: "Auteurs",
                empty: "Aucun Résultats",
                id: "Id",
                firstname: "Prénom",
                lastname: "Nom",
                signature: "Signature",
                action: "Action",
                search: "Rechercher...",
            },
            create: {
                title: "Ajouter un auteur",
                success: "Auteur ajouté",
                error: (message) =>
                    `Erreur lors de l'ajout de l'auteur: ${message}`,
            },
            update: {
                title: "Modifier un auteur",
                success: "Auteur modifié",
                error: (message) =>
                    `Erreur lors de la modification de l'auteur: ${message}`,
            },
            delete: {
                title: "Supprimer un auteur",
                alert: {
                    title: "Êtes-vous sûr ?",
                    text: "De vouloir supprimer cet auteur ?",
                },
                success: "Auteur supprimé",
                error: (message) =>
                    `Erreur lors de la suppression de l'auteur: ${message}`,
            },
        },
    },
    artist: {
        search: {
            breadcrumb: "Liste d'artistes",
            placeholder:
                "ID / ISNI / nom / nom réel / nom traduit / alias / surnom",
            empty: "Aucun Résultats",
            init: "Veuillez faire une recherche",
            add: "Ajouter un artiste",
            id: "Id",
            name: "Nom",
            complement: "Complément",
            genre: "Genre",
            actions: "Actions",
            mainGenre: "Genre majeur",
            principalGenre: "Genre principal",
            secondaryGenre: "Genre secondaire",
            country: "Pays",
            type: "Type",
            gender: "Sexe",
            role: "Rôle",
            reset: "Réinitialiser la recherche",
            error: (message) =>
                `Erreur lors de la recherche d'un artiste: ${message}`,
            majorGenreGroup: "Genres Majeurs",
            otherGenreGroup: "Autres genres",
            filters: {
                bio_en: {
                    title: "Biographie EN",
                    yes: "Avec biographie EN",
                    no: "Sans biographie EN",
                },
                bio_fr: {
                    title: "Biographie FR",
                    yes: "Avec biographie FR",
                    no: "Sans biographie FR",
                },
                bio_de: {
                    title: "Biographie DE",
                    yes: "Avec biographie DE",
                    no: "Sans biographie DE",
                },
                bio_pt: {
                    title: "Biographie PT",
                    yes: "Avec biographie PT",
                    no: "Sans biographie PT",
                },
                bio_es: {
                    title: "Biographie ES",
                    yes: "Avec biographie ES",
                    no: "Sans biographie ES",
                },
                curation: {
                    title: "Curation",
                    yes: "Avec photos de curation",
                    no: "Sans photos de curation",
                },
                getty: {
                    title: "Getty",
                    yes: "Avec photos getty",
                    no: "Sans photos getty",
                },
                label: {
                    title: "Label",
                    yes: "Avec photos label",
                    no: "Sans photos label",
                },
                editorialized: {
                    title: "Editorialisation",
                    yes: "Est éditorialisé",
                    no: "N'est pas éditorialisé",
                },
            },
        },
        view: {
            breadcrumb: "Visualisation artiste",
            id: "ID Music-Story",
            complementary: "Complément",
            type: "Type",
            mainGenre: "Genre majeur",
            country: "Pays",
            profile: "Profil",
            univers: "Univers",
            credits: "Crédits",
            charts: "Top titres",
            disco: "Discographie",
            images: "Images",
            biography: "Biographie",
            popularity: "Popularité",
            noData: {
                univers: "Pas d'univers disponible pour cet artiste",
                images: "Pas d'images disponibles pour cet artiste",
                disco: "Pas de discographie disponible pour cet artiste",
                credits: "Pas de crédits disponibles pour cet artiste",
                charts: "Pas de top titres disponibles pour cet artiste",
                biography: "Pas de biographie disponible pour cet artiste",
                popularity: "Pas de popularité disponible pour cet artiste",
            },
            profileInformation: {
                head: "Infos complémentaires",
                role: "Rôle",
                gender: "Sexe",
                Personne: {
                    born: "Date naissance",
                    birth_country: "Pays naissance",
                    birth_area: "Département naissance",
                    birth_city: "Ville naissance",
                    died: "Date décès",
                    death_country: "Pays décès",
                    death_area: "Département décès",
                    death_city: "Ville décès",
                },
                Groupe: {
                    born: "Date formation",
                    birth_country: "Pays formation",
                    birth_area: "Département formation",
                    birth_city: "Ville formation",
                    died: "Date séparation",
                    death_country: "Pays séparation",
                    death_area: "Département séparation	",
                    death_city: "Ville séparation",
                },
                famousFor: "Célèbre pour",
                awards: "Récompenses",
            },
            profileNames: {
                head: "Noms",
                realname: "Nom Prénom réel",
                alias: "Alias",
                names: "Autres langues",
            },
            profileMembers: {
                head: "Membres",
                current: "Membres actuels",
                old: "Anciens membres",
            },
            profileGroup: {
                head: "Groupe",
                current: "Groupe actuels",
                old: "Anciens groupes",
            },
            profileExternalLink: {
                head: "Liens externes",
                isni: "ISNI",
                site: "Sites",
            },
            profilePartnerLink: {
                head: "Liens partenaires",
            },
            universGenres: {
                head: "Genre",
                main: "Majeur",
                principal: "Principaux",
                secondary: "Secondaires",
                influence: "Influences",
            },
            universRelated: {
                head: "Artistes associés",
                related: "Associés",
                successor: "Successeurs",
                influence: "Influences",
            },
            universLanguages: {
                head: "Langues chantées",
                principal: "Langue principale",
                secondary: "Autres langues",
            },
            creditsCoPerformer: {
                head: "Co-interprétations",
            },
            creditsContribution: {
                head: "Contributions",
                description:
                    "Artistes pour lesquels l'artiste actuel a collaboré (par exemple en tant que featuring sur les morceaux d'un autre artiste, compositeur pour un autre artiste, ou jouant de n'importe quel instrument pour un autre artiste…)",
            },
            creditsCollaboration: {
                head: "Collaborations",
                description:
                    "Les artistes qui ont collaboré avec l'artiste actuel (comme par exemple son compositeur, ses principaux featurings, …)",
            },
            chartsRecordingCharts: {
                mainCharts: "Charts officiels",
                allCharts: "Tous les charts",
                cover: "Pochette",
                title: "Titre",
                star: "Score",
                peak_rank: "Meilleur rang",
                chart: "Chart",
                country: "Pays",
                entry_date: "Date d'entrée",
                trend: "Trend",
                year: "Année",
                multi_charts: "Multi-charts",
                multi_country: "Multi-pays",
                resetCharts: "Réinitialiser les filtres / tri",
                resetEditorsPick: "Réinitialiser le tri",
                hit: {
                    title: "Hits",
                    description:
                        "une selection par nos rédacteurs musicaux des titres les plus populaires de l'artiste",
                },
                editorial_pick: {
                    title: "Découverte",
                    description:
                        "une selection par nos rédacteurs musicaux de titres ayant un intérêt particulier",
                },
                empty: "Aucun Résultats",
            },
            discoAlbums: {
                head: "Albums",
                id: "Id",
                cover: "Pochette",
                title: "Titre",
                type: {
                    head: "Type",
                    live: "Live",
                    remix: "Remix",
                    compilation: "Compilation",
                    karaoke: "Karaoke",
                    ring: "Sonnerie",
                    other: "Autre",
                    original: "Original",
                },
                format: {
                    head: "Format",
                    album: "Album",
                    ep: "EP",
                    single: "Single",
                    mixtape: "Mixtape",
                    mcd: "Maxi",
                },
                year: "Année",
                genre: "Genre",
                release_date: "Date de sortie",
                reference: "Cote",
                has_reviews: "Chroniques",
                has_credits: "Crédits",
                has_chart: "Chart",
                complementary: "Contenu associé",
                empty: "Aucun Résultats",
                reset: "Réinitialiser les filtres / tri",
            },
            imagesGallery: {
                head: "Galerie",
                tags: "Filtrer par tags",
                empty: "Aucune image présente dans la galerie",
                detail: {
                    head: "Détail",
                    download: {
                        title: "Télécharger l'image originale",
                        error: "Vous ne disposez pas des droits nécessaires pour télécharger l'image. Contactez nous via le chat pour en savoir plus.",
                    },
                    source: "Source",
                    copyright: "Copyright",
                    tags: "Tags",
                    metas: "Metas",
                },
            },
            biographyGallery: {
                writer: "Auteur",
                updated_at: "Mis à jour le",
                words: "Mots",
                signs: "Signes",
                biography: "Biographie",
                empty: "Aucune biographie",
                licence: {
                    title: "Avec l'autorisation de CD Journal",
                    subtitle:
                        "Ce contenu ne peut être utilisé sans une licence spécifique délivrée par CD Journal",
                },
            },
            popularityOverview: {
                head: "Vue d'ensemble",
                global: {
                    title: "Globale",
                    recordings: "Titres dans les charts :",
                    description:
                        "La popularité d’un artiste est calculée sur la base des données de charts. Elle est donc le résultat de la popularité des titres de l’artiste et se décline sous deux formes :\nle rang (la place de l'artiste dans le classement des artistes populaires) et le niveau de popularité (en %, relative au score de popularité max).",
                },
                gold: {
                    title: "Catalogue",
                    recordings: "Titres dans les charts catalogue :",
                    description:
                        "La popularité Catalogue d'un artiste est basée sur sa présence dans les charts datant de plus de 18 mois.\nLa popularité Catalogue ne prend en compte que les données historiques du Billboard Hot 100, du top 100 UK Singles Charts, du SNEP et du Offizielle Deutsche Charts. D'autres charts officiels sont en cours d'intégration.",
                },
                current: {
                    title: "Actuelle",
                    recordings: "Titres dans les charts actuels :",
                    description:
                        "La popularité actuelle d'un artiste est basée sur sa présence dans les charts datant de moins de 18 mois.",
                },
                type: {
                    label: "Typologie :",
                    gold: "Catalogue",
                    current: "Actuel",
                },
                peak: "Pic :",
            },
            popularityHistogram: {
                head: "Évolution de la popularité",
                tabs: {
                    gold: {
                        title: "Catalogue",
                        description:
                            "La popularité Catalogue d'un artiste est basée sur sa présence dans les charts datant de plus de 18 mois.\nLa popularité Catalogue ne prend en compte que les données historiques du Billboard Hot 100, du top 100 UK Singles Charts, du SNEP et du Offizielle Deutsche Charts. D'autres charts officiels sont en cours d'intégration.",
                        header: "La popularité Catalogue ne prend en compte que les données historiques du Billboard Hot 100, du top 100 UK Singles Charts, du SNEP et du Offizielle Deutsche Charts. D'autres charts officiels sont en cours d'intégration.",
                    },
                    current: {
                        title: "Actuelle",
                        description:
                            "La popularité actuelle d'un artiste est basée sur sa présence dans les charts datant de moins de 18 mois.",
                    },
                },
                gold: "Popularité catalogue",
                current: "Popularité actuelle",
                popover: {
                    noData: "Aucunes données",
                    popularity: "Popularité de l'artiste :",
                    mostPopularTitle: "Titre le plus populaire :",
                    bestRank: "Meilleure position :",
                    bestCountry: "Pays de la meilleure position :",
                    charts: (n) => `Sur la base de ${n} charts`,
                },
            },
            popularityMap: {
                head: "Popularité par pays",
                tabs: {
                    gold: {
                        title: "Catalogue",
                        description:
                            "La popularité Catalogue d'un artiste est basée sur sa présence dans les charts datant de plus de 18 mois.\nLa popularité Catalogue ne prend en compte que les données historiques du Billboard Hot 100, du top 100 UK Singles Charts, du SNEP et du Offizielle Deutsche Charts. D'autres charts officiels sont en cours d'intégration.",
                    },
                    current: {
                        title: "Actuelle",
                        description:
                            "La popularité actuelle d'un artiste est basée sur sa présence dans les charts datant de moins de 18 mois.",
                    },
                },
                popover: {
                    noData: "Aucunes données",
                    popularity: "Popularité de l'artiste :",
                    mostPopularTitle: "Titre le plus populaire :",
                    bestRank: "Meilleure position :",
                    bestMonth: "Mois de la meilleure position :",
                    weeksInCharts: "Semaines dans les charts :",
                    charts: (n) => `Sur la base de ${n} charts`,
                },
            },
        },
        create: {
            breadcrumb: "Création",
            placeholder:
                "ID / ISNI / nom / nom réel / nom traduit / alias / surnom",
            empty: "Aucun Résultats",
            init: "Veuillez faire une recherche",
            source: "Source",
            name: "Nom",
            complement: "Complément",
            type: "Type",
            gender: "Sexe",
            country: "Pays",
            real_name: "N-Prénom Réél",
            alias: "Alias",
            member: "Membres",
            band: "Groupes",
            site: "Sites",
            social: "Sociaux",
            actions: "Actions",
            create: {
                title: "Créer l'artiste",
                alert: {
                    title: "Êtes-vous sûr ?",
                    text: "De vouloir créer cet artiste ?",
                },
                success: "Artiste créé",
                error: (message) =>
                    `Erreur lors de la création de l'artiste: ${message}`,
            },
        },
        management: {
            breadcrumb: "Modification d'artistes",
            profile: "Profil",
            editorial: "Editorial",
            biography: "Biographie",
            discography: "Discographie",
            curation: "Curation",
            getty: "Getty",
            labelPicture: "Images Label",
            matching: "Matching",
            charts: "Charts",
            tracking: "Suivi",
            action: {
                complete: {
                    message: "Création de l'artiste en cours",
                    button: "Terminer la création",
                    alert: {
                        title: "Êtes-vous sûr ?",
                        text: "De vouloir terminer la création de cet artiste ?",
                    },
                    success: "Création terminée",
                    error: (message) =>
                        `Erreur lors de la création de l'artiste: ${message}`,
                },
            },
            profileInformation: {
                head: "Informations principales",
                type: "Type",
                name: "Nom",
                complement: "Complément",
                realname: "Nom Prénom réel",
                gender: "Sexe",
                country: "Pays",
                isni: "ISNI",
                isniSearch: {
                    tooltip: "Rechercher l'ISNI",
                    head: "Recherche ISNI",
                    placeholder: "Rechercher par nom ou par ISNI",
                    error: (message) =>
                        `Erreur lors de la recherche ISNI: ${message}`,
                    isni: "ISNI",
                    name: "Nom",
                    select: "Sélectionner l'ISNI",
                    empty: "Aucun résultats",
                },
                reset: {
                    title: "Réinitialiser le formulaire",
                },
                update: {
                    title: "Modifier les informations principales",
                    success: "Informations modifiées",
                    error: (message) =>
                        `Erreur lors de la modification des informations: ${message}`,
                },
            },
            names: {
                head: "Autres langues",
                empty: "Aucune langues",
                name: "Nom",
                language: "Langue",
                reset: {
                    title: "Réinitialiser le formulaire",
                },
                update: {
                    title: "Modifier les autres langues",
                    success: "Autres langues modifiées",
                    error: (message) =>
                        `Erreur lors de la modification des autres langues: ${message}`,
                },
            },
            alias: {
                head: "Alias",
                empty: "Aucun alias",
                alias: "Alias",
                type: "Type",
                reset: {
                    title: "Réinitialiser le formulaire",
                },
                update: {
                    title: "Modifier les alias",
                    success: "Alias modifiés",
                    error: (message) =>
                        `Erreur lors de la modification des alias: ${message}`,
                },
            },
            member: {
                head: "Membres",
                empty: "Aucun membre",
                artist: "Nom",
                start_date: "Début",
                end_date: "Fin",
                role: "Rôle",
                reset: {
                    title: "Réinitialiser le formulaire",
                },
                update: {
                    title: "Modifier les membres",
                    success: "Membre modifiés",
                    error: (message) =>
                        `Erreur lors de la modification des membres: ${message}`,
                },
            },
            group: {
                head: "Groupes",
                empty: "Aucun groupe",
                artist: "Nom",
                start_date: "Début",
                end_date: "Fin",
                role: "Rôle",
                reset: {
                    title: "Réinitialiser le formulaire",
                },
                update: {
                    title: "Modifier les groupes",
                    success: "Groupe modifiés",
                    error: (message) =>
                        `Erreur lors de la modification des groupes: ${message}`,
                },
            },
            genre: {
                head: "Genre",
                empty: "Aucun genre",
                genre: "Nom",
                type: "Type",
                reset: {
                    title: "Réinitialiser le formulaire",
                },
                update: {
                    title: "Modifier les genres",
                    success: "Genres modifiés",
                    error: (message) =>
                        `Erreur lors de la modification des genres: ${message}`,
                },
            },
            role: {
                head: "Rôle",
                empty: "Aucun role",
                role: "Rôle",
                main: "Majeur",
                reset: {
                    title: "Réinitialiser le formulaire",
                },
                update: {
                    title: "Modifier les rôles",
                    success: "Rôles modifiés",
                    error: (message) =>
                        `Erreur lors de la modification des rôles: ${message}`,
                },
            },
            social: {
                head: "Sociaux",
                reset: {
                    title: "Réinitialiser le formulaire",
                },
                update: {
                    title: "Modifier les sociaux",
                    success: "Sociaux modifiés",
                    error: (message) =>
                        `Erreur lors de la modification des sociaux: ${message}`,
                },
                duplicates: {
                    tooltip: "Cet URL est utilisé pour d'autres artistes",
                    head: "Doublons de sociaux",
                    body: "Les artistes suivants utilisent le même URL :",
                },
            },
            site: {
                head: "Sites",
                empty: "Aucun site",
                url: "Url",
                reset: {
                    title: "Réinitialiser le formulaire",
                },
                update: {
                    title: "Modifier les urls",
                    success: "Urls modifiés",
                    error: (message) =>
                        `Erreur lors de la modification des urls: ${message}`,
                },
            },
            editorialInformation: {
                head: "Informations principales",
                Personne: {
                    born: "Date naissance",
                    birth_country: "Pays naissance",
                    birth_area: "Département naissance",
                    birth_city: "Ville naissance",
                    died: "Date décès",
                    death_country: "Pays décès",
                    death_area: "Département décès",
                    death_city: "Ville décès",
                },
                Groupe: {
                    born: "Date formation",
                    debut_date: "Date de début",
                    birth_country: "Pays formation",
                    birth_area: "Département formation",
                    birth_city: "Ville formation",
                    activity_period: "Période d'activité",
                    activity_period_start: "Date de début",
                    activity_period_end: "Date de fin",
                    died: "Date séparation",
                    death_country: "Pays séparation",
                    death_area: "Département séparation	",
                    death_city: "Ville séparation",
                },
                reset: {
                    title: "Réinitialiser le formulaire",
                },
                update: {
                    title: "Modifier les informations principales",
                    success: "Informations modifiées",
                    error: (message) =>
                        `Erreur lors de la modification des informations: ${message}`,
                },
            },
            highlights: {
                head: "Faits marquants",
                famous_for: "Célèbre pour",
                awards: "Récompenses",
                reset: {
                    title: "Réinitialiser le formulaire",
                },
                update: {
                    title: "Modifier les faits marquants",
                    success: "Faits marquants modifiés",
                    error: (message) =>
                        `Erreur lors de la modification des faits marquants: ${message}`,
                },
            },
            collaboration: {
                head: "Collaborations",
                empty: "Aucune collaboration",
                artist: "Nom",
                start_date: "Début",
                end_date: "Fin",
                role: "Rôle",
                reset: {
                    title: "Réinitialiser le formulaire",
                },
                update: {
                    title: "Modifier les collaboration",
                    success: "Collaborations modifiées",
                    error: (message) =>
                        `Erreur lors de la modification des collaboration: ${message}`,
                },
            },
            contribution: {
                head: "Contributions",
                empty: "Aucune contribution",
                artist: "Nom",
                start_date: "Début",
                end_date: "Fin",
                role: "Rôle",
                reset: {
                    title: "Réinitialiser le formulaire",
                },
                update: {
                    title: "Modifier les contribution",
                    success: "Contributions modifiées",
                    error: (message) =>
                        `Erreur lors de la modification des contribution: ${message}`,
                },
            },
            related: {
                head: "Associés",
                empty: "Aucun associés",
                artist: "Nom",
                type: "Type",
                reset: {
                    title: "Réinitialiser le formulaire",
                },
                update: {
                    title: "Modifier les associés",
                    success: "Associés modifiés",
                    error: (message) =>
                        `Erreur lors de la modification des associés: ${message}`,
                },
            },
            bio: {
                head: {
                    FR: "Music Story FR",
                    EN: "Music Story EN",
                    DE: "Music Story DE",
                    PT: "Music Story PT",
                    JA: "Music Story JA",
                    ES: "Music Story ES",
                },
                is_reference: "Biographie de référence",
                to_translate: () => (
                    <>
                        Processus en cours.
                        <br />
                        Cette biographie est traduite et mise à jour
                        automatiquement à partir de la biographie de référence
                        correspondante. Aucune action n'est nécessaire.
                    </>
                ),
                author: "Rédacteur",
                archive: "Archive",
                cd_journal: "CD Journal",
                reset: {
                    title: "Réinitialiser la biographie",
                },
                update: {
                    title: "Modifier la biographie",
                    success: "Biographie modifiée",
                    alert: {
                        title: "Êtes-vous sûr ?",
                        text: "Vous êtes sur le point de modifier une biographie qui a été traduite et qui ne sera plus traduite après modification.",
                    },
                    error: (message) =>
                        `Erreur lors de la modification de la biographie: ${message}`,
                },
                translation: {
                    placeholder: "Veuillez sélectionner une langue",
                    alert: {
                        title: "Êtes-vous sûr ?",
                        text: "De vouloir remplacer la biographie actuelle ?",
                    },
                },
            },
            wikipedia: {
                head: "Wikipedia",
                empty: "Aucun wikipedia",
                url: "Url",
                reset: {
                    title: "Réinitialiser le formulaire",
                },
                update: {
                    title: "Modifier les wikipedias",
                    success: "Wikipedias modifiés",
                    error: (message) =>
                        `Erreur lors de la modification des wikipedias: ${message}`,
                },
                names: {
                    head: 'Ajout des noms wikipedia dans "Autres langues"',
                    lang: "Langue",
                    name: "Nom",
                },
            },
            reviews: {
                head: "Chroniques d'album",
                is_reference: "Chronique de référence",
                to_translate: () => (
                    <>
                        Processus en cours.
                        <br />
                        Cette chronique est traduite et mise à jour
                        automatiquement à partir de la chronique d'album de
                        référence correspondante. Aucune action n'est
                        nécessaire.
                    </>
                ),
                author: "Rédacteur",
                reset: {
                    title: "Réinitialiser la chronique",
                },
                update: {
                    title: "Modifier la chronique",
                    success: "Chronique modifiée",
                    alert: {
                        title: "Êtes-vous sûr ?",
                        text: "Vous êtes sur le point de modifier une chronique d'album qui a été traduite et qui ne sera plus traduite après modification.",
                    },
                    error: (message) =>
                        `Erreur lors de la modification de la chronique: ${message}`,
                },
                translation: {
                    placeholder: "Veuillez sélectionner une langue",
                    alert: {
                        title: "Êtes-vous sûr ?",
                        text: "De vouloir remplacer la chronique actuelle ?",
                    },
                },
            },
            disco: {
                albums: {
                    head: "Albums",
                    search: "ID / Titre",
                    empty: "Aucun Résultats",
                    id: "Id",
                    title: "Titre",
                    type: {
                        head: "Type",
                        live: "Live",
                        remix: "Remix",
                        compilation: "Compilation",
                        karaoke: "Karaoke",
                        ring: "Sonnerie",
                        other: "Autre",
                        original: "Original",
                    },
                    format: {
                        head: "For.",
                        title: "Format",
                        album: "Album",
                        ep: "EP",
                        single: "Single",
                        mixtape: "Mixtape",
                        mcd: "Maxi",
                    },
                    genre: "Genre",
                    release_date: {
                        head: "Date",
                        title: "Date de sortie",
                    },
                    cover: {
                        head: "Poch.",
                        title: "Pochette",
                    },
                },
                releases: {
                    head: "Editions",
                    search: "ID / Code barre",
                    empty: "Aucun Résultats",
                    id: "Id",
                    barcode: {
                        head: "Code B.",
                        title: "Code barre",
                    },
                    commentary: "Commentaire",
                    support: "Support",
                    release_date: {
                        head: "Date",
                        title: "Date de sortie",
                    },
                },
                sandbox: {
                    head: "Bac à Sable",
                    search: {
                        input: "ID / Titre / Code barre",
                        title: "Rechercher dans toutes les discographies",
                        result: (query) =>
                            `Résultat de la recherche "${query}"`,
                        reset: "Réinialiser la recherche",
                    },
                    empty: "Aucun Résultats",
                    partner: "Affilié",
                    id: "Id",
                    barcode: "Code barre",
                    title: "Titre",
                    nb_track: "Nb tracks",
                    actions: "Actions",
                    view_product: "Voir le produit",
                    compare_tracklists: "Comparer les pistes",
                    view_matchings: "Voir les matchings",
                    popover: {
                        id: "ID :",
                        title: "Titre :",
                        artist: "Artiste :",
                        barcode: "Code barre :",
                        label: "Label :",
                        release_date: "Date de sortie :",
                        cover_art_error: "Impossible de charger la pochette",
                    },
                    matchings: {
                        head: "Matchings",
                        body: "Ce produit est matché avec les discographies suivantes :",
                        artists: "Artiste(s)",
                        album: "Album",
                        release: "Édition",
                        close: "Fermer",
                    },
                    tracklistComparison: {
                        head: "Comparaison de tracklist",
                        tracks: {
                            head: "Tracklist MusicStory",
                            empty: "Aucun Résultats",
                            no_cd: "D",
                            no_piste: "T",
                            isrc: "Isrc",
                            title: "Titre",
                            subtitle: "Sous-T.",
                            duration: "Durée",
                            hit: "Hit",
                            discovery: {
                                head: "Dec.",
                                title: "Découverte",
                            },
                        },
                        partnerTracks: {
                            head: "Tracklist partenaire",
                            empty: "Aucun Résultats",
                            no_cd: "D",
                            no_piste: "T",
                            isrc: "Isrc",
                            title: "Titre",
                            duration: "Durée",
                        },
                        replace: {
                            title: "Remplacer les pistes MusicStory",
                            alert: {
                                title: "Êtes-vous sûr ?",
                                text: "De vouloir remplacer la tracklist de cette édition ?",
                            },
                            success: "La tracklist a été remplacée",
                            error: (message) =>
                                `Erreur lors du remplacement de la tracklist: ${message}`,
                            warningIsrc: {
                                title: "Êtes-vous sûr ?",
                                text: "Avertissements lors du remplacement de la tracklist: des recordings avec le même ISRC ont été trouvés",
                            },
                        },
                    },
                    copyProduct: {
                        title: "Ajouter le produit",
                        head: "Ajouter le produit",
                        copyAsAlbum: {
                            title: "Ajouter en tant que nouvel album",
                            created:
                                "Le produit a été ajouté en tant que nouvel album",
                            error: (message) =>
                                `Erreur lors de l'ajout du produit en tant qu'album: ${message}`,
                            warningBarcode: {
                                title: "Êtes-vous sûr ?",
                                text: (message) =>
                                    `Avertissements lors de l'ajout de le l'album: ${message}`,
                            },
                            warningIsrc: {
                                title: "Êtes-vous sûr ?",
                                text: "Avertissements lors de l'ajout de l'album: des recordings avec le même ISRC ont été trouvés",
                            },
                        },
                        copyAsRelease: {
                            title: "Ajouter en tant que nouvelle édition de l'album courant",
                            created:
                                "Le produit a été ajouté en tant que nouvelle édition",
                            matched:
                                "Le produit a été matché avec une édition existante",
                            error: (message) =>
                                `Erreur lors de l'ajout ou du matching du produit en tant qu'édition: ${message}`,
                            warningBarcode: {
                                title: "Êtes-vous sûr ?",
                                text: (message) =>
                                    `Avertissements lors de l'ajout de le l'édition: ${message}`,
                            },
                            warningIsrc: {
                                title: "Êtes-vous sûr ?",
                                text: "Avertissements lors de l'ajout de l'édition: des recordings avec le même ISRC ont été trouvés",
                            },
                        },
                    },
                    pagination: ({ from, to, size }) => `${size} produits`,
                    filters: {
                        partners: "Affiliés",
                        status: "Statuts",
                    },
                    status: {
                        MATCHED_IN_CURRENT_DISCOGRAPHY:
                            "Matché dans la discographie actuelle",
                        MATCHED_IN_OTHER_DISCOGRAPHY:
                            "Matché dans une autre discographie",
                        NOT_MATCHED_COMPLETE: "Non matché, complet",
                        NOT_MATCHED_INCOMPLETE: "Non matché, incomplet",
                    },
                },
                tracks: {
                    head: "Pistes",
                    search: "ID / Isrc / Titre",
                    empty: "Aucun Résultats",
                    no_cd: {
                        head: "D",
                        title: "No CD",
                    },
                    no_piste: {
                        head: "P",
                        title: "No Piste",
                    },
                    id: "Id",
                    isrc: "Isrc",
                    title: "Titre",
                    subtitle: {
                        head: "Sous-T.",
                        title: "Sous-titre",
                    },
                    duration: {
                        head: "Durée",
                        title: "Durée",
                    },
                },
                matching: {
                    partner: "Affilié",
                    id: "Id",
                    actions: "Actions",
                    view: "Voir la piste",
                    delete: "Supprimer",
                },
                objects: {
                    album: {
                        tabTitle: "Album",
                        basic: {
                            head: "Données basiques",
                            artist: "Artiste",
                            title: "Titre",
                            subtitle: "Sous-Titre",
                            type: "Type",
                            format: "Format",
                            label: "Label",
                            publisher: "Editeur",
                            release_date: "Date de sortie",
                            genre: "Genre",
                            reference: "Note",
                            typeOptions: {
                                live: "Live",
                                remix: "Remix",
                                compilation: "Compilation",
                                karaoke: "Karaoke",
                                ring: "Sonnerie",
                                other: "Autre",
                                original: "Original",
                            },
                            formatOptions: {
                                album: "Album",
                                ep: "EP",
                                single: "Single",
                                mixtape: "Mixtape",
                                mcd: "Maxi",
                            },
                            reset: {
                                title: "Réinitialiser le formulaire",
                            },
                            create: {
                                title: "Ajouter un album",
                                success: "Album ajouté",
                                error: (message) =>
                                    `Erreur lors de l'ajout de l'album: ${message}`,
                            },
                            update: {
                                title: "Modifier un album",
                                success: "Album modifié",
                                error: (message) =>
                                    `Erreur lors de la modification de l'album: ${message}`,
                            },
                            delete: {
                                title: "Supprimer un album",
                                hasReleases:
                                    "L'album possède encore une ou plusieurs édition(s)",
                                hasReview:
                                    "L'album possède encore une chronique",
                                alert: {
                                    title: "Êtes-vous sûr ?",
                                    text: "De vouloir supprimer cet album ?",
                                },
                                warning: {
                                    title: "Êtes-vous sûr ?",
                                    text: (messages) =>
                                        `Avertissement lors de la suppression de l'album: \n\n ${messages.map(
                                            (message) => {
                                                return `- ${message} \n`;
                                            }
                                        )}`,
                                },
                                success: "Album supprimé",
                                error: (message) =>
                                    `Erreur lors de la suppression de l'album: ${message}`,
                            },
                            transfer: {
                                title: "Transférer un album",
                                success: ({ artist, album }) =>
                                    `Transfert de l'album "${album}" vers l'artiste "${artist}" avec succès`,
                                error: (message) =>
                                    `Erreur lors du transfert de l'album: ${message}`,
                            },
                        },
                        artist: {
                            head: "Artistes",
                            main: "Main",
                            principal: "Principal",
                            reset: {
                                title: "Réinitialiser les artistes",
                            },
                            update: {
                                title: "Modifier les artistes",
                                success: "Artistes modifiés",
                                error: (message) =>
                                    `Erreur lors de la modification des artistes: ${message}`,
                            },
                        },
                        cover: {
                            head: "Pochette",
                            musicstory: "Music-Story",
                            partner: "Partenaires",
                            url: "Url de la pochette",
                            ean: "Code barre",
                            create: {
                                title: "Ajouter une pochette",
                                success: "Pochette ajoutée",
                                error: (message) =>
                                    `Erreur lors de l'ajout de la pochette: ${message}`,
                            },
                            update: {
                                title: "Modifier la pochette",
                                success: "Pochette modifiée",
                                error: (message) =>
                                    `Erreur lors de la modification de la pochette: ${message}`,
                            },
                            automatic: {
                                success: "Pochette ajoutée automatiquement",
                                error: (message) =>
                                    `Erreur lors de l'ajout automatique de la pochette: ${message}`,
                            },
                        },
                        reviews: {
                            head: "Chroniques d'album",
                            author: "Rédacteur",
                            reset: {
                                title: "Réinitialiser la chronique d'album",
                            },
                            update: {
                                title: "Modifier la chronique d'album",
                                success: "Chronique d'album modifiée",
                                error: (message) =>
                                    `Erreur lors de la modification de la chronique d'album : ${message}`,
                            },
                        },
                        translations: {
                            head: "Traductions",
                            language: "Langue",
                            title: "Titre",
                            subtitle: "Sous-titre",
                            languagePlaceholder: "Ajouter une langue",
                            reset: {
                                title: "Réinitialiser le formulaire",
                            },
                            update: {
                                title: "Modifier les traductions",
                                success: "Traductions modifiées",
                                error: (message) =>
                                    `Erreur lors de la modification des traductions: ${message}`,
                            },
                        },
                    },
                    release: {
                        tabTitle: "Edition",
                        basic: {
                            head: "Données basiques",
                            barcode: "Code barre",
                            release_date: "Date de sortie",
                            editor: "Editeur",
                            support: "Support",
                            commentary: "Commentaire",
                            reference: "Réference",
                            reset: {
                                title: "Réinitialiser le formulaire",
                            },
                            create: {
                                title: "Ajouter une édition",
                                success: "Édition ajoutée",
                                error: (message) =>
                                    `Erreur lors de l'ajout de l'édition: ${message}`,
                                warning: {
                                    title: "Êtes-vous sûr ?",
                                    text: (message) =>
                                        `Avertissements lors de l'ajout de l'édition: ${message}`,
                                },
                            },
                            update: {
                                title: "Modifier une édition",
                                success: "Édition modifiée",
                                error: (message) =>
                                    `Erreur lors de la modification de l'édition: ${message}`,
                            },
                            delete: {
                                title: "Supprimer une édition",
                                alert: {
                                    title: "Êtes-vous sûr ?",
                                    text: "De vouloir supprimer cette édition ?",
                                },
                                warning: {
                                    title: "Êtes-vous sûr ?",
                                    text: (messages) =>
                                        `Avertissements lors de la suppression de l'édition: \n\n ${messages.map(
                                            (message) => {
                                                return `- ${message} \n`;
                                            }
                                        )}`,
                                },
                                success: "Édition supprimée",
                                error: (message) =>
                                    `Erreur lors de la suppression de l'édition: ${message}`,
                            },
                            transfer: {
                                title: "Transférer une édition",
                                artist: "Artiste",
                                album: "Album",
                                success: ({ artist, album, releaseId }) =>
                                    `Transfert de l'édition "${releaseId}" sur l'album "${album}" de l'artiste "${artist}" avec succès`,
                                error: (message) =>
                                    `Erreur lors du transfert de l'édition: ${message}`,
                                same_album_error:
                                    "L'album ne doit pas être l'album actuellement associé à l'édition",
                            },
                        },
                        matching: {
                            head: "Partenaires",
                            empty: "Aucun Résultats",
                            delete: {
                                title: "Supprimer un matching",
                                alert: {
                                    title: "Êtes-vous sûr ?",
                                    text: "De vouloir supprimer ce matching ?",
                                },
                                success: "Matching supprimé",
                                error: (message) =>
                                    `Erreur lors de la suppression du matching: ${message}`,
                            },
                        },
                        tracklist: {
                            head: "Pistes",
                            tracks: {
                                empty: "Aucun Résultats",
                                no_cd: "D",
                                no_piste: "T",
                                isrc: "Isrc",
                                title: "Titre",
                                subtitle: "ST",
                                duration: "Durée",
                                hit: "Hit",
                                discovery: {
                                    head: "Dec.",
                                    title: "Découverte",
                                },
                                validation: {
                                    no_cd: "Le numéro de disque doit être un nombre positif",
                                    no_piste:
                                        "Le numéro de piste doit être un nombre positif",
                                    title: "Le titre ne peut pas être vide",
                                    duration:
                                        'La durée doit être au format "HH:MM:SS"',
                                    duplicatePosition:
                                        "Une autre piste a les mêmes numéros de disque et de piste",
                                    duplicateIsrc:
                                        "Une autre piste a le même ISRC",
                                },
                            },
                            reset: {
                                title: "Réinitialiser les pistes",
                            },
                            update: {
                                title: "Modifier les pistes",
                                success: "Les pistes ont été modifiées",
                                error: (message) =>
                                    `Erreur lors de la modification des pistes: ${message}`,
                                warningIsrc: {
                                    title: "Êtes-vous sûr ?",
                                    text: "Avertissements lors de la modification des pistes: des recordings avec le même ISRC ont été trouvés",
                                },
                            },
                        },
                    },
                    track: {
                        tabTitle: "Piste",
                        basic: {
                            head: "Données basiques",
                            title: "Titre",
                            subtitle: "Sous-Titre",
                            isrc: "ISRC",
                            duration: "Durée",
                            no_cd: "Num. CD",
                            no_piste: "Num. piste",
                            hit: "Hit",
                            discovery: "Découverte",
                            language: "Langue chantée",
                            dialect: "Dialecte",
                            genre: "Genre",
                            reset: {
                                title: "Réinitialiser le formulaire",
                            },
                            create: {
                                title: "Ajouter une piste",
                                success: "Piste ajoutée",
                                error: (message) =>
                                    `Erreur lors de l'ajout de la piste: ${message}`,
                                warningIsrc: {
                                    title: "Êtes-vous sûr ?",
                                    text: "Avertissements lors de l'ajout de la piste: un recording avec le même ISRC a été trouvé",
                                },
                            },
                            update: {
                                title: "Modifier une piste",
                                success: "Piste modifiée",
                                error: (message) =>
                                    `Erreur lors de la modification de la piste: ${message}`,
                                warningIsrc: {
                                    title: "Êtes-vous sûr ?",
                                    text: "Avertissements lors de la modification de la piste: un recording avec le même ISRC a été trouvé",
                                },
                            },
                            delete: {
                                title: "Supprimer une piste",
                                alert: {
                                    title: "Êtes-vous sûr ?",
                                    text: "De vouloir supprimer cette piste ?",
                                },
                                success: "Piste supprimée",
                                error: (message) =>
                                    `Erreur lors de la suppression de la piste: ${message}`,
                            },
                        },
                        translations: {
                            head: "Traductions",
                            language: "Langue",
                            title: "Titre",
                            subtitle: "Sous-Titre",
                            languagePlaceholder: "Ajouter une langue",
                            reset: {
                                title: "Réinitialiser le formulaire",
                            },
                            update: {
                                title: "Modifier les traductions",
                                success: "Traductions modifiées",
                                error: (message) =>
                                    `Erreur lors de la modification des traductions: ${message}`,
                            },
                        },
                        matching: {
                            head: "Partenaires",
                            empty: "Aucun Résultats",
                            delete: {
                                title: "Supprimer un matching",
                                alert: {
                                    title: "Êtes-vous sûr ?",
                                    text: "De vouloir supprimer ce matching ?",
                                },
                                success: "Matching supprimé",
                                error: (message) =>
                                    `Erreur lors de la suppression du matching: ${message}`,
                            },
                        },
                    },
                },
            },
            match: {
                empty: "Aucun matching",
                id: "ID",
                name: "Nom",
                type_match: "Type",
                status: "Statut",
                pending: "Matching en cours...",
                success: "Matching terminé",
            },
            imageCuration: {
                head: "Galerie",
                empty: "Aucune image présente dans la galerie",
                width: "Largeur",
                height: "Hauteur",
                created_at: "Date de création",
                copyright: "Copyright",
                status: "Statut",
                edit: "Modifier",
                edit_image: "Modifier les informations de l'image",
                delete: "Supprimer",
                delete_image: "Supprimer l'image",
            },
            imageGetty: {
                gallery: {
                    head: "Galerie",
                    empty: "Aucune image présente dans la galerie",
                    width: "Largeur",
                    height: "Hauteur",
                    created_at: "Date de création",
                    copyright: "Copyright",
                    status: "Statut",
                    edit: "Modifier",
                    edit_image: "Modifier les informations de l'image",
                    delete: "Supprimer",
                    delete_image: "Supprimer l'image",
                },
            },
            imageLabel: {
                head: "Galerie",
                empty: "Aucune image présente dans la galerie",
                width: "Largeur",
                height: "Hauteur",
                created_at: "Date de création",
                copyright: "Copyright",
                status: "Statut",
            },
            chartsList: {
                head: "Charts",
                empty: "Aucun Résultats",
                source: "Source",
                area: "Continent",
                format: "Format",
                country: "Pays",
                origin: "Origine",
                date_out: "Date sortie",
                track_title: "Titre",
                title: "Nom du chart",
                country: "Pays",
                date_in: "Date entrée",
                date_out: "Date sortie",
                nb_week: "Nb semaines",
                rank_in: "Rang entrée",
                max_rank: "Rang max",
                rank_out: "Rang sortie",
            },
            trackingPlanning: {
                head: "Historique des plannings",
                empty: "Aucun Résultats",
                date: "Date",
                title: "Titre",
                album: "Album",
                category: "Catégorie",
                reason: "Motif",
                classification: "Classification",
                tasks: "Tâches",
                status: {
                    TODO: "À faire",
                    COMPLETED: "Complété",
                    TO_BE_CORRECTED: "À corriger",
                    VALIDATED_WITHOUT_CONTROL: "Validé sans contrôle",
                    VERIFIED_AND_VALIDATED: "Vérifié et validé",
                    ERROR: "En erreur",
                },
            },
            trackingPicture: {
                head: "Historique des travaux photo",
                empty: "Aucun Résultats",
                picture: "Photo",
                date: "Date",
                username: "Utilisateur",
                action: "Action",
                curation_cms_artist_image_add_completed:
                    "Ajout d'une photo de curation",
                curation_cms_artist_image_update_completed:
                    "Modification d'une photo de curation",
                curation_cms_artist_image_main_completed:
                    "Passage en principal d'une photo de curation",
                curation_cms_artist_image_delete_completed:
                    "Suppression d'une photo de curation",
                label_cms_artist_picture_status_change_completed:
                    "Changement de statut d'une photo label",
                getty_cms_artist_picture_add_completed:
                    "Ajout d'une photo getty",
                label_cms_artist_picture_add_completed:
                    "Ajout d'une photo label",
                getty_cms_artist_picture_delete_completed:
                    "Suppression d'une photo getty",
                label_cms_artist_picture_delete_completed:
                    "Suppression d'une photo label",
                getty_cms_artist_picture_check_completed:
                    "Vérification des photos",
                getty_move_picture_completed: "Déplacement d'une photo getty",
                label_move_picture_completed: "Déplacement d'une photo label",
            },
            trackingLog: {
                head: "Logs",
                empty: "Aucun Résultats",
                date: "Date",
                username: "Utilisateur",
                action: "Action",
                cms_artist_alias_create_completed: "Alias créé",
                cms_artist_alias_update_completed: "Alias modifié",
                cms_artist_alias_delete_completed: "Alias supprimé",
                cms_artist_biography_create_completed: "Biographie créée",
                cms_artist_biography_update_completed: "Biographie modifiée",
                cms_artist_biography_translate_completed: "Biographie traduite",
                cms_artist_collaboration_create_completed:
                    "Collaboration créée",
                cms_artist_collaboration_delete_completed:
                    "Collaboration supprimée",
                cms_artist_collaboration_update_completed:
                    "Collaboration modifiée",
                cms_artist_contribution_create_completed: "Contribution créée",
                cms_artist_contribution_delete_completed:
                    "Contribution supprimée",
                cms_artist_contribution_update_completed:
                    "Contribution modifiée",
                cms_artist_create_completed: "Artist créé",
                cms_artist_update_completed: "Artist modifié",
                cms_artist_delete_completed: "Artist supprimé",
                cms_artist_genre_create_completed: "Genre créé",
                cms_artist_genre_delete_completed: "Genre supprimé",
                cms_artist_genre_update_completed: "Genre modifié",
                cms_artist_group_create_completed: "Groupe créé",
                cms_artist_group_delete_completed: "Groupe supprimé",
                cms_artist_group_update_completed: "Groupe modifié",
                cms_artist_member_create_completed: "Membre créé",
                cms_artist_member_delete_completed: "Membre supprimé",
                cms_artist_member_update_completed: "Membre modifié",
                cms_artist_name_create_completed: "Autre langue créée",
                cms_artist_name_update_completed: "Autre langue modifiée",
                cms_artist_name_delete_completed: "Autre langue supprimée",
                cms_artist_related_create_completed: "Associé créé",
                cms_artist_related_delete_completed: "Associé modifié",
                cms_artist_related_update_completed: "Associé supprimé",
                cms_artist_role_update_completed: "Rôle modifié",
                cms_artist_site_create_completed: "Site créé",
                cms_artist_site_delete_completed: "Site supprimé",
                cms_artist_site_update_completed: "Site modifié",
                cms_artist_social_create_completed: "Social créé",
                cms_artist_social_delete_completed: "Social supprimé",
                cms_artist_social_update_completed: "Social modifié",
                cms_artist_wikipedia_create_completed: "Wikipedia créé",
                cms_artist_wikipedia_update_completed: "Wikipedia modifié",
            },
        },
        delete: {
            breadcrumb: "Suppression artiste",
            empty: "Vide",
            object: "Objets",
            nb: "Nombre de liaisons restantes",
            url: "Liens",
            album: "Album",
            alias: "Alias",
            bio: "Music Story",
            collab: "Collaboration et contribution",
            member_group: "Membre et groupe",
            picture_curation: "Photo de curation",
            picture_right: "Photo avec droit",
            plannings: "Plannings",
            related1: "A des associés",
            related2: "Associé à",
            site: "Websites",
            social: "Social",
            wiki: "Wikipedia",
            delete: {
                title: "Suppression artiste",
                alert: {
                    title: "Etes vous sûr ?",
                    text: "Attention, des objets importants sont toujours liés à l'artiste. La suppression est irréversible, êtes vous sûr de vouloir continuer ?",
                },
                success: "Artiste supprimé avec succès",
                error: (message) =>
                    `Erreur à la suppression de l'artiste : ${message}`,
            },
        },
    },
    album: {
        view: {
            breadcrumb: "Visualisation album",
            id: "ID Music-Story",
            title: "Titre",
            subtitle: "Sous-titre",
            genre: "Genre",
            profile: "Explorateur d'enregistrements",
            univers: "Album",
            cover: "Pochette",
            credits: "Crédits",
            review: "Chronique",
            charts: "Charts",
            similar: "Album similaire",
            noData: {
                profile: "Pas de tracklist disponible pour cet album",
                credits: "Pas de crédits disponibles pour cet album",
                reviews: "Pas de chroniques disponibles pour cet album",
            },
            profileInformation: {
                head: "Informations",
                type: {
                    head: "Type",
                    live: "Live",
                    remix: "Remix",
                    compilation: "Compilation",
                    karaoke: "Karaoke",
                    ring: "Sonnerie",
                    other: "Autre",
                    original: "Original",
                },
                format: {
                    head: "Format",
                    album: "Album",
                    ep: "EP",
                    single: "Single",
                    mixtape: "Mixtape",
                    mcd: "Maxi",
                },
                label: "Label",
                release_date: "Date de sortie originale",
                performers: "Interprètes",
                featurings: "Featurings",
                translations: "Traductions",
                reference: "Cote",
            },
            profileReleases: {
                head: "Releases",
                subtitle:
                    "Cliquez sur une release pour afficher les ISRCs associés",
                description:
                    "Liste des différentes releases de l’album. Cliquez sur une release pour afficher les ISRCs qui lui sont associés. La release sélectionnée est surlignée en bleu et les ISRC associés sont surlignés en jaune.",
                ean: "Code barre",
                id: "Id",
                reference: "Référence",
                nb_cd: "Nb CD",
                nb_track: "Nb Track",
                release_date: "Date de sortie",
                commentary: "Commentaire",
                support: {
                    head: "Support",
                    ep: "EP",
                    num: "NUM",
                    dvd: "DVD",
                    lp: "LP",
                    "2cd": "2CD",
                    k7: "K7",
                    cd: "CD",
                },
                cline: "CLINE",
                pline: "PLINE",
                links: "Liens",
                empty: "Aucun Résultats",
            },
            profileIsrcs: {
                head: "ISRCs",
                subtitle:
                    "Cliquez sur un ISRC pour afficher les releases associées",
                description:
                    "Liste des ISRCS associés à cet album. Cliquez sur un ISRC pour afficher les releases dont il fait partie. L'ISRC sélectionné est surligné en bleu, et les releases associées en jaune.",
                isrc: "ISRC",
                id: "Id",
                title: "Titre",
                duration: "Durée",
                complementary: "Contenu associé",
                has_credits: "Crédits",
                has_lyric: "Parole",
                has_audio: "Audio",
                links: "Liens",
                empty: "Aucun Résultats",
            },
            profileTracklist: {
                head: "Tracklist",
                release_head: (id) =>
                    `Tracklist correspondant à l'édition "${id}" sélectionné`,
                isrc_head: (isrc) =>
                    `Tracks correspondants à l’ISRC "${isrc}" sélectionné`,
                id_release: "Id release",
                ean: "Code barre",
                id_track: "Id track",
                no_cd: "No CD",
                no_piste: "No Piste",
                id_recording: "Id recording",
                title: "Titre",
                subtitle: "Sous-titre",
                isrc: "ISRC",
                duration: "Durée",
                hit: "Hit score",
                editorial_pick: "Score de découverte",
                empty: "Aucun Résultats",
            },
            universGenres: {
                head: "Genre",
                principal: "Principaux",
                secondary: "Secondaires",
            },
            universLanguages: {
                head: "Langues chantées",
                principal: "Langue principale",
                secondary: "Autres langues",
            },
            universInstruments: {
                head: "Instruments",
            },
            universAudioDescription: {
                head: "Description audio",
                licence:
                    "Description audio calculée à l'aide de la technologie Simbals",
                moods: {
                    title: "Moods de l’album",
                    subtitle: "par ordre d'importance",
                    description: () => (
                        <>
                            Détail des moods de l'enregistrement et leurs poids
                            correspondants. <br />
                            <strong>Liste complète:</strong> heureux, triste,
                            calme, énergique, excité, étonné/éveillé,
                            tendu/alarmé/effrayé, en colère, en détresse, ravi,
                            convaincu, impatient, frustré, mécontent, satisfait,
                            intéressé, neutre, méfiant, malheureux/déçu/étonné,
                            serein/content, confiant, inquiet, mal à l'aise,
                            triste/dépressif/gloire, détendu/satisfait,
                            contemplatif, fatigué/sommeillé, somnolent,
                            ennuyé/anxieux.
                        </>
                    ),
                },
                themes: {
                    title: "Cet album contient des titres ayant pour thèmes",
                    description: `Thèmes détaillés de l'enregistrement relatifs notamment aux contextes d’écoute.  Inclut, par exemple, les valeurs suivantes : "Entraînement", "Dîner", "Noël", "Fête", "Jeux vidéo", "Été", etc…`,
                },
                timbres: {
                    title: "Contient des titres ayant pour timbres",
                    description:
                        "Tags correspondant au timbre de l'enregistrement.",
                },
                arousal: {
                    title: "Intensité",
                    description: () => (
                        <>
                            Cet indicateur représente une mesure perceptuelle
                            d'un enregistrement liée à son intensité, son
                            énergie et son activité.
                            <ul>
                                <li>
                                    <strong>Une valeur élevée </strong>
                                    correspond généralement à un morceau à forte
                                    énergie (hard rock, métal)
                                </li>
                                <li>
                                    <strong>Une valeur faible </strong> est
                                    attribuée aux morceaux calmes (slow,
                                    ballade).
                                </li>
                            </ul>
                        </>
                    ),
                },
                valence: {
                    title: "Indice de sentiment (négativité vs positivité)",
                    description: () => (
                        <>
                            Cet indice représente une mesure perceptuelle de
                            l'humeur, liée à des sentiments positifs ou
                            négatifs, par exemple la joie ou la tristesse.
                            <ul>
                                <li>
                                    <strong>Une valeur élevée </strong>
                                    correspond à des titres à sentiments
                                    positifs
                                </li>
                                <li>
                                    <strong>Une valeur faible</strong> renvoie à
                                    des titres tristes.
                                </li>
                            </ul>
                        </>
                    ),
                },
                vocal_instrumental: {
                    title: "Caractère instrumental - vocal",
                    description: () => (
                        <>
                            Ce descripteur indique si une piste contient ou non
                            des sons vocaux.
                            <ul>
                                <li>
                                    <strong>Une valeur élevée </strong>
                                    indique que la piste ne contient
                                    probablement pas de sons vocaux
                                    (instrumentaux)
                                </li>
                                <li>
                                    <strong>Une valeur faible </strong>
                                    correspond probablement à une piste avec un
                                    contenu vocal (paroles chantées, rap, slam
                                    ou discours par exemple).
                                </li>
                            </ul>
                        </>
                    ),
                },
                music_speech: {
                    title: "Indicateur de contenu “parlé”",
                    description: () => (
                        <>
                            Ce descripteur indique le contenu vocal d'une piste.
                            <ul>
                                <li>
                                    <strong>Une valeur élevée </strong>
                                    correspond à la présence de paroles
                                    (interview, livre audio, poésie,
                                    commentaire)
                                </li>
                                <li>
                                    <strong>Une valeur faible </strong>
                                    correspond probablement à de la musique,
                                    éventuellement avec des voix (titres
                                    chantés), mais sans parties parlées
                                </li>
                            </ul>
                        </>
                    ),
                },
                electric_acoustic: {
                    title: "Accoustique vs électrique",
                    description: () => (
                        <>
                            Ce descripteur indique si la piste contient un
                            contenu acoustique
                            <ul>
                                <li>
                                    <strong>Une valeur élevée </strong>
                                    correspond à des pistes acoustiques
                                    (guitare/piano acoustique, voix, musique
                                    classique)
                                </li>
                                <li>
                                    <strong>Une valeur faible </strong>
                                    indique des pistes à contenu électrique
                                    (électronique, distorsion, effets audio)
                                </li>
                            </ul>
                        </>
                    ),
                },
                danceability: {
                    title: "Dansabilité",
                    description: () => (
                        <>
                            La dansabilité indique si un titre peut être
                            considéré comme dansant (perception du tempo et du
                            rythme, stabilité et régularité).
                            <ul>
                                <li>
                                    <strong>Une valeur élevée </strong>
                                    correspond à des titres plus dansants
                                </li>
                                <li>
                                    <strong>Une valeur faible </strong>
                                    est liée à des titres sans rythme
                                    perceptible
                                </li>
                            </ul>
                        </>
                    ),
                },
                studio_live: {
                    title: "Studio vs Live",
                    description: () => (
                        <>
                            Ce descripteur indique la probabilité que
                            l’enregistrement ait été joué en direct.
                            <ul>
                                <li>
                                    <strong>Une valeur élevée </strong>
                                    correspond à des titres exécutés en live
                                </li>
                                <li>
                                    <strong>Une valeur faible </strong>
                                    est plus probablement liée à des
                                    enregistrements en studio
                                </li>
                            </ul>
                        </>
                    ),
                },
                melodicity: {
                    title: "Mélodicité",
                    description: () => (
                        <>
                            Ce descripteur indique si une piste contient ou non
                            une mélodie clairement audible.
                            <ul>
                                <li>
                                    <strong>Une valeur élevée </strong>
                                    correspondent à des pistes agréables,
                                    harmoniques, sur lesquelles on a envie de
                                    chanter
                                </li>
                                <li>
                                    <strong>Une valeur faible </strong>
                                    correspondent plutôt à des bruits, des
                                    paysages sonores ou des pistes avec des
                                    harmonies ou des mélodies complexes
                                    (classique, jazz, etc.)
                                </li>
                            </ul>
                        </>
                    ),
                },
                dissonance: {
                    title: "Dissonance",
                    description: () => (
                        <>
                            Ce descripteur indique le degré de dissonance d'une
                            piste.
                            <ul>
                                <li>
                                    <strong>Une valeur élevée </strong>
                                    correspondent à des pistes sans harmonie
                                    claire, avec un timbre bruyant par exemple,
                                    ou avec des harmonies très complexes
                                    (intervalles dissonants tels que les
                                    tritons, les secondes, etc.)
                                </li>
                                <li>
                                    <strong>Une valeur faible </strong>
                                    correspondent à des pistes avec une harmonie
                                    simple, une mélodie claire, des harmonies
                                    consonantes (mélodies simples au piano par
                                    exemple)
                                </li>
                            </ul>
                        </>
                    ),
                },
            },
            creditsCreditsData: {
                licence: "Avec l'autorisation de Muso.Ai",
                musician: "Musicien",
                songwriter: "Auteur-compositeur",
                management: "Gestion",
                engineer: "Ingénieur",
                producers: "Producteurs",
                organization: "Organisation",
                other: "Autre",
                visual: "Visuel",
            },
            reviews: {
                writer: "Auteur",
                updated_at: "Mis à jour le",
                words: "Mots",
                signs: "Signes",
                review: "Chronique d'album",
                empty: "Aucune chronique",
            },
        },
    },
    recording: {
        view: {
            breadcrumb: "Visualisation recording",
            id: "ID Music-Story",
            title: "Titre",
            subtitle: "Sous-titre",
            isrc: "ISRC",
            genre: "Genre",
            profile: "Profil",
            credits: "Crédits",
            popularity: "Popularité",
            charts: "Charts",
            lyrics: "Paroles",
            audio_description: "Audio description",
            similar: "Recording similaire",
            work: "Oeuvre",
            noData: {
                lyrics: "Pas de paroles disponibles pour cet enregistrement",
                credits: "Pas de crédits disponibles pour cet enregistrement",
                charts: "Pas de charts disponibles pour cet enregistrement",
                audio: "Pas de description audio disponible pour cet enregistrement",
                popularity:
                    "Aucune donnée de popularité n'est disponible pour cet enregistrement",
                work: "Aucune donnée d'œuvre n'est disponible pour cet enregistrement",
            },
            profileFeaturing: {
                head: "Featurings",
            },
            profileInstrument: {
                head: "Instruments",
            },
            profileDuration: {
                head: "Durée",
            },
            profileRelatedIsrc: {
                head: "ISRC(s) Associé(s)",
                has_credits: "Crédits",
                has_lyric: "Parole",
                has_audio: "Audio",
            },
            profileTranslation: {
                head: "Traductions du titre",
            },
            profileAlbum: {
                head: "Albums",
                cover: "Pochette",
                title: "Titre",
                artists: "Artistes",
                type: {
                    head: "Type",
                    live: "Live",
                    remix: "Remix",
                    compilation: "Compilation",
                    karaoke: "Karaoke",
                    ring: "Sonnerie",
                    other: "Autre",
                    original: "Original",
                },
                format: {
                    head: "Format",
                    album: "Album",
                    ep: "EP",
                    single: "Single",
                    mixtape: "Mixtape",
                    mcd: "Maxi",
                },
                release_date: "Date de sortie",
                link: "Liens",
                empty: "Aucun Résultats",
            },
            chartsRecordingCharts: {
                charts: {
                    head: "Charts",
                    title: "Titre",
                    empty: "Aucun Résultats",
                    search: "Rechercher...",
                },
                filter: {
                    format: {
                        label: "Type",
                    },
                    reference: {
                        label: "Officiel",
                        description:
                            "Le classement officiel des ventes du pays",
                    },
                },
                trend: {
                    head: "Tendance",
                    x: "Date",
                    y: "Rang",
                    dynamicRank: "Ne pas afficher les rangs vides",
                    dynamicDate: "Ne pas afficher les dates vides",
                },
            },
            lyricsInformation: {
                head: "Information",
                licence: {
                    title: "Avec l'autorisation de LyricFind",
                    subtitle:
                        "Ce contenu ne peut être utilisé sans une licence spécifique délivrée par LyricFind",
                },
                lfid: "ID Lyricfind",
                hfa_code: "Code HFA",
                publisher_credit: "Crédit d'édition",
                territories: "Territoires",
                lyric: "Parole originale",
            },
            audioAudioDescription: {
                head: "Description audio",
                licence:
                    "Description audio calculée à l'aide de la technologie Simbals",
                moods: {
                    title: "Moods de l'enregistrement",
                    subtitle: "par ordre d'importance",
                    description: () => (
                        <>
                            Détail des moods de l'enregistrement et leurs poids
                            correspondants. <br />
                            <strong>Liste complète:</strong> heureux, triste,
                            calme, énergique, excité, étonné/éveillé,
                            tendu/alarmé/effrayé, en colère, en détresse, ravi,
                            convaincu, impatient, frustré, mécontent, satisfait,
                            intéressé, neutre, méfiant, malheureux/déçu/étonné,
                            serein/content, confiant, inquiet, mal à l'aise,
                            triste/dépressif/gloire, détendu/satisfait,
                            contemplatif, fatigué/sommeillé, somnolent,
                            ennuyé/anxieux.
                        </>
                    ),
                },
                themes: {
                    title: "Thèmes",
                    description: `Thèmes détaillés de l'enregistrement relatifs notamment aux contextes d’écoute.  Inclut, par exemple, les valeurs suivantes : "Entraînement", "Dîner", "Noël", "Fête", "Jeux vidéo", "Été", etc…`,
                },
                timbres: {
                    title: "Timbres",
                    description:
                        "Tags correspondant au timbre de l'enregistrement.",
                },
                arousal: {
                    title: "Intensité",
                    description: () => (
                        <>
                            Cet indicateur représente une mesure perceptuelle
                            d'un enregistrement liée à son intensité, son
                            énergie et son activité.
                            <ul>
                                <li>
                                    <strong>Une valeur élevée </strong>
                                    correspond généralement à un morceau à forte
                                    énergie (hard rock, métal)
                                </li>
                                <li>
                                    <strong>Une valeur faible </strong> est
                                    attribuée aux morceaux calmes (slow,
                                    ballade).
                                </li>
                            </ul>
                        </>
                    ),
                },
                valence: {
                    title: "Indice de sentiment (négativité vs positivité)",
                    description: () => (
                        <>
                            Cet indice représente une mesure perceptuelle de
                            l'humeur, liée à des sentiments positifs ou
                            négatifs, par exemple la joie ou la tristesse.
                            <ul>
                                <li>
                                    <strong>Une valeur élevée </strong>
                                    correspond à des titres à sentiments
                                    positifs
                                </li>
                                <li>
                                    <strong>Une valeur faible</strong> renvoie à
                                    des titres tristes.
                                </li>
                            </ul>
                        </>
                    ),
                },
                vocal_instrumental: {
                    title: "Caractère instrumental - vocal",
                    description: () => (
                        <>
                            Ce descripteur indique si une piste contient ou non
                            des sons vocaux.
                            <ul>
                                <li>
                                    <strong>Une valeur élevée </strong>
                                    indique que la piste ne contient
                                    probablement pas de sons vocaux
                                    (instrumentaux)
                                </li>
                                <li>
                                    <strong>Une valeur faible </strong>
                                    correspond probablement à une piste avec un
                                    contenu vocal (paroles chantées, rap, slam
                                    ou discours par exemple).
                                </li>
                            </ul>
                        </>
                    ),
                },
                music_speech: {
                    title: "Indicateur de contenu “parlé”",
                    description: () => (
                        <>
                            Ce descripteur indique le contenu vocal d'une piste.
                            <ul>
                                <li>
                                    <strong>Une valeur élevée </strong>
                                    correspond à la présence de paroles
                                    (interview, livre audio, poésie,
                                    commentaire)
                                </li>
                                <li>
                                    <strong>Une valeur faible </strong>
                                    correspond probablement à de la musique,
                                    éventuellement avec des voix (titres
                                    chantés), mais sans parties parlées
                                </li>
                            </ul>
                        </>
                    ),
                },
                electric_acoustic: {
                    title: "Accoustique vs électrique",
                    description: () => (
                        <>
                            Ce descripteur indique si la piste contient un
                            contenu acoustique
                            <ul>
                                <li>
                                    <strong>Une valeur élevée </strong>
                                    correspond à des pistes acoustiques
                                    (guitare/piano acoustique, voix, musique
                                    classique)
                                </li>
                                <li>
                                    <strong>Une valeur faible </strong>
                                    indique des pistes à contenu électrique
                                    (électronique, distorsion, effets audio)
                                </li>
                            </ul>
                        </>
                    ),
                },
                danceability: {
                    title: "Dansabilité",
                    description: () => (
                        <>
                            La dansabilité indique si un titre peut être
                            considéré comme dansant (perception du tempo et du
                            rythme, stabilité et régularité).
                            <ul>
                                <li>
                                    <strong>Une valeur élevée </strong>
                                    correspond à des titres plus dansants
                                </li>
                                <li>
                                    <strong>Une valeur faible </strong>
                                    est liée à des titres sans rythme
                                    perceptible
                                </li>
                            </ul>
                        </>
                    ),
                },
                studio_live: {
                    title: "Studio vs Live",
                    description: () => (
                        <>
                            Ce descripteur indique la probabilité que
                            l’enregistrement ait été joué en direct.
                            <ul>
                                <li>
                                    <strong>Une valeur élevée </strong>
                                    correspond à des titres exécutés en live
                                </li>
                                <li>
                                    <strong>Une valeur faible </strong>
                                    est plus probablement liée à des
                                    enregistrements en studio
                                </li>
                            </ul>
                        </>
                    ),
                },
                melodicity: {
                    title: "Mélodicité",
                    description: () => (
                        <>
                            Ce descripteur indique si une piste contient ou non
                            une mélodie clairement audible.
                            <ul>
                                <li>
                                    <strong>Une valeur élevée </strong>
                                    correspondent à des pistes agréables,
                                    harmoniques, sur lesquelles on a envie de
                                    chanter
                                </li>
                                <li>
                                    <strong>Une valeur faible </strong>
                                    correspondent plutôt à des bruits, des
                                    paysages sonores ou des pistes avec des
                                    harmonies ou des mélodies complexes
                                    (classique, jazz, etc.)
                                </li>
                            </ul>
                        </>
                    ),
                },
                dissonance: {
                    title: "Dissonance",
                    description: () => (
                        <>
                            Ce descripteur indique le degré de dissonance d'une
                            piste.
                            <ul>
                                <li>
                                    <strong>Une valeur élevée </strong>
                                    correspondent à des pistes sans harmonie
                                    claire, avec un timbre bruyant par exemple,
                                    ou avec des harmonies très complexes
                                    (intervalles dissonants tels que les
                                    tritons, les secondes, etc.)
                                </li>
                                <li>
                                    <strong>Une valeur faible </strong>
                                    correspondent à des pistes avec une harmonie
                                    simple, une mélodie claire, des harmonies
                                    consonantes (mélodies simples au piano par
                                    exemple)
                                </li>
                            </ul>
                        </>
                    ),
                },
                extra: {
                    rythm: "Rythme",
                    timbre: "Timbre",
                    harmony: "Harmonie",
                    energy: "Energie",
                },
                articulation: {
                    title: "Articulation",
                    description: "Articulation",
                },
                rhythmic_stability: {
                    title: "Rhythmic stability",
                    description: "Rhythmic stability",
                },
                event_density: {
                    title: "Event density",
                    description: "Event density",
                },
                pulse_clarity: {
                    title: "Pulse clarity",
                    description: "Pulse clarity",
                },
                bpm: {
                    title: "BPM",
                    description: "BPM",
                },
                complexity: {
                    title: "Complexity",
                    description: "Complexity",
                },
                binary: {
                    title: "Binary",
                    description: "Binary",
                },
                roll_off: {
                    title: "Roll off",
                    description: "Roll off",
                },
                brightness: {
                    title: "Brightness",
                    description: "Brightness",
                },
                zero_cross_rate: {
                    title: "Zero cross rate",
                    description: "Zero cross rate",
                },
                mfcc: {
                    title: "MFCC",
                    description: "MFCC",
                },
                mfcc01: {
                    title: "Mfcc01",
                },
                mfcc02: {
                    title: "Mfcc02",
                },
                mfcc03: {
                    title: "Mfcc03",
                },
                mfcc04: {
                    title: "Mfcc04",
                },
                mfcc05: {
                    title: "Mfcc05",
                },
                mfcc06: {
                    title: "Mfcc06",
                },
                mfcc07: {
                    title: "Mfcc07",
                },
                mfcc08: {
                    title: "Mfcc08",
                },
                mfcc09: {
                    title: "Mfcc09",
                },
                mfcc10: {
                    title: "Mfcc10",
                },
                mfcc11: {
                    title: "Mfcc11",
                },
                mfcc12: {
                    title: "Mfcc12",
                },
                mfcc13: {
                    title: "Mfcc13",
                },
                chroma: {
                    title: "Chroma (Harmony, key)",
                    description: "Chroma (Harmony, key)",
                },
                chroma01: {
                    title: "Chroma01",
                },
                chroma02: {
                    title: "Chroma02",
                },
                chroma03: {
                    title: "Chroma03",
                },
                chroma04: {
                    title: "Chroma04",
                },
                chroma05: {
                    title: "Chroma05",
                },
                chroma06: {
                    title: "Chroma06",
                },
                chroma07: {
                    title: "Chroma07",
                },
                chroma08: {
                    title: "Chroma08",
                },
                chroma09: {
                    title: "Chroma09",
                },
                chroma10: {
                    title: "Chroma10",
                },
                chroma11: {
                    title: "Chroma11",
                },
                chroma12: {
                    title: "Chroma12",
                },
                complexity_chroma: {
                    title: "Complexity chroma",
                    description: "Complexity chroma",
                },
                intensity: {
                    title: "Intensity",
                    description: "Intensity",
                },
                loudness: {
                    title: "Loudness",
                    description: "Loudness",
                },
                absolute_loudness: {
                    title: "Absolute loudness",
                    description: "Absolute loudness",
                },
                loudness_range: {
                    title: "Loudness range",
                    description: "Loudness range",
                },
                centroid: {
                    title: "Centroid",
                    description: "Centroid",
                },
                spread: {
                    title: "Spread",
                    description: "Spread",
                },
                flatness: {
                    title: "Flatness",
                    description: "Flatness",
                },
            },
            creditsCreditsData: {
                licence: "Avec l'autorisation de Muso.Ai",
                musician: "Musicien",
                songwriter: "Auteur-compositeur",
                management: "Gestion",
                engineer: "Ingénieur",
                producers: "Producteurs",
                organization: "Organisation",
                other: "Autre",
                visual: "Visuel",
            },
            popularityOverview: {
                head: "Vue d'ensemble",
                global: {
                    title: "Globale",
                    weeks: "Semaines dans les charts :",
                    description:
                        "La popularité d'un titre est calculée sur en fonction de sa présence et de sa position dans les charts que nous suivons. Elle prend deux formes : le rang (la position du titre dans notre classement des titres populaires), et le niveau de popularité (en %, relative au score de popularité max de notre classement).",
                },
                gold: {
                    title: "Catalogue",
                    weeks: "Semaines dans les charts catalogues :",
                    description:
                        "La popularité catalogue d'un titre est basée sur sa présence dans les charts officiels datant de plus de 18 mois.\nLa popularité Catalogue ne prend en compte que les données historiques du Billboard Hot 100, du top 100 UK Singles Charts, du SNEP et du Offizielle Deutsche Charts. D'autres charts officiels sont en cours d'intégration.",
                },
                current: {
                    title: "Actuelle",
                    weeks: "Semaines dans les charts actuels :",
                    description:
                        "La popularité actuelle d'un titre est basée sur sa présence dans les charts datant de moins de 18 mois.",
                },
                type: {
                    label: "Typologie :",
                    gold: "Catalogue",
                    current: "Actuel",
                },
                peak: "Pic :",
            },
            popularityHistogram: {
                head: "Évolution de la popularité",
                tabs: {
                    gold: {
                        title: "Catalogue",
                        description:
                            "La popularité Catalogue d'un artiste est basée sur sa présence dans les charts datant de plus de 18 mois.\nLa popularité Catalogue ne prend en compte que les données historiques du Billboard Hot 100, du top 100 UK Singles Charts, du SNEP et du Offizielle Deutsche Charts. D'autres charts officiels sont en cours d'intégration.",
                        header: "La popularité Catalogue ne prend en compte que les données historiques du Billboard Hot 100, du top 100 UK Singles Charts, du SNEP et du Offizielle Deutsche Charts. D'autres charts officiels sont en cours d'intégration.",
                    },
                    current: {
                        title: "Actuelle",
                        description:
                            "La popularité actuelle d'un artiste est basée sur sa présence dans les charts datant de moins de 18 mois.",
                    },
                },
                gold: "Popularité catalogue",
                current: "Popularité actuelle",
                popover: {
                    noData: "Aucunes données",
                    popularity: "Popularité du titre :",
                    bestRank: "Meilleure position :",
                    bestCountry: "Pays de la meilleure position :",
                    charts: (n) => `Sur la base de ${n} charts`,
                },
            },
            popularityMap: {
                head: "Popularité par pays",
                tabs: {
                    gold: {
                        title: "Catalogue",
                        description:
                            "La popularité Catalogue d'un artiste est basée sur sa présence dans les charts datant de plus de 18 mois.\nLa popularité Catalogue ne prend en compte que les données historiques du Billboard Hot 100, du top 100 UK Singles Charts, du SNEP et du Offizielle Deutsche Charts. D'autres charts officiels sont en cours d'intégration.",
                    },
                    current: {
                        title: "Actuelle",
                        description:
                            "La popularité actuelle d'un artiste est basée sur sa présence dans les charts datant de moins de 18 mois.",
                    },
                },
                popover: {
                    noData: "Aucunes données",
                    popularity: "Popularité du titre :",
                    bestRank: "Meilleure position :",
                    bestMonth: "Mois de la meilleure position :",
                    weeksInCharts: "Semaines dans les charts :",
                    charts: (n) => `Sur la base de ${n} charts`,
                },
            },
            workData: {
                iswc: "ISWC",
                hfa: "Code HFA",
                publisher_credit: "Crédit d'édition (Lyricfind)",
                share: {
                    head: "Répartition des parts aux Etats-Unis (MLC)",
                    party: "Partie",
                    ipi: "IPI",
                    role: "Rôle",
                    parent: "Partie Parent",
                    parent_ipi: "IPI Parent",
                    share: "% contrôlé",
                },
            },
        },
    },
    genre: {
        breadcrumb: "Genres",
        genre: {
            head: "Genres",
            parent: "Nom parent",
            name: "Nom",
        },
        related: {
            head: "Associés",
            name: "Nom",
            weight: "Type",
        },
        artist: {
            head: "Top Artistes",
            position: "N°",
            name: "Nom",
            complement: "Complément",
            actions: "Actions",
            country: "Pays",
            type: "Type",
            role: "Rôle",
        },
        button: "Gestion des genres",
        search: "Rechercher...",
        empty: "Aucun Résultats",
        manage: {
            breadcrumb: "Management",
            genre: {
                head: "Genre",
                empty: "Aucun Résultats",
                id: "Id",
                name: "Nom",
                description: "Description",
                related: "Associés",
                genre: "Genre",
                type: "Type",
                genre_fr: "Genre FR",
                genre_en: "Genre EN",
                other_translations: "Autres traductions",
                parent_genre: "Genre parent",
                parent_genre_placeholder:
                    "Ne choisissez rien pour créer un genre parent",
                created_at: "Date d'ajout",
                updated_at: "Date de modification",
                action: "Action",
                search: "Rechercher...",
            },
            create: {
                title: "Ajouter un genre",
                success: "Genre ajouté",
                error: (message) =>
                    `Erreur lors de l'ajout du genre: ${message}`,
            },
            update: {
                title: "Modifier un genre",
                success: "Genre modifié",
                error: (message) =>
                    `Erreur lors de la modification du genre: ${message}`,
            },
            delete: {
                title: "Supprimer un genre",
                alert: {
                    title: "Êtes-vous sûr ?",
                    text: "De vouloir supprimer ce genre ?",
                },
                success: "Genre supprimé",
                error: (message) =>
                    `Erreur lors de la suppression du genre: ${message}`,
            },
        },
    },
    country: {
        breadcrumb: "Pays",
        head: "Pays",
        button: "Gestion des pays",
        code: "Code",
        country_fr: "Pays FR",
        country_en: "Pays EN",
        country_ja: "Pays JA",
        country_de: "Pays DE",
        country_ko: "Pays KO",
        search: "Rechercher...",
        empty: "Aucun pays à afficher",
        manage: {
            breadcrumb: "Management",
            country: {
                head: "Pays",
                empty: "Aucun Résultats",
                id: "Id",
                code: "Code",
                country_fr: "Pays FR",
                country_en: "Pays EN",
                other_translations: "Autres traductions",
                created_at: "Date d'ajout",
                updated_at: "Date de modification",
                action: "Action",
                search: "Rechercher...",
            },
            create: {
                title: "Ajouter un pays",
                success: "Pays ajouté",
                error: (message) =>
                    `Erreur lors de l'ajout du pays: ${message}`,
            },
            update: {
                title: "Modifier un pays",
                success: "Pays modifié",
                error: (message) =>
                    `Erreur lors de la modification du pays: ${message}`,
            },
            delete: {
                title: "Supprimer un pays",
                alert: {
                    title: "Êtes-vous sûr ?",
                    text: "De vouloir supprimer ce pays ?",
                },
                success: "Pays supprimé",
                error: (message) =>
                    `Erreur lors de la suppression du pays: ${message}`,
            },
        },
    },
    misc_entity: {
        breadcrumb: "Spectacles",
        head: "Spectacles",
        search: "Rechercher...",
        empty: "Aucun spectacle à afficher",
        id: "id",
        name: "nom",
        type: "type",
        description: "description",
        date: "date",
        management: {
            breadcrumb: "Gestion",
            profile: "Profile",
            curation: "Curation",
            getty: "Getty",
            main: "Main information",
            misc_entity: {
                id: "ID",
                name: "Nom",
                type: "Type",
                description: "Description",
                date: "Date",
                created_at: "Created At",
                updated_at: "Updated At",
                action: "Action",
                search: "Search...",
            },
            imageCuration: {
                head: "Galerie",
                empty: "Aucune image présente dans la galerie",
                width: "Largeur",
                height: "Hauteur",
                created_at: "Date de création",
                copyright: "Copyright",
                status: "Statut",
                edit: "Modifier",
                edit_image: "Modifier les informations de l'image",
                delete: "Supprimer",
                delete_image: "Supprimer l'image",
            },
            imageGetty: {
                gallery: {
                    head: "Galerie",
                    empty: "Aucune image présente dans la galerie",
                    width: "Largeur",
                    height: "Hauteur",
                    created_at: "Date de création",
                    copyright: "Copyright",
                    status: "Statut",
                    edit: "Modifier",
                    edit_image: "Modifier les informations de l'image",
                    delete: "Supprimer",
                    delete_image: "Supprimer l'image",
                },
            },
            create: {
                title: "Ajouter entité",
                success: "Entité ajoutée",
                error: (message) =>
                    `Erreur lors de l'ajout de l'entité: ${message}`,
            },
            update: {
                title: "Modifier une entité",
            },
            delete: {
                title: "Supprimer une entité",
                alert: {
                    title: "Êtes-vous sûr ?",
                    text: "De vouloir supprimer cette entité ?",
                },
                success: "Entité supprimée",
                error: (message) =>
                    `Erreur lors de la suppression cette entité: ${message}`,
            },
        },
    },
    role: {
        breadcrumb: "Role",
        head: "Role",
        button: "Gestion des Roles",
        role_fr: "Role FR",
        role_en: "Role EN",
        role_ja: "Role JA",
        role_de: "Role DE",
        role_ko: "Role KO",
        search: "Rechercher...",
        empty: "Aucun role à afficher",
        manage: {
            breadcrumb: "Management",
            role: {
                head: "Role",
                empty: "Aucun Résultats",
                id: "Id",
                role_fr: "Role FR",
                role_en: "Role EN",
                other_translations: "Autres traductions",
                created_at: "Date d'ajout",
                updated_at: "Date de modification",
                action: "Action",
                search: "Rechercher...",
            },
            create: {
                title: "Ajouter un role",
                success: "Role ajouté",
                error: (message) =>
                    `Erreur lors de l'ajout du role: ${message}`,
            },
            update: {
                title: "Modifier un role",
                success: "Role modifié",
                error: (message) =>
                    `Erreur lors de la modification du role: ${message}`,
            },
            delete: {
                title: "Supprimer un role",
                alert: {
                    title: "Êtes-vous sûr ?",
                    text: "De vouloir supprimer ce role ?",
                },
                success: "Role supprimé",
                error: (message) =>
                    `Erreur lors de la suppression du role: ${message}`,
            },
        },
    },
    plannings: {
        breadcrumb: "Plannings",
        manage: {
            breadcrumb: "Gestion des plannings",
            planning: {
                empty: "Aucun Résultats",
                building: "En préparation",
                progress: "En cours",
                done: "Archives",
                title: "Titre",
                startDate: "Date début",
                endDate: "Date fin",
                stats: "Avancement",
                itemsCount: "Résultat(s)",
            },
            create: {
                title: "Création d'un planning",
            },
            update: {
                title: "Modification d'un planning",
            },
        },
        view: {
            breadcrumb: "Consulter un planning",
            partner: {
                all: "Résumé",
            },
            action: {
                validate: {
                    title: "Valider le planning",
                    swal: {
                        title: "Êtes-vous sûr ?",
                        notCompleted:
                            "Le planning n'est pas entièrement complété",
                        text: "De vouloir valider ce planning ?",
                    },
                },
                deleted: {
                    title: "Supprimer le planning",
                    swal: {
                        title: "Êtes-vous sûr ?",
                        tasksExist: "Le planning n'est pas vide",
                        text: "De vouloir supprimer ce planning ?",
                    },
                },
            },
            tasks: {
                title: "Tâches",
                empty: "Aucun Résultats",
                manager: "Gestionnaire",
                status: "Statut",
                artist: "Artiste",
                album: "Album",
                track: "Track",
                category: "Catégorie",
                assignement: "Affectation",
                classification: "Class.",
                tasks: "Tâche(s)",
                reason: "Motif",
                information: "Informations",
                nbRelated: "Nombre d'associés",
                albumsWithoutGenre: "Albums sans genre",
                hasBioFR: "Présence Bio FR",
                hasBioEN: "Présence Bio EN",
                hasBioDE: "Présence Bio DE",
                hasBioJA: "Présence Bio JA",
                hasBioES: "Présence Bio ES",
                hasBioPT: "Présence Bio PT",
                hasReview: "Présence Chronique d'album",
                hasPicture: "Présence Photo avec droits",
                hasCuration: "Présence Photo de curation",
                lastUpdated: "Dernière mise au planning",
                genre: "Genre",
                language: "Langue",
                country: "Pays",
                action: {
                    topLink: "Top associé",
                    lyricsSampleLink: "Lyric assessment",
                    discoLink: "Discographie",
                    create: {
                        title: "Création d'une tâche",
                    },
                    update: {
                        title: "Modification de la tâche",
                    },
                    delete: {
                        title: "Suppression de la tâche",
                        alert: {
                            title: "Êtes-vous sûr ?",
                            text: "De vouloir supprimer cette tâche ?",
                        },
                    },
                },
                subtasks: {
                    change_status: "Modifier le statut :",
                    confirm_change_status: {
                        TODO: {
                            title: "Êtes-vous sûr ?",
                            text: "De vouloir passer le status de cette tâche en À faire ?",
                        },
                        COMPLETED: {
                            title: "Êtes-vous sûr ?",
                            text: "De vouloir passer le status de cette tâche en Complétée ?",
                        },
                        TO_BE_CORRECTED: {
                            title: "Êtes-vous sûr ?",
                            text: "De vouloir passer le status de cette tâche en À corriger ?",
                            comment: {
                                title: "Ajouter un commentaire",
                                error: "Un commentaire est requis",
                            },
                        },
                        VERIFIED_AND_VALIDATED: {
                            title: "Êtes-vous sûr ?",
                            text: "De vouloir passer le status de cette tâche en Vérifiée et validée ?",
                        },
                        VALIDATED_WITHOUT_CONTROL: {
                            title: "Êtes-vous sûr ?",
                            text: "De vouloir passer le status de cette tâche en Validé sans contrôle ?",
                        },
                        ERROR: {
                            title: "Êtes-vous sûr ?",
                            text: "De vouloir passer le status de cette tâche en Erreur ?",
                            comment: {
                                title: "Ajouter un commentaire",
                            },
                        },
                    },
                    status: {
                        UNASSIGNED: "Non assigné",
                        TODO: "À faire",
                        COMPLETED: "Complété",
                        TO_BE_CORRECTED: "À corriger",
                        VALIDATED_WITHOUT_CONTROL: "Validé sans contrôle",
                        VERIFIED_AND_VALIDATED: "Vérifié et validé",
                        ERROR: "En erreur",
                    },
                },
            },
            stats: {
                title: "Stats",
                manager: "Manager",
            },
            progress: {
                title: "Progression",
                username: "Username",
            },
            budget: {
                title: "Budget",
                username: "Username",
                task_type: "Task type",
            },
            history: {
                title: "Afficher l'historique",
                head: "Historique",
                empty: "Aucun Résultats",
                search: "Rechercher un artiste",
                action: {
                    created_by: "Créée par",
                    deleted_by: "Supprimée par",
                    modified_by: "Modifiée par",
                    release_matched_by: "Album matché par",
                    release_unmatched_by: "Album dé-matché par",
                    track_matched_by: "Track matchée par",
                    track_unmatched_by: "Track dé-matchée par",
                    status_changed_by: (new_status) =>
                        `Marqué comme ${new_status} par`,
                    status_comment: (comment) => `Commentaire : ${comment}`,
                },
            },
        },
        tasks: {
            planning: "Planning",
            artist: "Artiste",
            nbRelated: "Nombre d'associés",
            hasBioFR: "Présence Bio FR",
            hasBioEN: "Présence Bio EN",
            hasBioDE: "Présence Bio DE",
            hasBioJA: "Présence Bio JA",
            hasBioES: "Présence Bio ES",
            hasBioPT: "Présence Bio PT",
            hasPicture: "Présence Photo avec droits",
            hasCuration: "Présence Photo de curation",
            album: "Album",
            track: "Track",
            category: "Catégorie",
            classification: "Classification",
            assignement: "Affectation",
            reason: "Motif",
            error: {
                required: "Ce champ est requis",
                albumTrackRequired: "Le champ album ou track est requis",
            },
            subtasks: {
                title: "Tâche(s)",
                typePlaceholder: "Tâche...",
                assignementPlaceholder: "Affectée à...",
                new: "Ajouter une tâche",
                remove: "Supprimer la tâche",
                typeGroups: {
                    ART: "Artiste",
                    ART_C: "Correction Artiste",
                    BIO_FR: "Biographie Française",
                    BIO_EN: "Biographie Anglaise",
                    BIO_DE: "Biographie Allemande",
                    BIO_PT: "Biographie Portugaise",
                    BIO_JP: "Biographie Japonaise",
                    BIO_FR_C: "Correction Biographie Française",
                    BIO_EN_C: "Correction Biographie Anglaise",
                    BIO_DE_C: "Correction Biographie Allemande",
                    BIO_JP_C: "Correction Biographie Japonaise",
                    RVW: "Chronique",
                    RVW_C: "Correction Chronique",
                    APL_AS: "Lyrics assessment",
                    APL_CAS: "Lyrics counter-assessment",
                },
                error: {
                    oneTypePerGroup: (category) =>
                        `Plusieurs tâches ont été définies dans la catégorie ${category}`,
                },
            },
        },
        production: {
            breadcrumb: "Page d'accueil",
            industry: "Statistiques marchés",
            ms: "Statistiques base de données",
            my_planning: "Mon planning",
            tasks: {
                empty: "Aucune tâche n'est planifiée",
                artist: "Artiste",
                album: "Album",
                track: "Track",
                genre: "Genre",
                country: "Pays",
                category: "Catégorie-Motif",
                classification: "Classification",
                tasks: "Tâche(s)",
                related_tasks: "Related Tasks",
                subtasks: {
                    change_status: "Modifier le statut :",
                    match_required:
                        "Un matching est requis sur l'album ou la track",
                    confirm_change_status: {
                        COMPLETED: {
                            title: "Êtes-vous sûr ?",
                            text: "De vouloir passer le status de cette tâche en Complétée ?",
                        },
                        ERROR: {
                            title: "Êtes-vous sûr ?",
                            text: "De vouloir passer le status de cette tâche en Erreur ?",
                            comment: {
                                title: "Ajouter un commentaire",
                            },
                        },
                    },
                    status: {
                        TODO: "À faire",
                        COMPLETED: "Complété",
                        TO_BE_CORRECTED: "À corriger",
                        VALIDATED_WITHOUT_CONTROL: "Validé sans contrôle",
                        VERIFIED_AND_VALIDATED: "Vérifié et validé",
                        ERROR: "En erreur",
                    },
                },
            },
        },
        client: {
            title: "Planning de production Music Story",
            sub_title: ({ title, start_date, end_date }) =>
                `du ${start_date} au ${end_date} ${title ? `(${title})` : ""}`,
            status: ({ status }) => {
                const translatedStatus = {
                    BUILDING: "Statut : En préparation",
                    PROGRESS: "Statut : En cours",
                    DONE: "Statut : Archives",
                };
                return translatedStatus[status];
            },
            tasks: {
                empty: "Aucune tâche n'est planifiée",
                artist: "Artiste",
                album: "Album",
                track: "Track",
                category: "Catégorie",
                status_artist: "Profil Artiste",
                status_bio_fr: "Bio FR",
                status_bio_en: "Bio EN",
                status_bio_de: "Bio DE",
                status_bio_ja: "Bio JA",
                status_review: "Review",
                status_picture: "Photo avec droits",
                status_curation: "Photo de curation",
                status: {
                    creation: "Création",
                    update: "Mise à jour",
                    new: "Nouveau",
                    progress: "En cours de création",
                    exist: "Déjà présent",
                    missing: "Absent",
                },
            },
        },
        picture: {
            breadcrumb: "Travaux photo",
            planning: {
                head: "Plannings",
                title: "Titre",
                empty: "Aucun Résultats",
                status: "Statut",
                building: "En préparation",
                progress: "En cours",
                done: "Archives",
                startDate: "Date début",
                endDate: "Date fin",
            },
            tasks: {
                title: "Tâches",
                empty: "Aucun Résultats",
                artist: "Artiste",
                album: "Album",
                track: "Track",
                latest_added_date: "Dernière date d'ajout",
                status: "Statut",
                information: "Informations",
                label: "Label",
                getty: "Getty",
                not_found: "Non trouvé",
                comment: "Commentaire",
                last_label_created_at:
                    "Date d'ajout de la dernière photo Label",
                last_getty_created_at:
                    "Date d'ajout de la dernière photo Getty",
                max_getty_date: "Date la plus récente des photos Getty",
                has_disco: "Disco",
                last_check_date: "Date de dernière vérification",
                itemsCount: (count) => {
                    return `${count} Résultat${count > 1 ? "s" : ""}`;
                },
            },
        },
        billing: {
            breadcrumb: "Facturation",
            empty: "Aucune facturation disponible",
            month: {
                period: ({ start_date, end_date }) =>
                    `Période du ${start_date} au ${end_date}`,
            },
            subtasks: {
                artist: "Artiste",
                album: "Album",
                track: "Track",
                validated_at: "Date de validation",
            },
        },
        statistic: {
            breadcrumb: "Statistiques",
            tracking_period: "Suivi par période",
            chart_tracking: "Suivi des charts",
            ressources_tracking: "Suivi des ressources",
            planning_annual: "Suivi annuel",
            charts_annual_tracking: "Suivi annuel des charts",
            resources_annual_tracking: "Suivi annuel des resources",
        },
    },
    apple: {
        sample: {
            assessment: {
                informations: {
                    head: "Informations",
                    catalogType: "Type de catalogue",
                    month: "Mois",
                    artist: "Artiste",
                    album: "Album",
                    title: "Titre",
                    duration: "Durée",
                    isrc: "Isrc",
                    genre: "Genre",
                    country: "Pays",
                    language: "Langage",
                    status: {
                        TODO: "À faire",
                        COMPLETED: "Complété",
                        TO_BE_CORRECTED: "À corriger",
                        VALIDATED_WITHOUT_CONTROL: "Validé sans contrôle",
                        VERIFIED_AND_VALIDATED: "Vérifié et validé",
                        ERROR: "En erreur",
                    },
                    planning: "Planning",
                    manager: "Manager",
                },
                lyric: {
                    head: "Parole",
                    empty: "Aucun résultat",
                    nbLine: "Ligne",
                    timing: "Durée",
                    text: "Texte",
                    rating: {
                        filter: "Voir les évaluations de cette ligne",
                        criteria: "Critère évalué",
                        errorType: "Type d'erreur",
                        comment: "Commentaire",
                        create: {
                            title: "Ajouter une évaluation",
                            success: "Evaluation ajoutée",
                            error: (message) =>
                                `Erreur lors de l'ajout de l'évaluation: ${message}`,
                        },
                    },
                },
                rating: {
                    reset: "Voir toutes les évaluations",
                    line: (line_number) => `Ligne : ${line_number}`,
                    criteria: "Critère évalué :",
                    errorType: "Type d'erreur :",
                    comment: "Commentaire :",
                    delete: {
                        tooltip: "Supprimer l'évaluation",
                        alert: {
                            title: "Êtes-vous sûr ?",
                            text: "De vouloir supprimer l'évaluation ?",
                        },
                        success: "L'évaluation a été supprimé",
                    },
                },
                player: {
                    action: {
                        previous: "Revenir au début (X)",
                        back10: "Revenir de 10 secondes (C)",
                        pause: "Mettre la musique sur pause (ESPACE)",
                        play: "Lancer la musique (ESPACE)",
                    },
                    login: "Connexion",
                    logout: "Déconnexion",
                    playerError: {
                        unavailable: "Aucun player disponible",
                        restricted: "Contenu restreint",
                    },
                },
                ratingType: {
                    assessment: "Evaluation",
                    counterAssessment: "Contre Evaluation",
                },
            },
            management: {
                sample: {
                    head: "Sample",
                    empty: "Aucun résultat",
                    date: "Date",
                },
                catalog: "Catalogue",
                stats: "Stats",
                progress: "Progression",
                lyrics: {
                    head: "Lyrics",
                    empty: "Aucun résultat",
                    catalog_type: "Type de catalogue",
                    provider: "Fournisseur",
                    user: "Utilisateur",
                    artist: "Artiste",
                    album: "Album",
                    title: "Titre",
                    isrc: "Isrc",
                    genre: "Genre",
                    language: "Langue",
                    country: "Pays",
                    assessment: "Assessment",
                    counter_assessment: "Counter-Assessment",
                    filters: {
                        planning: "Planning",
                        assignement: "Affecté à",
                        nb_line: "Nombre de ligne",
                        assessment: {
                            yes: "Avec assessment",
                            no: "Sans assessment",
                        },
                        counter_assessment: {
                            yes: "Avec counter-assessment",
                            no: "Sans counter-assessment",
                        },
                    },
                    action: {
                        addToPlanning: {
                            title: "Ajouter au planning",
                            exist: "Déjà présent dans un planning",
                        },
                    },
                },
            },
            report: {
                sample: {
                    head: "Sample",
                    empty: "Aucun résultat",
                    date: "Date",
                },
                scorecard_provider: "Scorecard Provider",
                scorecard_apple_user: "Scorecard Apple User",
                ranking_language_genre: "Ranking Language/Genre",
                explorer: "Explorer",
                error: "Error details",
            },
        },
    },
    cnm: {
        repositories: {
            repositories: {
                title: "Référentiels",
                empty: "Aucun résultat",
            },
            years: {
                title: "Années",
                empty: "Aucun résultat",
            },
            upload: {
                button: {
                    title: "Ajouter",
                },
                modal: {
                    title: "Ajouter un référentiel",
                    file: "Fichier",
                    file_placeholder: {
                        csv: "Sélectionner un fichier CSV",
                        json: "Sélectionner un fichier JSON",
                    },
                    browse: "Parcourir",
                    type: "Type",
                    year: "Année",
                    submit: "Ajouter",
                    submitting: "Ajout en cours",
                },
                conflict: {
                    usedIn: ({ name }) => `${name} est utilisé dans `,
                    titleLink: ({ artist, title }) => `${title} par ${artist}`,
                },
                success: "Le référentiel a été ajouté",
            },
            csa_artists: {
                title: "Artistes CSA",
                upload_help:
                    "Le fichier CSV doit avoir une seule colonne contenant les noms des artistes, et ne doit pas avoir d'entête.",
                search: "Rechercher...",
                empty: "Aucun résultat",
                name: "Nom",
                conflict:
                    "Les artistes CSA ci-dessous sont utilisés dans le top consolidé mais ne sont pas présents dans le fichier uploadé :",
            },
            cnm_artists_titles: {
                title: "Artistes/Titres CNM",
                artists: {
                    head: "Artistes",
                    search: "Rechercher...",
                    id: "ID",
                    name: "Nom",
                    msArtists: "Artistes MS",
                    noArtist: "Titres sans artiste",
                    edit: {
                        tooltip: "Modifier l'artiste",
                        title: "Modifier un artiste",
                        msArtistsPlaceholder: "Sélectionner un artiste",
                        submit: "Modifier",
                        success: "L'artiste a été modifié",
                    },
                    delete: {
                        tooltip: "Supprimer l'artiste",
                        title: "Supprimer l'artiste",
                        text: "Êtes-vous sûr de vouloir supprimer l'artiste ?",
                        submit: "Supprimer",
                        success: "L'artiste a été supprimé",
                    },
                },
                titles: {
                    head: ({ noArtist, artistName }) =>
                        noArtist
                            ? "Titres sans artiste"
                            : artistName
                            ? `Titres de ${artistName}`
                            : "Titres",
                    search: "Rechercher...",
                    id: "ID",
                    artist: "Artiste",
                    title: "Titre",
                    msRecordings: "Recordings MS",
                    edit: {
                        tooltip: "Modifier le titre",
                        title: "Modifier un titre",
                        msRecordingsPlaceholder: "Sélectionner un recording",
                        submit: "Modifier",
                        success: "Le titre a été modifié",
                    },
                    delete: {
                        tooltip: "Supprimer le titre",
                        title: "Supprimer le titre",
                        text: "Êtes-vous sûr de vouloir supprimer le titre ?",
                        matchings:
                            "Le titre est matché dans les tops plateformes ci-dessous, et ne peut pas être supprimé.",
                        position: (positions) => `position(s) ${positions}`,
                        submit: "Supprimer",
                        success: "Le titre a été supprimé",
                    },
                },
            },
            cnm_genres: {
                title: "Genres CNM",
                upload_help:
                    "Le fichier CSV doit avoir une seule colonne contenant les noms des genres, et ne doit pas avoir d'entête.",
                genres: {
                    title: "Genres CNM",
                    search: "Rechercher...",
                    empty: "Aucun résultat",
                    cnmGenre: "Genre CNM",
                    msGenres: "Genres MS",
                },
                matchings: {
                    title: "Matchings",
                    search: "Rechercher...",
                    empty: "Aucun résultat",
                    filter: {
                        msMainGenre: "Genre majeur MS",
                        cnmGenre: "Genre CNM",
                        notMatched: "Non matché",
                    },
                    msGenre: "Genre MS",
                    cnmGenre: "Genre CNM",
                    cnmGenrePlaceholder: "Sélectionner un genre CNM",
                    matchSelection: {
                        button: "Matcher la sélection",
                        title: "Matcher les genres MS sélectionnés",
                        genrePlaceholder: "Sélectionner un genre CNM",
                        modifiedMatchings:
                            "Attention, les matchings suivants vont être écrasés :",
                        submit: "Matcher",
                    },
                    copyMatchings: {
                        button: "Copier les matchings",
                        title: "Copier les matchings d'une autre année",
                        genrePlaceholder: "Sélectionner une année",
                        deletedGenres: (yearSrc) =>
                            `Genres de ${yearSrc} supprimés :`,
                        newGenres: (yearDst) =>
                            `Genres ajoutés en ${yearDst} :`,
                        modifiedMatchings: {
                            title: ({ yearSrc, yearDst }) =>
                                `Attention, les genres MS ci-dessous ont des matchings différents dans les référentiels ${yearSrc} et ${yearDst}. Les matchings de ${yearDst} vont être conservés :`,
                            item: ({
                                yearSrc,
                                yearDst,
                                msName,
                                dstCNMName,
                                srcCNMName,
                            }) =>
                                `${msName} (MS) – ${srcCNMName} en ${yearSrc} et ${dstCNMName} en ${yearDst} (CNM)`,
                        },
                        submit: "Copier",
                        success: "Les matchings ont été copiés",
                    },
                },
                matchingSuccess: "Matching(s) modifié(s)",
            },
            cnm_labels: {
                title: "Labels CNM",
                upload_help:
                    "Le fichier CSV doit avoir deux colonnes contenant les noms et types des labels, et ne doit pas avoir d'entête.",
                empty: "Aucun résultat",
                search: "Rechercher...",
                name: "Nom",
                type: "Type",
                add: {
                    button: "Ajouter",
                    tooltip: "Ajouter un label",
                    success: "Le label a été ajouté",
                },
                edit: {
                    tooltip: "Modifier le label",
                    success: "Le label a été modifié",
                },
                delete: {
                    tooltip: "Supprimer le label",
                    title: "Supprimer le label",
                    text: "Êtes-vous sûr de vouloir supprimer le label ?",
                    matchings:
                        "Le label est utilisé dans les titres suivants du top consolidé :",
                    titleLink: ({ artist, title }) => `${title} par ${artist}`,
                    submit: "Supprimer",
                    success: "Le label a été supprimé",
                },
                createOrUpdate: {
                    create: {
                        title: "Ajouter un label",
                        submit: "Ajouter",
                    },
                    update: {
                        title: "Modifier un label",
                        submit: "Modifier",
                    },
                    cnmName: "Nom CNM original",
                    name: "Nom",
                    type: {
                        label: "Type",
                        placeholder: "Sélectionnez un type",
                        major: "Major",
                        indie: "Indés",
                    },
                },
                conflict:
                    "Les labels CNM ci-dessous sont utilisés dans le top consolidé mais ne sont pas présents dans le fichier uploadé :",
            },
            cnm_distributors: {
                title: "Distributeurs CNM",
                upload_help:
                    "Le fichier CSV doit avoir deux colonnes contenant les noms et types des Distributeurs, et ne doit pas avoir d'entête.",
                empty: "Aucun résultat",
                search: "Rechercher...",
                name: "Nom",
                type: "Type",
                add: {
                    button: "Ajouter",
                    tooltip: "Ajouter un distributeur",
                    success: "Le distributeur a été ajouté",
                },
                edit: {
                    tooltip: "Modifier le distributeur",
                    success: "Le distributeur a été modifié",
                },
                delete: {
                    tooltip: "Supprimer le distributeur",
                    title: "Supprimer le distributeur",
                    text: "Êtes-vous sûr de vouloir supprimer le distributeur ?",
                    matchings:
                        "Le distributeur est utilisé dans les titres suivants du top consolidé :",
                    titleLink: ({ artist, title }) => `${title} par ${artist}`,
                    submit: "Supprimer",
                    success: "Le distributeur a été supprimé",
                },
                createOrUpdate: {
                    create: {
                        title: "Ajouter un distributeur",
                        submit: "Ajouter",
                    },
                    update: {
                        title: "Modifier un distributeur",
                        submit: "Modifier",
                    },
                    cnmName: "Nom CNM original",
                    name: "Nom",
                    type: {
                        label: "Type",
                        placeholder: "Sélectionnez un type",
                        distributor: "Distributeur",
                        topDistributor: "Top Distributeur",
                    },
                },
                conflict:
                    "Les distributeurs CNM ci-dessous sont utilisés dans le top consolidé mais ne sont pas présents dans le fichier uploadé :",
            },
            cypok_languages: {
                title: "Langues Cypok",
                upload_help:
                    "Le fichier doit être au format Newline-Delimited JSON",
                empty: "Aucun résultat",
                artist: "Artiste",
                title_column: "Titre",
                languages: "Langues",
                search: "Rechercher...",
            },
            platforms: {
                title: "Plateformes",
                empty: "Aucunes plateformes",
                name: "Nom",
                add: {
                    button: "Ajouter",
                    tooltip: "Ajouter une plateforme",
                    success: "La plateforme a été ajoutée",
                },
                edit: {
                    tooltip: "Modifier la plateforme",
                    success: "La plateforme a été modifiée",
                },
                enable: {
                    tooltip: "Activer la plateforme",
                    success: "La plateforme a été activée",
                },
                disable: {
                    tooltip: "Désactiver la plateforme",
                    success: "La plateforme a été désactivée",
                },
                createOrUpdate: {
                    create: {
                        title: "Ajouter une plateforme",
                        submit: "Ajouter",
                    },
                    update: {
                        title: "Modifier une plateforme",
                        submit: "Modifier",
                    },
                    name: "Nom",
                },
            },
        },
        aggregation: {
            yearSelect: {
                type: {
                    TEST: "Test",
                    REAL: "Réel",
                },
            },
            cnmTitleList: {
                head: "Titres CNM",
                position: "Pos.",
                artist: "Artiste",
                title: "Titre",
                platforms: "Plateformes",
                isrcs: "ISRC",
                duplicate: "Dup.",
                mixed: "Mix.",
                streams: "Streams",
                check: "Marquer le titre comme vérifié",
                uncheck: "Marquer le titre comme non vérifié",
                empty: "Aucun Résultats",
                search: "Recherche...",
                filters: {
                    missingPlatforms: "Plateforme manquante",
                    numberOfMatchedPlatforms: "Nombre de plateformes",
                    status: {
                        placeholder: "Statut",
                        none: "Aucun",
                        checked: "Vérifié",
                        discarded: "Écarté",
                    },
                },
                discardButton: {
                    discard: "Écarter le titre",
                    recover: "Rétablir le titre",
                    commentTitle: "Raison : ",
                    head: "Écarter le titre",
                    comment: "Raison",
                    submit: "Écarter",
                },
            },
            search: {
                head: "Titres CNM Similaires",
                sameIsrc: "Les titres ont un ISRC en commun",
                artist: "Artiste",
                title: "Titre",
                platforms: "Plateformes",
                streams: "Streams",
                empty: "Aucun Résultats",
                form: {
                    search: "Recherche...",
                },
            },
            platformTitleList: {
                head: "Titres des plateformes",
                position: "Pos.",
                artist: "Artiste",
                title: "Titre",
                subtitle: "Sous-titre",
                isrcs: "ISRC",
                duration: "Dur.",
                streams: "Streams",
                empty: "Aucun Résultats",
                move: "Déplacer le titre",
            },
            createButton: {
                head: "Créer un titre CNM à partir de la sélection",
                artist: "Artiste",
                title: "Titre",
                success: "Titre créé",
            },
        },
        charts: {
            platform: {
                nav: "Tops - Plateforme",
                year: {
                    head: "Année",
                    empty: "Aucun Résultats",
                    name: "Nom",
                    type: {
                        TEST: "test",
                        REAL: "réel",
                    },
                },
                pf: {
                    head: "Plateforme",
                    empty: "Aucun Résultats",
                    name: "Nom",
                    show_disabled: "Afficher les plateformes désactivées",
                },
                upload: {
                    title: "Ajouter un top",
                    file: "Fichier",
                    file_placeholder: "Sélectionner un fichier CSV",
                    browse: "Parcourir",
                    year: "Année",
                    type: {
                        label: "Type",
                        TEST: "Test",
                        REAL: "Réel",
                    },
                    submit: "Ajouter",
                    submitting: "Ajout en cours",
                    success: "Le top a été ajouté",
                    confirm: {
                        head: "Êtes-vous sûr de vouloir ajouter ce top ? Les champs suivants vont être écrasés :",
                        difference: ({ track, modifiedFields }) =>
                            `${track.artist_display} – ${track.title} (ID ${
                                track.track_id
                            }, position ${
                                track.position
                            }) : ${modifiedFields.join(", ")}`,
                    },
                },
                matching: {
                    head: "Matching",
                    empty: "Aucun Résultats",
                    search: "Recherche...",
                    position: "N°",
                    artist: "Artiste",
                    title: "Titre",
                    isrc: "Isrc",
                    cnm_artist: "CNM Artiste",
                    cnm_title: "CNM Titre",
                    streams: "Streams",
                    filters: {
                        charts: {
                            placeholder: "Top",
                            initial: "Initial",
                            complement: "Complément",
                        },
                    },
                },
            },
            deduplicated: {
                nav: "Tops - Dédupliqué",
                year: {
                    head: "Année",
                    empty: "Aucun Résultats",
                    name: "Nom",
                    type: {
                        TEST: "test",
                        REAL: "réel",
                    },
                },
                pf: {
                    head: "Plateforme",
                    empty: "Aucun Résultats",
                    name: "Nom",
                    show_disabled: "Afficher les plateformes désactivées",
                },
                chart: {
                    head: "Top",
                    empty: "Aucun Résultats",
                    search: "Recherche...",
                    position: "N°",
                    artist: "Artiste",
                    title: "Titre",
                    isrc: "Isrc",
                    streams: "Streams",
                },
            },
            aggregate: {
                nav: "Tops - Agrégé",
                year: {
                    head: "Année",
                    empty: "Aucun Résultats",
                    name: "Nom",
                    type: {
                        TEST: "test",
                        REAL: "réel",
                    },
                },
                chart: {
                    head: "Top",
                    empty: "Aucun Résultats",
                    search: "Recherche...",
                    rank: "Rang",
                    artist: "Artiste",
                    title: "Titre",
                    isrcs: "Isrc(s)",
                    platforms: "Plateformes",
                    streams: "Streams",
                    export: {
                        head: "Export",
                        requestForComplement: "Demande de complément",
                        requestForConsolidationData:
                            "Demande de données de consolidation",
                        cypok: "Cypok",
                    },
                },
            },
        },
        report: {
            types: {
                title: "Types",
                empty: "Aucun types",
            },
            years: {
                title: "Années",
                empty: "Aucune années",
                type: {
                    TEST: "test",
                    REAL: "réel",
                },
            },
            progress: {
                title: "Suivi",
                aggregate: "Top Agrégé",
                upload: "Réception Top",
                matching: "Matching Top",
                building: "Construction Top 1000",
                coverage: "Couverture Top Agrégé",
                consolidation: "Consolidation Top 1000",
                logs: {
                    title: "Logs",
                    uploadChart: "Top importé",
                    uploadCypok: "Données Cypok importées",
                    requestForComplement: "Demande de complément exportée",
                    requestForCypok: "Demande Cypok exportée",
                },
                lock: {
                    title: "Verrouiller les indicateurs",
                    disabled:
                        "Les indicateurs ne peuvent pas être verrouillés depuis le lot test",
                    success: "Les indicateurs ont été verrouillés",
                },
                unlock: {
                    title: "Déverrouiller les indicateurs",
                    disabled:
                        "Les indicateurs ne peuvent pas être déverrouillés depuis le lot test",
                    success: "Les indicateurs ont été déverrouillés",
                },
            },
            consolidate: {
                yearSelect: {
                    type: {
                        TEST: "Test",
                        REAL: "Réel",
                    },
                },
                chart: {
                    head: "Top",
                    search: "Rechercher...",
                    empty: "Aucun résultats",
                    rank: "N°",
                    artist: "Artiste",
                    title: "Titre",
                    platformSelect: {
                        aggregate: "Agrégé",
                        global: "Global",
                    },
                    filter: {
                        firstLetter: "1e L.",
                        status: {
                            placeholder: "Statut",
                            none: "Aucun",
                            validated: "Validé",
                            discarded: "Écarté",
                            reported: "Signalé",
                        },
                    },
                },
                edit: {
                    head: "Titre",
                    header: {
                        platforms: "Plateformes",
                        openInAggregation:
                            "Ouvrir ce titre dans l'interface d'agrégation",
                        artist: "Artiste",
                        artist_warning:
                            "Ces informations vont être modifiées pour tous les titres de l'artiste.",
                        title: "Titre",
                        vocals: "Chant",
                        problems: "Problèmes",
                    },
                    title: {
                        label: "Titre",
                    },
                    msArtists: {
                        label: "Artistes MS",
                    },
                    isrcs: {
                        label: "ISRCs",
                        placeholder: "Sélectionner des ISRCs",
                        reference: "ISRCs (MS / Plateformes)",
                        select: "Sélectionner l'ISRC",
                        unselect: "Désélectionner l'ISRC",
                        selectAll: "Sélectionner tous les ISRCs",
                    },
                    genre: {
                        label: "Genre",
                        placeholder: "Sélectionner un genre",
                        reference: "Genres (MS / Plateformes)",
                        select: "Sélectionner le genre",
                        unselect: "Déselectionner le genre",
                    },
                    leadGender: {
                        label: "Genre du lead (entité artistique)",
                        placeholder: "Sélectionner un genre",
                        reference: "Genre du lead (MS)",
                        select: "Sélectionner le genre",
                        unselect: "Déselectionner le genre",
                        F: "Femme",
                        M: "Homme",
                        MIXED: "Mixte",
                    },
                    countries: {
                        label: "Nationalité",
                        placeholder: "Sélectionnez le/les pays des artistes",
                    },
                    voice: {
                        label: "Genre du lead (tonalité de la voix)",
                        placeholder: "Sélectionner une tonalité",
                        F: "Femme",
                        M: "Homme",
                        MIXED: "Mixte",
                        INSTRUMENTAL: "Instrumental",
                    },
                    publicationDate: {
                        label: "Date de publication",
                        reference: "Dates de publication (MS / Plateformes)",
                        select: "Sélectionner la date",
                        unselect: "Déselectionner la date",
                    },
                    ageType: {
                        label: "Typologie d'ancienneté",
                        new: "Nouveauté",
                        recurring: "Récurrent",
                        gold: "Gold",
                        warning: ({ from, to, date }) =>
                            `La typologie est passée de ${from} à ${to} le ${date}`,
                    },
                    label: {
                        label: "Label",
                        placeholder: "Sélectionner un label",
                        create: "Créer un label",
                        reference: "Labels (MS / Plateformes)",
                        copy: "Copier le label",
                    },
                    distributor: {
                        label: "Distributeur",
                        placeholder: "Sélectionner un distributeur",
                        create: "Créer un distributeur",
                        reference: "Distributeurs (Plateformes)",
                        copy: "Copier le distributeur",
                    },
                    cnmArtist: {
                        label: "Artiste CNM",
                        placeholder: "Sélectionner un artiste CNM",
                        name: "Nom",
                        create: {
                            title: "Créer un artiste",
                            success: "L'artiste a été créé",
                        },
                        update: {
                            title: "Modifier l'artiste",
                            success: "L'artiste a été modifié",
                        },
                    },
                    csaArtist: {
                        label: "Artiste CSA",
                        placeholder: "Sélectionner un artiste CSA",
                    },
                    levelOfDevelopment: {
                        label: "Niveau de dévelopement",
                        confirmed: "Confirmé",
                        new: "Nouveau talent",
                    },
                    language: {
                        label: "Langue chantée",
                        placeholder: "Sélectionner une langue",
                        lyricfindReference: "Langue chantée (Lyricfind)",
                        select: "Sélectionner la langue",
                        unselect: "Déselectionner la langue",
                        FRENCH: "Français",
                        INTERNATIONAL: "International",
                        INSTRUMENTAL: "Instrumental",
                    },
                    platformTitles: {
                        head: "Titres des plateformes",
                        platform: "Plateforme",
                        artist: "Artiste",
                        title: "Titre",
                        isrcs: "ISRC",
                        duration: "Dur.",
                        streams: "Streams",
                    },
                    validate: "Valider le titre",
                    discard: {
                        head: "Écarter le titre",
                        shouldBeFalse: "Impossible de valider un titre écarté",
                    },
                    report: {
                        head: "Signaler le titre",
                        shouldBeFalse: "Impossible de valider un titre signalé",
                    },
                    discardReason: {
                        head: "Raison",
                        placeholder: "Sélectionner une raison",
                        required:
                            "Le titre ne peut pas être écarté sans raison",
                    },
                    reportReason: "Raison",
                    success: "Le titre a été modifié",
                    error: "Une erreur a eu lieu lors de l'enregistrement des informations",
                },
            },
            indicators: {
                title: "Indicateurs",
                top_titles: "Top Titres",
                top_artists: "Top Artistes",
                indicators: "Indicateurs",
            },
        },
    },
    charts: {
        breadcrumb: "Charts",
        display: {
            breadcrumb: "Consultation",
        },
        translation: {
            breadcrumb: "Traduction",
        },
    },
    partner_tops: {
        partners: {
            title: "Partenaires",
            empty: "Aucun partenaires",
        },
        dates: {
            title: "Dates",
            empty: "Aucune dates",
        },
        tops: {
            title: "Tops",
            empty: "Aucun tops",
        },
        artists: {
            title: "Artistes",
            empty: "Aucun artistes",
            rank: "Position",
            name: "Nom",
            matchings: "Matching",
            editMatchings: "Modifier les matchings",
            status: "Statut",
            biographies: "Biographies",
            biographies_fr: "Français",
            biographies_en: "Anglais",
            pictures: "Photos",
            discarded: ({ date, user, comment }) =>
                `Écarté le ${date} par ${user} - "${comment}"`,
            filters: {
                max_rank: {
                    title: "Positions",
                    top: (n) => `Top ${n}`,
                },
                matched: {
                    title: "Match",
                    yes: "Avec match",
                    no: "Sans match",
                },
                discarded: {
                    title: "Écartés",
                    yes: "Écartés",
                    no: "Non écartés",
                },
                bio_en: {
                    title: "Biographie EN",
                    yes: "Avec biographie EN",
                    no: "Sans biographie EN",
                },
                bio_fr: {
                    title: "Biographie FR",
                    yes: "Avec biographie FR",
                    no: "Sans biographie FR",
                },
                curation: {
                    title: "Curation",
                    yes: "Avec photos de curation",
                    no: "Sans photos de curation",
                },
                pictures: {
                    title: "Photos",
                    valid: "Photos valides",
                    improve: "Photos à améliorer",
                    missing: "Photos manquantes",
                },
            },
            matching: {
                create: {
                    success: "Matching créé",
                    duplicate: "Le matching existe déjà",
                    error: (message) =>
                        `Erreur lors de la création du matching : ${message}`,
                },
                update: {
                    success: "Matching modifié",
                    not_found:
                        "Erreur lors de la modification du matching : le matching n'existe plus",
                    duplicate:
                        "Erreur lors de la modification du matching : le matching existe déjà",
                    error: (message) =>
                        `Erreur lors de la modification du matching : ${message}`,
                },
                delete: {
                    success: "Matching supprimé",
                    not_found:
                        "Erreur lors de la suppression du matching : le matching n'existe plus",
                    error: (message) =>
                        `Erreur lors de la suppression du matching : ${message}`,
                },
            },
            discard: {
                button: {
                    title: "Écarter",
                },
                modal: {
                    title: "Êtes-vous sûr ?",
                    text: "De vouloir écarter cet artiste ?",
                    placeholder: "Commentaire",
                },
                success: "Artiste écarté",
                duplicate:
                    "Erreur lors de l'écartement de l'artiste : l'artiste est déjà écarté",
                error: (message) =>
                    `Erreur lors de l'écartement de l'artiste : ${message}`,
            },
            recover: {
                button: {
                    title: "Rétablir",
                },
                modal: {
                    title: "Êtes-vous sûr ?",
                    text: "De vouloir rétablir cet artiste ?",
                },
                success: "Artiste rétabli",
                not_found:
                    "Erreur lors du rétablissement de l'artiste : l'artiste n'est pas écarté",
                error: (message) =>
                    `Erreur lors du rétablissement de l'artiste : ${message}`,
            },
        },
        artistsV3: {
            title: "Artistes",
            empty: "Aucun artistes",
            rank: "Position",
            artist: "Artiste",
            ms_artist: "Artiste MS",
            discography_score: "Albums matchés",
            biography_en: "Bio EN",
            biography_fr: "Bio FR",
            curation: "Curation",
            pictures: "Photos",
            discarded_on: (date) => `Écarté le ${date}`,
            filters: {
                max_rank: {
                    title: "Positions",
                    top: (n) => `Top ${n}`,
                },
                matched: {
                    title: "Match",
                    yes: "Avec match",
                    no: "Sans match",
                },
                discarded: {
                    title: "Écartés",
                    yes: "Écartés",
                    no: "Non écartés",
                },
                bio_en: {
                    title: "Biographie EN",
                    yes: "Avec biographie EN",
                    no: "Sans biographie EN",
                },
                bio_fr: {
                    title: "Biographie FR",
                    yes: "Avec biographie FR",
                    no: "Sans biographie FR",
                },
                curation: {
                    title: "Curation",
                    yes: "Avec curation",
                    no: "Sans curation",
                },
                pictures: {
                    title: "Photos",
                    yes: "Avec photos",
                    no: "Sans photos",
                },
            },
            deezer: {
                market: "Market",
                musicbrainz: "MusicBrainz",
                facebook: "Facebook",
                filters: {
                    market: {
                        title: "Market",
                    },
                },
                pictures: {
                    head: "Deezer",
                    backstage: "Backstage",
                    other: "Autre",
                    none: "Aucune",
                },
            },
            napster: {
                country: "Pays",
                filters: {
                    country: {
                        title: "Pays",
                    },
                },
            },
            matching: {
                create: {
                    success: "Matching créé",
                    duplicate: "Le matching existe déjà",
                    error: (message) =>
                        `Erreur lors de la création du matching : ${message}`,
                },
                update: {
                    success: "Matching modifié",
                    not_found:
                        "Erreur lors de la modification du matching : le matching n'existe plus",
                    duplicate:
                        "Erreur lors de la modification du matching : le matching existe déjà",
                    error: (message) =>
                        `Erreur lors de la modification du matching : ${message}`,
                },
                delete: {
                    success: "Matching supprimé",
                    not_found:
                        "Erreur lors de la suppression du matching : le matching n'existe plus",
                    error: (message) =>
                        `Erreur lors de la suppression du matching : ${message}`,
                },
            },
            discard: {
                button: {
                    title: "Écarter",
                },
                modal: {
                    title: "Êtes-vous sûr ?",
                    text: "De vouloir écarter cet artiste ?",
                    placeholder: "Commentaire",
                },
                success: "Artiste écarté",
                duplicate:
                    "Erreur lors de l'écartement de l'artiste : l'artiste est déjà écarté",
                error: (message) =>
                    `Erreur lors de l'écartement de l'artiste : ${message}`,
            },
            recover: {
                button: {
                    title: "Rétablir",
                },
                modal: {
                    title: "Êtes-vous sûr ?",
                    text: "De vouloir rétablir cet artiste ?",
                },
                success: "Artiste rétabli",
                not_found:
                    "Erreur lors du rétablissement de l'artiste : l'artiste n'est pas écarté",
                error: (message) =>
                    `Erreur lors du rétablissement de l'artiste : ${message}`,
            },
        },
        pictures: {
            none: "Aucune",
            getty: "Getty",
            curation: "Curation",
            label: "Label",
            backstage: "Deezer - Backstage",
            other: "Deezer - Autre",
            last_label_creation: "Date d'ajout de la dernière photo Label",
            last_getty_creation: "Date d'ajout de la dernière photo Getty",
            last_getty_date: "Date de la photo Getty la plus récente",
            last_check_date: "Date de dernière vérification",
        },
    },
    pictures: {
        breadcrumb: "Photos",
        label: {
            gallery: {
                breadcrumb: "Disponibles Clients",
                empty: "Aucun Résultats",
                head: (label) => `Photos label: ${label}`,
                search: "Rechercher un artiste",
                artist: "Artiste",
                action: "Action",
                expired: "Photo expirée",
                takedownLabel: "Date d'expiration",
                add: "Ajouter une image",
            },
            status: {
                breadcrumb: "En attente / Historique",
                empty: "Aucun Résultats",
                head: (label) => `Photos label: ${label}`,
                search: "Rechercher un artiste",
                picture: "Photo",
                artist: "Artiste",
                status: "Statut",
                filterStatusLabel: "Afficher historique:",
                historic: "Historique",
                statusChange: "Changement de statut",
                createdAt: "Date de soumission",
                formatedStatus: (status) => status.replaceAll("_", " "),
                definition: {
                    AWAITING:
                        "La photo a été prise en compte et sera traité prochainement",
                    AWAITING_FOR_PROCESSING:
                        "La photo a été acceptée et est en attente pour documentation",
                    DOCUMENTED:
                        "La photo a passé le processus de validation et est désormais disponible",
                    REJECTED:
                        "La photo ne correspond pas aux critères Music Story et ne peut donc pas être validée",
                },
            },
            validation: {
                breadcrumb: "Images à traiter",
                empty: "Aucun Résultats",
                validationHead: "Validation",
                processingHead: "A documenter",
                search: "Rechercher un artiste ou un label",
                picture: "Photo",
                artist: "Artiste",
                label: "Label",
                title: "Titre",
                copyright: "Copyright",
                date_takedown: "Date d'expiration",
                action: "Action",
                validate: {
                    title: "Valider",
                    alert: {
                        title: "Êtes-vous sûr ?",
                        text: "De vouloir valider cette photo ?",
                    },
                    success: "Photo validé",
                    error: (message) =>
                        `Erreur lors de la validation de la photo: ${message}`,
                },
                reject: {
                    title: "Rejeter",
                    alert: {
                        title: "Êtes-vous sûr ?",
                        text: "De vouloir rejeter cette photo ?",
                        commentTitle: "Ajouter un commentaire",
                    },
                    success: "Photo rejeté",
                    error: (message) =>
                        `Erreur lors du rejet de la photo : ${message}`,
                },
            },
            rejected: {
                breadcrumb: "Images rejetées",
                empty: "Aucun Résultats",
                head: "Images rejetées",
                search: "Rechercher un artiste ou un label",
                picture: "Photo",
                artist: "Artiste",
                label: "Label",
                title: "Titre",
                copyright: "Copyright",
                date_takedown: "Date d'expiration",
                comment: "Commentaire",
                action: "Action",
                retrieve: {
                    title: "Récupérer",
                    alert: {
                        title: "Êtes-vous sûr ?",
                        text: "De vouloir récupérer cette photo ?",
                    },
                    success: "Photo récupérée",
                    error: (message) =>
                        `Erreur lors de la récupération de la photo: ${message}`,
                },
                edit: {
                    title: "Modifier le commentaire",
                    success: "Commentaire modifié",
                    error: (message) =>
                        `Erreur lors de la modification du commentaire: ${message}`,
                },
            },
        },
    },
    labelPromo: {
        breadcrumb: "Labels",
        manage: {
            breadcrumb: "Management",
            label: {
                head: "Label",
                empty: "Aucun Résultats",
                id: "Id",
                name: "Nom",
                created_at: "Date d'ajout",
                updated_at: "Date de modification",
                status: "Statut",
                action: "Action",
                search: "Rechercher...",
            },
            show: {
                title: "Visualisation des utilisateurs",
                empty: "Aucun utilisateur associé",
            },
            create: {
                title: "Ajouter un label",
                success: "Label ajouté",
                error: (message) =>
                    `Erreur lors de l'ajout du label: ${message}`,
            },
            update: {
                title: "Modifier un label",
                success: "Label modifié",
                error: (message) =>
                    `Erreur lors de la modification du label: ${message}`,
            },
            delete: {
                title: "Supprimer un label",
                alert: {
                    title: "Êtes-vous sûr ?",
                    text: "De vouloir supprimer ce label ?",
                },
                success: "Label supprimé",
                error: (message) =>
                    `Erreur lors de la suppression du label: ${message}`,
            },
        },
    },
    artistTitle: {
        breadcrumb: "Artist-Title",
        searchForm: {
            artist: "Artiste",
            title: "Titre",
            search: "Rechercher",
        },
        resultsList: {
            count: (count) => `${count} Résultats`,
            isrc: (isrc) => (isrc ? `ISRC : ${isrc}` : "pas d'ISRC"),
            recordingId: (id) => `id Recording : ${id}`,
            artist: {
                head: "Artiste",
                picture: "Photo artiste",
                id: (id) => `id Artiste : ${id}`,
            },
            album: {
                head: "Album",
                picture: "Pochette",
                id: (id) => `id Album : ${id}`,
            },
        },
    },
    recommendation: {
        form: {
            positive: "Le champ doit être positif",
            min: "Le champ ne doit pas être inférieur à 0",
            max: "Le champ ne doit pas être supérieur à 100",
            select_options: {
                low: "Bas (0-0.3333)",
                medium: "Moyen (0.3333-0.6666)",
                high: "Haut (0.6666-1)",
            },
            select_options_bpm: {
                low: "Bas (0-76)",
                medium: "Moyen (76-160)",
                high: "Haut (160+)",
            },
        },
        breadcrumb: "Recommandation étendue",
        results: "Résultats",
        empty: "Aucun Résultats",
        search: "Recherche...",
        ms_track_ids: "MS Track Ids",
        ms_track_id: "MS Track Id",
        ms_artist_ids: "MS Artist Ids",
        min_accuracy: "Précision minimum",
        fa_bpm: "Bpm",
        fa_arousal: "Arousal",
        fa_moods: "Moods",
        fa_valence: "Valence",
        fa_intensity: "Intensity",
        year_range: "Écart d'année",
        fa_descriptor: "Descripteurs",
        name: "Nom",
        title: "Titre",
        subtitle: "Sous-titre",
        genre: "Genre",
        original_album: "Album",
        original_release_date: "Date de sortie",
        artists: "Artistes",
        mbid: "Mbid",
        match: "Match",
        popularity: "Popularité",
    },
    data_external: {
        breadcrumb: "Travaux externes Data",
        internationals_charts: {
            breadcrumb: "Charts internationaux",
            charts: {
                nav: "Charts",
                empty: "Aucun Résultats",
                id: "Id",
                name: "Nom",
                positions: "Nombre de positions",
                treatments: "Position de traitements",
                search: "Recherche...",
            },
            stats: {
                nav: "Stat de couverture",
                empty: "Aucun Résultats",
                search: "Recherche...",
                title: "Titre",
                country: "Pays",
                start_date: "Date",
                treatment: "Matché",
                matched_simple: "Simple",
                creation: "Création",
                nb_discarded: "Ecarté",
                nb_forgot: "Oublié",
            },
            coverage: {
                nav: "Infos de traitements",
                empty: "Aucun Résultats",
                search: "Recherche...",
                simple: "Matchings simples",
                creation: "Synthèse des créations",
                discarded: "Ecartés - Checké mais non traité",
                forgot: "Ecartés - Oubliés",
                title: "Titre",
                country: "Pays",
                area: "Continent",
                date: "Date",
                rank: "Rang",
                artist: "Artiste",
                album_track: "Album/Titre",
                ms_artist: "Artiste MS",
                comment: "Commentaire",
                user: "Utilisateur",
            },
        },
        deezer_top_songs: {
            breadcrumb: "Deezer - top songs",
            stats: {
                nav: "Stat de couverture",
                empty: "Aucun Résultats",
                date: "Date",
                country: "Pays",
                treatment: "Couverture",
                nb_match_simple_ms: "Simple MS",
                nb_match_creation_ms: "Création Ms",
                nb_discard_ms: "Oublié MS",
                nb_match_lf: "Matché LF",
                nb_request_lf: "Request LF",
                nb_forgot_lf: "Oublié LF",
                nb_match_lf_cas1: "Matché Cas 1",
                nb_match_lf_cas23_with_request: "Avec Request Cas 2/3",
                nb_match_lf_cas23_without_request: "Sans Request Cas 2/3",
                nb_forgot_ms_lf: "Oublié MS + LF",
            },
            coverage: {
                nav: "Infos de traitements",
                empty: "Aucun Résultats",
                search: "Recherche...",
                simple: "Matchings simples",
                creation: "Synthèse des créations",
                new_lyrics: "Matching lyricfind",
                discarded_full: "Ecarté",
                forgot_full: "Oublié complet",
                forgot_lf: "Oublié LF",
                forgot_2_3: "Oublié 2/3",
                date: "Date",
                country: "Pays",
                id_deezer: "ID Deezer",
                artist: "Artiste",
                album: "Album",
                song: "Song",
                ms_artist: "Artiste MS",
                ms_album: "Album MS",
                lf_composition: "Composition",
                comment: "Commentaire",
                user: "Utilisateur",
            },
        },
        universal_artist: {
            breadcrumb: "Universal",
            stats: {
                nav: "Stat de couverture",
                empty: "Aucun Résultats",
                date: "Date",
                total: "Global",
                matched_total: "Hebdo",
                matched_simple: "Simple",
                matched_creation: "Création",
                discarded: "Ecarté",
            },
            coverage: {
                nav: "Infos de traitements",
                empty: "Aucun Résultats",
                search: "Recherche...",
                simple: "Simple",
                creation: "Création",
                discarded: "Ecartés",
                id: "Id",
                name: "Nom",
                ms_artist: "Artiste MS",
                comment: "Commentaire",
                user: "Utilisateur",
            },
        },
    },
    api: {
        breadcrumb: "API",
        monitoring: {
            breadcrumb: "Monitoring",
            token: "Jeton API",
            status: {
                head: "Statut",
                remaining: "Restant",
                limit: "Limite",
                retry: "Réessayer après",
                retry_description:
                    "La date de réinitialisation ne s'affichera que lorsque vous aurez atteint 0 requêtes restantes. Elle indique la date à laquelle vous pourrez effectuer de nouvelles requêtes.",
                status: "Statut",
            },
            historic: {
                head: "Historique",
                date: "Date",
                requests: "Requêtes",
            },
        },
    },
    moodmedia: {
        breadcrumb: "Moodmedia",
        search: {
            breadcrumb: "Recherche",
            results: "Resultats",
            empty: "Aucun Résultats",
            title: "Titre",
            artist: "Artiste",
            id: "Id",
            original_release_date: "Date de sortie",
            ms_album_title: "Titre album",
            name: "Nom",
            subtitle: "Sous-titre",
            artists: "Artistes",
            mbid: "Mbid",
            match: "Match",
            popularity: "Popularité",
            link: "WS2",
        },
        recommendation: {
            form: {
                error: "Les champs MS et MM ne peuvent être saisie en même temps",
                positive: "Le champ doit être positif",
                min: "Le champ ne doit pas être inférieur à 0",
                max: "Le champ ne doit pas être supérieur à 100",
            },
            breadcrumb: "Recommandation",
            results: "Resultats",
            empty: "Aucun Résultats",
            search: "Recherche...",
            ms_track_ids: "MS Track Ids",
            ms_track_id: "MS Track Id",
            ms_artist_ids: "MS Artist Ids",
            mm_track_ids: "MM Track Ids",
            mm_track_id: "MM Track Id",
            filter_no: "Filtre NON",
            catalog: "Catalogue",
            min_accuracy: "Précision minimum",
            name: "Nom",
            title: "Titre",
            subtitle: "Sous-titre",
            genre: "Genre",
            original_album: "Album",
            original_release_date: "Date de sortie",
            artists: "Artistes",
            mbid: "Mbid",
            match: "Match",
            popularity: "Popularité",
        },
    },
    snep: {
        file: {
            breadcrumb: "Gestion des fichiers SNEP",
            fileList: {
                head: "Fichiers",
                search: "ID / Type / Date",
                empty: "Aucun fichiers",
                id: "ID",
                name: "Type Chart",
                period: "Période",
                date: "Date",
                imported: "Importé",
                exportedSnep: "Exporté SNEP",
                exportedYadeck: "Exporté Yadeck",
                upload: {
                    button: "Ajouter",
                    head: "Ajouter un fichier SNEP",
                    file: "Cliquez pour choisir un fichier sur votre ordinateur",
                    success: "Le fichier a été ajouté",
                },
                import: {
                    head: "Importer un fichier SNEP",
                    name: "Fichier",
                    startDate: "Début",
                    endDate: "Fin",
                    chart: {
                        label: "Top",
                        placeholder: "Sélectionner un top",
                        album: "Album",
                        track: "Track",
                    },
                    success: "Le fichier a été importé",
                },
                export: {
                    pending: "Export du fichier en cours...",
                    success: "Le fichier a été exporté",
                },
                clean: {
                    title: "Supprimer les exports",
                    success: "Les exports ont été supprimés",
                },
                delete: {
                    title: "Supprimer le fichier",
                    success: "Le fichier a été supprimé",
                },
            },
        },
    },
    matching: {
        tops: {
            breadcrumb: "Matching",
        },
        artist: {
            partner: {
                head: "Partenaires",
                name: "Nom",
            },
            list: {
                head: "Listes",
                empty: "Aucune listes",
                date: "#",
                name: "Nom",
                matched: "Matchés",
                completed: "Complétés",
            },
            artist: {
                head: "Artistes",
                empty: "Aucun artistes",
                id: "#",
                name: "Nom",
                firstname: "Prénom",
                lastname: "Nom",
                complementary: "Complément",
                discography: "Discographie",
                spotify: "Spotify",
                musicbrainz: "MusicBrainz",
                max_created_at: "Date",
                complementary: "Complément",
                type: "Type",
                getty_picture: "Photos getty",
                picture: (nb) => `${nb} photo${nb > 1 ? "s" : ""}`,
                artist: "Artiste Music-Story",
                action: "Action",
                checked: "Vérifié",
                edit_matchings: "Modifier les matchings",
                match: {
                    success: "Matching créé",
                    error: (message) =>
                        `Erreur lors de la création du matching : ${message}`,
                },
                unmatch: {
                    success: "Matching supprimé",
                    error: (message) =>
                        `Erreur lors de la suppression du matching : ${message}`,
                },
                mark: {
                    button: {
                        title: "Marquer comme 'Ignoré'",
                    },
                    modal: {
                        title: "Êtes-vous sûr ?",
                        text: "De vouloir ignorer cet artiste ?",
                        placeholder: "Commentaire",
                    },
                    success: "Artiste ignoré",
                    error: (message) =>
                        `Erreur en essayant d'ignorer l'artiste : ${message}`,
                },
                unmark: {
                    button: {
                        title: "Marquer comme 'Non Ignoré'",
                    },
                    success: "Artiste rétabli",
                    error: (message) =>
                        `Erreur lors du rétablissement de l'artiste : ${message}`,
                },
                check: {
                    button: {
                        title: "Marquer comme 'Vérifié'",
                    },
                    success: "Artiste vérifié",
                    error: (message) =>
                        `Erreur lors de la vérification de l'artiste : ${message}`,
                },
                artistCreated: {
                    button: {
                        title: "Marquer comme 'Artiste créé'",
                    },
                    success: "Artiste marqué comme créé",
                    error: (message) =>
                        `Erreur en essayant de marquer l'artiste : ${message}`,
                },
                unartistCreated: {
                    button: {
                        title: "Marquer comme 'Artist non créé'",
                    },
                    success: "Artiste marqué comme non créé",
                    error: (message) =>
                        `Erreur lors du dé-marquage de l'artiste : ${message}`,
                },
                filter: {
                    matched: {
                        placeholder: "Matché",
                        true: "Matché",
                        false: "Non matché",
                    },
                    completed: {
                        placeholder: "Complété",
                        true: "Complété",
                        false: "Non complété",
                    },
                    checked: {
                        placeholder: "Checké",
                        true: "Checké",
                        false: "Non checké",
                    },
                    skiped: {
                        placeholder: "Ignoré",
                        true: "Ignoré",
                        false: "Non ignoré",
                    },
                },
                legend: {
                    title: "Légende",
                    completed: "Completé",
                    matched: "Matché",
                    skiped: "Ignoré",
                    notAutoMatched: "Non-Matchés auto (doublon)",
                    error: "Erreur",
                },
            },
        },
        album: {
            partner: {
                head: "Partenaires",
                name: "Nom",
            },
            list: {
                head: "Listes",
                empty: "Aucune listes",
                date: "#",
                name: "Nom",
                matched: "Matchés",
            },
            album: {
                head: "Albums",
                empty: "Aucun albums",
                rank: "Rang",
                cover: "Pochette",
                id: "#",
                title: "Titre",
                snep_artist: "Artiste Snep",
                artist: "Artiste Music-Story",
                album: "Album Music-Story",
                spotify_album: "Album Spotify",
                fnac_album: "Album Fnac",
                match: {
                    success: "Matching créé",
                    error: (message) =>
                        `Erreur lors de la création du matching : ${message}`,
                },
                unmatch: {
                    success: "Matching supprimé",
                    error: (message) =>
                        `Erreur lors de la suppression du matching : ${message}`,
                },
                filter: {
                    matched: {
                        placeholder: "Matché",
                        true: "Matché",
                        false: "Non matché",
                    },
                },
                legend: {
                    title: "Légende",
                    matched: "Matché",
                    partially_matched: "Partiellement matché",
                },
            },
        },
        track: {
            partner: {
                head: "Partenaires",
                name: "Nom",
            },
            list: {
                head: "Listes",
                empty: "Aucune listes",
                date: "#",
                name: "Nom",
                matched: "Matchés",
                progress: "Avancement",
            },
            track: {
                head: "Tracks",
                empty: "Aucune tracks",
                rank: "Rang",
                cover: "Pochette",
                id: "#",
                title: "Titre",
                snep_artist: "Artiste Snep",
                artist: "Artiste Music-Story",
                recording: "Recording Music-Story",
                duration: "Durée",
                artist_album: "Artiste - Album",
                case: "Cas",
                lyricfind: "Track Lyricfind",
                actions: "Actions",
                spotify_track: "Track Spotify",
                fnac_album: "Album Fnac",
                linemusic_artist: "Artiste Line Music",
                edit_matchings: "Modifier les matchings",
                match: {
                    success: "Matching créé",
                    error: (message) =>
                        `Erreur lors de la création du matching : ${message}`,
                },
                unmatch: {
                    success: "Matching supprimé",
                    error: (message) =>
                        `Erreur lors de la suppression du matching : ${message}`,
                },
                request: {
                    button: {
                        enabled: "Soumettre la demande de parole à Lyricfind",
                        disabled: "Demande soumise à Lyricfind",
                    },
                    success: "Demande soumise à Lyricfind",
                    error: (message) =>
                        `Erreur lors de la demande : ${message}`,
                },
                discard: {
                    button: {
                        title: "Écarter",
                    },
                    modal: {
                        title: "Êtes-vous sûr ?",
                        text: "De vouloir écarter cet artiste ?",
                        placeholder: "Commentaire",
                    },
                    success: "Titre écarté",
                    error: (message) =>
                        `Erreur lors de l'écartement du titre : ${message}`,
                },
                recover: {
                    button: {
                        title: "Rétablir",
                    },
                    success: "Titre rétabli",
                    error: (message) =>
                        `Erreur lors du rétablissement du titre : ${message}`,
                },
                filter: {
                    matched: {
                        placeholder: "Matché",
                        true: "Matché",
                        false: "Non matché",
                    },
                    discarded: {
                        placeholder: "Écarté",
                        true: "Écarté",
                        false: "Non écarté",
                    },
                },
                legend: {
                    title: "Légende",
                    matched: "Matché",
                    completed: "Complété",
                    partially_matched: "Partiellement matché",
                    discarded: "Écarté",
                },
            },
        },
        event: {
            head: "Concerts",
            empty: "Aucun concerts",
            id: "ID",
            name: "Nom",
            date: "Date",
            time: "Heure",
            venue: "Lieu",
            songkick_events: "Concerts Songkick",
            no_artists: "Aucun artistes",
            filter: {
                completed: {
                    placeholder: "Complété",
                    matched: "Matché",
                    completed_with_discard: "Complété (partiellement écarté)",
                    not_completed: "Non complété",
                },
            },
            legend: {
                title: "Légende",
                matched: "Matché",
                completed_with_discard: "Complété (partiellement écarté)",
            },
        },
    },
    profile: {
        personal_details: {
            title: "Informations personnelles",
            breadcrumb: "Profil",
            head: "Informations principales",
            account_type: {
                title: "Type de compte",
                music_service: "Music service",
                cmo: "CMO",
                label_distributor: "Label / Distributor",
                artist_manager: "Artist / Manager",
            },
            firstname: "Prénom",
            lastname: "Nom",
            email: "Mail",
            company: "Société",
            label: "Label",
            artist: "Artiste",
            role: {
                title: "Rôle",
                music_editor: "Music editor",
                marketing: "Marketing",
                catalog_management: "Catalog Management",
                product_management: "Product Management",
                tech: "Tech",
                executive: "Executive",
                other: "Other",
                promotion: "Promotion",
                business_development: "Business development",
                a_and_r: "A&R",
                supply_chain: "Supply chain",
                analytics: "Analytics",
            },
            describe: "Précision",
            language: "Langue",
            expires_at: "Date d'expiration",
            token: "Jeton API",
            success: "Les informations ont été enregistrées",
        },
        change_password: {
            title: "Modifier le mot de passe",
            old_password: "Ancien mot de passe",
            new_password: "Nouveau mot de passe",
            confirm_password: "Confirmer le mot de passe",
            success: "Le mot de passe a été mis à jour",
        },
    },
    search: {
        breadcrumb: "Rechercher",
        placeholder: "Artiste / Album / Recording",
        all: "Tous",
        artists: "Artistes",
        albums: "Albums",
        recordings: "Recordings",
        empty: "Aucun résultat",
        more: "Voir plus",
        artistsData: {
            head: "Artistes",
            cover: "Image",
            id: "ID",
            name: "Nom",
            popularity: "Popularité",
            peak: "Pic de popularité",
            complementary: "Complément",
            type: "Type",
            genre: "Genre",
            country: "Pays",
            role: "Rôle",
            actions: "Actions",
        },
        albumsData: {
            head: "Albums",
            cover: "Pochette",
            id: "ID",
            title: "Titre",
            artist: "Artiste",
            type: {
                live: "Live",
                remix: "Remix",
                compilation: "Compilation",
                karaoke: "Karaoke",
                ring: "Sonnerie",
                other: "Autre",
                original: "Original",
            },
            format: {
                album: "Album",
                ep: "EP",
                single: "Single",
                mixtape: "Mixtape",
                mcd: "Maxi",
            },
            genre: "Genre",
            release_date: "Date de sortie",
            label: "Label",
            actions: "Actions",
        },
        recordingsData: {
            head: "Recordings",
            cover: "Pochette",
            id: "ID",
            title: "Titre",
            subtitle: "Sous-titre",
            popularity: "Popularité",
            artist: "Artiste",
            duration: "Durée",
            genre: "Genre",
            release_date: "Date de sortie",
            complementary: "Contenu associé",
            has_credits: "Crédits",
            has_lyric: "Parole",
            has_audio: "Audio",
            actions: "Actions",
        },
    },
    base: {
        user: {
            account: "Profil",
            logout: "Déconnexion",
        },
        nav: {
            home: "Accueil",
            search: "Rechercher",
            title: {
                partners: "Partenaires",
                admin: "Administration",
            },
            apple: {
                title: "Apple",
                sample: {
                    title: "Sample",
                    assessment: "Evaluation",
                    management: "Management",
                    report: "Rapport",
                    guideline: "Directives",
                },
            },
            documentation: {
                title: "Documents",
            },
            data: {
                title: "Données",
                artists: {
                    title: "Artistes",
                    search: "Recherche avancée",
                    create: "Création",
                    followup: "Suivi créations/suppressions",
                },
                roles: "Roles",
                country: "Pays",
                genres: "Genres",
                charts: {
                    title: "Charts",
                    display: "Consultation",
                    translation: "Traduction",
                },
                labelPromo: "Labels",
                data_external: {
                    title: "Travaux externes Data",
                    internationals_charts: "Charts internationaux",
                    deezer_top_songs: "Deezer - top songs",
                    universal_artist: "Universal",
                },
                misc_entity: "Spectacles",
                api: {
                    title: "API",
                    getting_started: "Introduction",
                    api_reference: "Référence API",
                    monitoring: "Monitoring",
                    status: "Statut",
                },
            },
            tools: {
                title: "Outils",
                artistTitle: "Artist-Title",
                movePicture: "Déplacement picture",
            },
            plannings: {
                title: "Plannings",
                overview: "Vue d'ensemble",
                manage: "Gestion des plannings",
                picture: "Travaux photo",
                billing: "Facturation",
                statistic: "Statistiques",
            },
            pictures: {
                title: "Photos",
                label: {
                    gallery: "Disponibles Clients",
                    status: "En attente / Historique",
                    validation: "Images à traiter",
                    rejected: "Images rejetées",
                },
                curation: {
                    followup: "Suivi Curation",
                },
                getty: {
                    followup: "Suivi Getty",
                },
            },
            matching: {
                title: "Matching",
                artist: "Artists",
                album: "Albums",
                track: "Tracks",
                tops: "Tops",
                lyricsSample: "Lyrics sample",
                partner_tops: "Tops Artistes",
                event: "Concerts",
            },
            snep: {
                title: "SNEP",
                file: "Gestion des Tops",
                chart: "Gestion des Charts",
            },
            cnm: {
                title: "CNM",
                repositories: "Référentiels",
                aggregation: "Agrégation",
                consolidation: "Consolidation",
                charts: "Tops",
                report: "Rapport",
            },
            moodmedia: {
                title: "Moodmedia",
                search: "Recherche",
                recommendation: "Recommandation",
            },
            admin: {
                users: {
                    title: "Utilisateurs",
                    user: "Gestion des Utilisateurs",
                    author: "Gestion des Auteurs",
                },
                rights: {
                    title: "Droits",
                    role: "Gestion des Rôles",
                    right: "Gestion des Droits",
                    group: "Gestion des Groupes",
                },
            },
        },
    },
    common: {
        explicit: {
            long: "Explicit",
            short: "E",
        },
        multiSearch: {
            placeholder: "Artiste / Album / Recording",
            explanation: () => (
                <>
                    Recherchez des artistes, albums, recordings, pour explorer
                    toutes les métadonnées associées et disponibles par API ou
                    flux de données :
                    <ul className="mb-0">
                        <li>
                            artist : ISNI, biographies, images, popularité,
                            artistes associés...
                        </li>
                        <li>
                            albums : UPC, album canonique, genre, date de sortie
                            originale...
                        </li>
                        <li>
                            recordings : ISRCs, crédits, lyrics, audio
                            description...
                        </li>
                    </ul>
                </>
            ),
            search: "Recherche Music-Story",
            artist: "Artiste",
            album: "Album",
            type: {
                live: "Live",
                remix: "Remix",
                compilation: "Compilation",
                karaoke: "Karaoke",
                ring: "Sonnerie",
                other: "Autre",
                original: "Original",
            },
            format: {
                album: "Album",
                ep: "EP",
                single: "Single",
                mixtape: "Mixtape",
                mcd: "Maxi",
            },
            recording: "Recording",
            has_credits: "Crédits",
            has_lyric: "Parole",
            has_audio: "Audio",
            more: "Voir plus",
            empty: "Aucun résultat",
        },
        matching: {
            status: {
                not_found: "Introuvable",
                matched: "Matché",
                mixed: "Discographie mélangée",
                duplicates: "Doublons",
                unmatched: "Non matché",
            },
        },
        partners: {
            "7D": "7Digital",
            AZ: "Amazon",
            DG: "Discogs",
            DZ: "Deezer",
            IT: "iTunes",
            MB: "MusicBrainz",
            NA: "Napster",
            PR: "PriceMinister",
            QB: "Qobuz",
            UN: "Universal",
            SP: "Spotify",
            CJ: "CD Journal",
            LM: "LineMusic",
        },
        apiDownloadButton: {
            pending: "Création du fichier en cours",
            success: "Le fichier a été créé",
        },
        editMatchingsTable: {
            empty: "Aucun candidat",
            id: "ID",
            name: "Nom",
            type: {
                title: "Type",
                discarded: "Discarded",
                valid: "Validé manuellement",
                invalid: "Invalidé manuellement",
                matchedByName: "Matché par nom",
                matchedByDiscography: "Matché par discographie",
            },
            representativity: "Repr.",
            actions: {
                validate: "Valider le matching",
                cancelValidation: "Annuler la validation manuelle",
                invalidate: "Invalider le matching",
                cancelInvalidation: "Annuler l'invalidation manuelle",
            },
        },
        editMatchingsModal: {
            status: "Statut :",
            matched: "Matché",
            not_found: "Non Matché",
        },
        pictureCheckButton: {
            title: "Check",
            confirmModal: {
                title: "Êtes-vous sûr ?",
                text: "De vouloir valider les photos de cet artiste ?",
            },
            commentModal: {
                title: "Ajouter un commentaire",
                success: "Commentaire ajouté",
                error: (message) =>
                    `Erreur lors de l'ajout du commentaire: ${message}`,
            },
        },
        pictureTagsModal: {
            title: "Mise à jour des tags de l'image originale",
            titleVersion: "Mise à jour des tags de l'image redimensionnée",
            metas: "Metas",
            tags: "Tags",
            description: {
                2: () => (
                    <>
                        <strong>Intérieur:</strong> Photo prise intérieur qui
                        n'entre pas dans les autres catégories
                        <br />
                        <strong>Extérieur:</strong> Photo prise en extérieur qui
                        n'entre pas dans les autres catégories
                        <br />
                        <strong>Scène:</strong> Photo prise sur scène lors d'un
                        spectacle
                    </>
                ),
                7: () => (
                    <>
                        <strong>1:</strong> Image size greater than or equal to
                        2500px.
                        <br />
                        <strong>2:</strong> Image size between 1500px (included)
                        and 2500px (excluded).
                        <br />
                        <strong>3:</strong> Image size between 800px (included)
                        and 1500px (excluded).
                    </>
                ),
                4: () => (
                    <>
                        Date à laquelle la photo a été prise. Comme il est
                        difficile de connaître précisément la date, nous l'avons
                        définie comme une période de 5 ans. L'objectif est de
                        situer la photo dans le temps afin de savoir à quel
                        moment de la carrière de l'artiste elle a été prise. Un
                        champ <strong>“date de shoot”</strong> a également été
                        mis en place, à remplir si vous connaissez la date
                        précise de la prise de la photo. Pour les images Getty,
                        vérifiez dans les "Meta" de l'image s'il est mentionné.
                    </>
                ),
                5: () => (
                    <>
                        <strong>Individuel:</strong> Pour un artiste solo ou
                        lorsque l'image ne représente qu'une seule personne
                        d'une marque.
                        <br />
                        <strong>Groupe:</strong> Plusieurs personnes sur la
                        photo mais pas un groupe complet
                        <br />
                        <strong>Groupe complet:</strong> Un groupe complet est
                        représenté sur l'image
                    </>
                ),
                6: () => (
                    <>
                        <strong>Portrait:</strong> Des cheveux aux épaules
                        <br />
                        <strong>Plan taille:</strong> Des cheveux à la taille
                        <br />
                        <strong>Plan pied:</strong> Tout le corps est représenté
                    </>
                ),
            },
            shotDate: "Date de shoot",
            msArtists: "Artistes MS",
            msArtistsPlaceholder: "Sélectionner un artiste",
            open_tags: {
                title: "Tags libres",
                placeholder: "Entrer des tags libres",
            },
            tags_getty: "Tags Getty",
            success: "Tags mis à jour",
            alert: {
                title: "Êtes-vous sûr ?",
                text: "De vouloir fermer cette fenêtre ? Les informations renseignées seront perdues.",
            },
            upload: "Upload",
            legal: "Juridique",
            cropping: "Cadrage",
            tagging: "Tags",
            warning:
                "Les prédictions générées par l'intelligence artificielle ont reçu une note basse; veuillez vérifier les données renseignées.",
        },
        pictureCropModal: {
            head: "Redimensionnement de l'image",
            width: "Largeur",
            height: "Hauteur",
            rotateLeft: "Rotation gauche",
            rotateRight: "Rotation droite",
            success: "Redimensionnement avec succès",
        },
        pictureMoveModal: {
            title: "Déplacer l'image",
            artist: "Artiste",
            success: "Image a été déplacée",
        },
        multiRecordingSelect: {
            globalSearch: "Chercher dans toutes les discographies",
        },
        imageUploadModal: {
            head: "Ajout d'une image",
            link: "Ajouter un lien ici",
            browse: "Parcourir",
            file: "Cliquez pour choisir une image sur votre ordinateur",
            success: "L'image a été ajoutée",
            alert: {
                title: "Êtes-vous sûr ?",
                text: "De vouloir fermer cette fenêtre ? Les informations renseignées seront perdues.",
            },
            upload: "Upload",
            legal: "Juridique",
            cropping: "Cadrage",
            tagging: "Tags",
            warning_upscale:
                "L'image sera agrandie au moment de l'upload. Le processus peut prendre quelques secondes. Veuillez revérifier la qualité de l'image à l'étape suivante.",
            warning_downscale:
                "L'image sera réduite au moment de l'upload. Le processus peut prendre quelques secondes. Veuillez revérifier la qualité de l'image à l'étape suivante.",
        },
        pictureInformationModal: {
            head: "Information de l'image",
            source: "Source",
            source_url: "URL Source",
            author: "Photographe",
            copyright: "Copyright notice",
            year: "Année",
            copyright_owner: "Propriétaire du copyright",
            license: "Licence",
            complementary: "Complément",
            artist_site: "Site web de l'artiste",
            artist_label: "Label de l'artiste",
            other: "Autre",
            description: {
                source: "Source de l'image, Site web d'où vous téléchargez l'image.",
                source_url: "URL de l'image uploadée.",
                author: "Auteur de l'image.",
                copyright:
                    "Texte légal à afficher par quiconque utilisant notre image.",
                year: "L'année de publication de l'image.",
                copyright_owner:
                    "Le propriétaire des droits patrimoniaux de l'image. Uniquement si différent du photographe.",
                complementary:
                    "Informations supplémentaires sur l'image telles que les URLs d'autres sites web où l'image est publiée.",
            },
            success: "Les informations de l'image ont été enregistrées",
            alert: {
                title: "Êtes-vous sûr ?",
                text: "De vouloir fermer cette fenêtre ? Les informations renseignées seront perdues.",
            },
            upload: "Upload",
            legal: "Juridique",
            cropping: "Cadrage",
            tagging: "Tags",
            licenseInformation: {
                head: "Détails des licences",
                1: {
                    title: "GNU/FDL",
                    body: "La licence GNU / FDL (Free Documentation Licence) autorise quiconque à modifier, copier et distribuer le document pour peu qu’il soit republié sous cette licence. On prendra soin d’indiquer quoi qu’il en soit la source (URL de la page où l’on a trouvé le document) et le type de licence. En fait cette licence était destinée à l’origine aux manuels d’utilisation des logiciels libres distribués sous licence GNU/GPL et il fallait y inclure toujours cette licence avec un historique des modifications, les noms des auteurs etc… Avec le temps, cette licence a été étendue aux autres documents (images, sons…) avec lesquels on ne trouve pas toujours une copie de la licence (qui doit absolument être la version anglaise, c’est ce qui est spécifié dans cette même licence !). Pour ce qui nous concerne, si nous utilisons une image sous licence GNU/FDL en ajoutant le type de licence, l’auteur et la source, nous aurons déjà bien fait notre travail.",
                },
                2: {
                    title: "CC-BY",
                    body: "Creative Commons: Paternité (BY): Le titulaire des droits autorise toute exploitation de l’œuvre, y compris à des fins commerciales, ainsi que la création d’œuvres dérivées, dont la distribution est également autorisé sans restriction, à condition de l’attribuer à son l’auteur en citant son nom. Cette licence est recommandée pour la diffusion et l’utilisation maximale des œuvres.",
                },
                3: {
                    title: "CC-BY-SA",
                    body: "Creative Commons: Paternité + Partage dans les mêmes conditions (BY SA) : Le titulaire des droits autorise toute utilisation de l’œuvre originale (y compris à des fins commerciales) ainsi que la création d’œuvres dérivées, à condition qu’elles soient distribuées sous une licence identique à celle qui régit l’œuvre originale. Cette licence est souvent comparée aux licences « copyleft » des logiciels libres. C’est la licence utilisée par Wikipedia.",
                },
                4: {
                    title: "CC-BY-ND",
                    body: "Creative Commons: Paternité + Pas de Modification (BY ND) : Le titulaire des droits autorise toute utilisation de l’œuvre originale (y compris à des fins commerciales), mais n’autorise pas la création d’œuvres dérivées.",
                },
                5: {
                    title: "CC-BY-NC",
                    body: "Creative Commons: Paternité + Pas d’Utilisation Commerciale (BY NC) : le titulaire des droits autorise l’exploitation de l’œuvre, ainsi que la création d’œuvres dérivées, à condition qu’il ne s’agisse pas d’une utilisation commerciale (les utilisations commerciales restant soumises à son autorisation).",
                },
                6: {
                    title: "CC-BY-NC-SA",
                    body: "Creative Commons: Paternité + Pas d’Utilisation Commerciale + Partage dans les mêmes conditions (BY NC SA): Le titulaire des droits autorise l’exploitation de l’œuvre originale à des fins non commerciales, ainsi que la création d’œuvres dérivées, à condition qu’elles soient distribuées sous une licence identique à celle qui régit l’œuvre originale.",
                },
                7: {
                    title: "CC-BY-NC-ND",
                    body: "Creative Commons: Paternité + Pas d’Utilisation Commerciale + Pas de Modification (BY NC ND) : Le titulaire des droits autorise l’utilisation de l’œuvre originale à des fins non commerciales, mais n’autorise pas la création d’œuvres dérivés.",
                },
                8: {
                    title: "all-rights-reserved",
                    body: "Tous droits réservés.",
                },
                9: {
                    title: "royalty-free",
                    body: "Libre de droits",
                },
                10: {
                    title: "Public Domain",
                    body: "Le domaine public est l'absence de droits d'auteur, ce qui signifie que l'œuvre donnée n'est pas soumise à des droits d'auteur ou à d'autres restrictions légales.",
                },
                11: {
                    title: "CC0",
                    body: "CC0 (aka CC Zero) est un outil publique qui permet aux créateurs de renoncer à leurs droits d'auteur et de placer leurs œuvres dans le domaine public mondial. CC0 permet aux réutilisateurs de distribuer, de remixer, d'adapter et d'exploiter le matériel sur n'importe quel support ou dans n'importe quel format, sans aucune condition.",
                },
            },
        },
        pictureInformationModalGetty: {
            upload: "Upload",
            legal: "Juridique",
            cropping: "Cadrage",
            tagging: "Tags",
            source: "Source",
            source_url: "Source URL",
            author: "Photographe",
            copyright: "Copyright notice",
            year: "Année",
            copyright_owner: "Propriétaire du copyright",
            license: "Licence",
            complementary: "Complément",
            description: {
                source: "Source de l'image, Site web d'où vous téléchargez l'image.",
                source_url: "URL de l'image uploadée.",
                author: "Auteur de l'image.",
                copyright:
                    "Texte légal à afficher par quiconque utilisant notre image.",
                year: "L'année de publication de l'image.",
                copyright_owner:
                    "Le propriétaire des droits patrimoniaux de l'image. Uniquement si différent du photographe.",
                complementary:
                    "Informations supplémentaires sur l'image telles que les détails de la collection et utilisation autorisée.",
            },
        },
        imageCropModal: {
            head: "Redimensionnement de l'image",
            width: "Largeur",
            height: "Hauteur",
            rotateLeft: "Rotation gauche",
            rotateRight: "Rotation droite",
            success: "Redimensionnement avec succès",
            alert: {
                title: "Êtes-vous sûr ?",
                text: "De vouloir fermer cette fenêtre ? Les informations renseignées seront perdues.",
            },
            upload: "Upload",
            legal: "Juridique",
            cropping: "Cadrage",
            tagging: "Tags",
        },
        labelUploadModal: {
            head: "Ajout d'une image artiste",
            link: "Ajouter un lien ici",
            browse: "Parcourir",
            file: "Cliquez pour choisir une image sur votre ordinateur",
            title: "Title",
            copyright: "Copyright",
            takedown: "Date d'expiration",
            label: "Label",
            success: "L'image a été ajoutée",
        },
        labelInformationModal: {
            head: "Information de l'image",
            title: "Title",
            copyright: "Copyright",
            takedown: "Date d'expiration",
            success: "Les informations de l'image ont été enregistrées",
        },
        charts: {
            categoryList: {
                empty: "Aucun Résultats",
                head: "Categorie",
                title: "Titre",
                source: "Source",
                area: "Continent",
                country: "Pays",
                main: "Principal",
                format: "Format",
                link: "Lien",
            },
            chartsList: {
                empty: "Aucun Résultats",
                head: "Liste des charts",
                year: "Année",
                month: "Mois",
                startDate: "Date début",
                endDate: "Date fin",
                dataMatched: "Matchés Data",
                matched: "Matchés",
                translated: "Traduits",
                userData: "A faire",
            },
            charts: {
                empty: "Aucun Résultats",
                head: "Charts",
                rank: "Rang",
                artist: "Artiste",
                album: "Album",
                track: "Track",
                previousRank: "Rang précèdent",
                dateIn: "Date d'entrée",
                dateOut: "Date de sortie",
                nbWeek: "Nb Semaines",
                rankIn: "Rang d'entrée",
                maxRank: "Rang max",
                rankOut: "Rang de sortie",
                msArtist: "Artiste MS",
                msAlbum: "Album MS",
                msRecordings: "Recordings MS",
                informations: "Informations",
                hasBioFR: "Présence Bio FR",
                hasBioEN: "Présence Bio EN",
                hasBioDE: "Présence Bio DE",
                hasBioJA: "Présence Bio JA",
                hasBioES: "Présence Bio ES",
                hasBioPT: "Présence Bio PT",
                hasPictures: "Présence Photo avec droits",
                hasCuration: "Présence Photo de curation",
                hasReview: "Présence Review",
                lastUpdated: "Dernière mise au planning",
                nbRelated: "Nb Associés",
                albumsWithoutGenre: "Albums Sans Genre",
                discarded: "Ecarté",
                matched: "Matché",
                translated: "Traduit",
                creation: "Création",
                filter: {
                    matched: {
                        placeholder: "Matché",
                        true: "Matché",
                        false: "Non matché",
                    },
                    newEntry: {
                        placeholder: "Nouvelle entrée",
                        true: "Nouvelle entrée",
                        false: "Ancienne entrée",
                    },
                    toBeTreated: {
                        placeholder: "À traiter",
                        true: "À traiter",
                    },
                    discarded: {
                        placeholder: "Écarté",
                        true: "Écarté",
                        false: "Non écarté",
                    },
                },
                albumTranslationsForm: {
                    artist: "Artiste",
                    album: "Album",
                    translatedTitle: "Titre traduit",
                    translatedName: "Nom traduit",
                    multipleNamesWarning:
                        "L'artiste matchés a plusieurs noms traduits. " +
                        "Les traductions suivantes vont être écrasées lors de l'enregistrement:",
                    save: {
                        success: "Les traductions ont été enregistrées",
                        error: (message) =>
                            `Erreur lors de l'enregistrement des traductions : ${message}`,
                        tooltip: "Enregistrer les traductions",
                    },
                    reset: "Réinitialiser le formulaire",
                },
                trackTranslationsForm: {
                    artist: "Artiste",
                    albums: "Albums",
                    recordings: "Recordings",
                    albumFormat: {
                        album: "Album",
                        ep: "EP",
                        single: "Single",
                        mixtape: "Mixtape",
                        mcd: "Maxi",
                    },
                    albumType: {
                        live: "Live",
                        remix: "Remix",
                        compilation: "Compilation",
                        karaoke: "Karaoke",
                        ring: "Sonnerie",
                        other: "Autre",
                        original: "Original",
                    },
                    translatedTitle: "Titre traduit",
                    translatedSubtitle: "Sous-titre traduit",
                    translatedName: "Nom traduit",
                    multipleNamesWarning:
                        "L'artiste matchés a plusieurs noms traduits. " +
                        "Les traductions suivantes vont être écrasées lors de l'enregistrement:",
                    multipleTitlesWarning:
                        "Tous les recordings matchés n'ont pas la même traduction. " +
                        "Veuillez vérifier que les matchings sont corrects. " +
                        "Les traductions suivantes vont être écrasées lors de l'enregistrement:",
                    noTitle: "Pas de titre",
                    noSubtitle: "Pas de sous-titre",
                    noTranslation: "Pas de traduction",
                    save: {
                        success: "Les traductions ont été enregistrées",
                        error: (message) =>
                            `Erreur lors de l'enregistrement des traductions : ${message}`,
                        tooltip: "Enregistrer les traductions",
                    },
                    reset: "Réinitialiser le formulaire",
                },
                discardButton: {
                    discardTooltip: "Ecarter",
                    recoverTooltip: "Récupérer",
                    modalTitle: "Commentaire",
                },
                addToPlanningButton: {
                    title: "Ajouter au planning",
                },
            },
        },
        picture: {
            action: {
                add: "Ajouter une image",
                edit: "Modifier les informations de l'image",
                tags: "Modifier les tags de l'image originale",
                tagsVersion: "Modifier les tags de l'image redimensionnée",
                crop: "Redimensionner l'image",
                move: "Déplacer l'image",
                del: {
                    title: "Supprimer l'image",
                    alert: {
                        title: "Êtes-vous sûr ?",
                        text: "De vouloir supprimer cette image ?",
                    },
                    success: "Image supprimée",
                    error: (message) =>
                        `Erreur lors de la suppression de l'image: ${message}`,
                },
            },
            getty: {
                bas: {
                    head: "Bac à sable",
                    empty: "Aucune image présente dans le bac à sable",
                    width: "Largeur",
                    height: "Hauteur",
                    created_at: "Date de création",
                    loading: "Chargement...",
                    more: "En voir plus",
                    filters: {
                        search_type: {
                            title: "Type de recherche",
                            best_match: "Meilleures correspondances",
                            most_popular: "Plus populaires",
                        },
                    },
                    action: {
                        detail: {
                            head: "Détail d'une image du Bac à Sable",
                            dimensions: "dimensions",
                            id: "id",
                            title: "title",
                            collection_name: "collection_name",
                            caption: "caption",
                            people: "people",
                            date_camera_shot: "date_camera_shot",
                            date_created: "date_created",
                            date_submitted: "date_submitted",
                            success: "Image ajoutée",
                            error: (message) =>
                                `Erreur lors de l'ajout de l'image: ${message}`,
                        },
                    },
                },
            },
        },
        discardButton: {
            discard: "Écarter",
            recover: "Rétablir",
            commentTitle: "Raison : ",
            head: "Écarter",
            comment: "Raison",
            submit: "Écarter",
        },
    },
    utils: {
        select: {
            empty: "Aucun résultats",
            placeholder: "Rechercher...",
            loading: "Chargement...",
            noOptions: "Aucune option",
            create: "Créer",
        },
        copy: {
            success: "Le texte a été copié dans le presse papiers",
            error: "Impossible de copier le texte dans le presse papiers",
        },
        pagination: {
            total: ({ size }) => `${size} Résultats`,
            first_page: "Première page",
            last_page: "Dernière page",
            previous_page: "Page précédente",
            next_page: "Page suivante",
        },
        form: {
            required: "Ce champ est requis",
            number: "Ce champ n'est pas un nombre",
            date_placeholder: "YYYY-MM-DD",
            date_format: "Le format est invalide (YYYY-MM-DD)",
            date_zero: "La date '0000-00-00' est invalide",
            barcode_format:
                "Le code-barre doit être un nombre à 12 ou 13 chiffres",
            isrc_format: "Format ISRC invalide (AANNN0000000)",
            duration_format: "Le format de durée est invalide (hh:mm:ss)",
            duration_placeholder: "hh:mm:ss",
            year_format: "Le format d'année est invalide (YYYY)",
            end_date_greater_than_start:
                "La date de fin doit être supérieure ou égale à la date de début",
            url: "L'url n'est pas valide",
            wysiwyg: {
                placeholder: "Ecrivez ici...",
                bold: "Gras",
                italic: "Italique",
                underline: "Souligner",
                strikethrough: "Barré",
            },
            email: "L'email n'est pas valide",
            isni_format:
                "L'ISNI doit être un code à 16 chiffres, ou un code à 15 chiffres suivi de la lettre X",
            upload_max_size: `Le fichier est trop volumineux. (Max: ${
                UPLOAD_MAX_SIZE / 1000000
            } Mo)`,
            must_contain_uppercase: "Doit contenir au moins une majuscule",
            must_contain_lowercase: "Doit contenir au moins une minuscule",
            must_contain_8_characters: "Doit faire 8 caractères ou plus",
            must_contain_digit: "Doit contenir au moins un chiffre",
            must_contain_letter: "Doit contenir au moins une lettre",
            passwords_must_match: "Les mots de passe ne correspondent pas",
            not_hit_discovery_at_same_time:
                "Les champs Hit et Découverte ne peuvent pas être rempli simultanément",
        },
        button: {
            cancel: "Annuler",
            confirm: "Confirmer",
            create: "Créer",
            back: "Retour",
            add: "Ajouter",
            edit: "Modifier",
            delete: "Supprimer",
            deleteAll: "Tous supprimer",
            validate: "Valider",
            previous: "Précédent",
            next: "Suivant",
            now: "Maintenant",
            save: "Enregistrer",
            close: "Fermer",
            transfer: "Transférer",
            reset: "Réinitialiser",
            approve: "Approuver",
            reject: "Rejeter",
            export: "Exporter",
            generate: "Générer",
            upload: "Uploader",
            search: "Rechercher",
            merge: "Fusionner",
            continue: "Continuer",
            download: "Télécharger",
            browse: "Parcourir",
            login: "Connexion",
            translate: "Traduire",
        },
        sweetalert: {
            warning: "Attention",
            error: "Erreur",
            cancel: "Non, j'annule",
            close: "Fermer",
            confirm: "Oui, je confirme",
            ok: "Ok, j'ai compris",
        },
        filters: {
            all: "tous",
            none: "aucun",
        },
        lang: {
            PT: "Portugais",
            JA: "Japonais",
            DE: "Allemand",
            EN: "Anglais",
            ES: "Espagnol",
            FR: "Français",
            KO: "Coréen",
            CD_JOURNAL: "Japonais",
        },
        nth: (n) => (
            <>
                {n?.toLocaleString("fr")}
                <sup>{n === 1 ? "er" : "ème"}</sup>
            </>
        ),
    },
    landing: {
        breadcrumb: "Page d'accueil",
        industry: "Statistiques marchés",
        ms: "Statistiques base de données",
        industryStats: {
            chart_title: (top) => `À partir du top ${top ?? ""} officiel - `,
            chart_date: ({ start_date, end_date }) =>
                ` (Du ${start_date ?? ""} au ${end_date ?? ""})`,
            top_title: (country) =>
                `Titres les plus populaires actuellement - ${country ?? ""}`,
            top_title_description:
                "Les titres les plus populaires du pays sélectionné pour le mois en cours.",
            top_artist: (country) =>
                `Artistes les plus populaires actuellement - ${country ?? ""}`,
            top_artist_description:
                "Les artistes les plus populaires du pays sélectionné pour le mois en cours.",
            top_progression: ({ id_country, country }) =>
                id_country == 33
                    ? `Meilleures entrées de la journée - ${country ?? ""}`
                    : `Meilleures entrées de la semaine - ${country ?? ""}`,
            top_progression_description: (id_country) =>
                id_country == 33
                    ? "Les titres faisant les meilleures entrées dans le chart officiel du jour précédent."
                    : "Les titres faisant les meilleures entrées dans le chart officiel de la semaine précédente.",
            sexe_repartition: (country) =>
                `Répartition des artistes par type - ${country ?? ""}`,
            sexe_repartition_description:
                "Représentation des artistes de type “groupe”, “solo” (et par sexe pour les artistes solo) dans le chart officiel du selectionné.",
            genre_repartition: (country) =>
                `Genres musicaux représentés - ${country ?? ""}`,
            genre_repartition_description:
                "Répartition des genres musicaux représentés dans le chart officiel du pays sélectionné",
            language_repartition: (country) =>
                `Langues chantées - ${country ?? ""}`,
            language_repartition_description:
                "Répartition des langues chantées dans le chart officiel du pays sélectionné.",
            top_emerging: (country) => `Artistes émergents - ${country ?? ""}`,
            top_emerging_description:
                "Les artistes les plus populaires qui ont fait leur première apparition dans les charts ces 3 derniers mois.",
            current_catalog: (country) =>
                `Répartition des titres par typologie d’ancienneté - ${
                    country ?? ""
                }`,
            current_catalog_description:
                "Représentation des titres “Actuels” (date de sortie < 18 mois), et “Catalogues” (date de sortie > 18 mois) dans le chart officiel du pays sélectionné.",
            no_data: "Non renseigné",
            other: "Autre",
            gold: "Catalogue",
            current: "Actuelle",
            person: "Personne",
            band: "Groupe",
            man: "Homme",
            woman: "Femme",
            top_3: "Top 3:",
            total: "Total: ",
        },
        msStats: {
            artists: {
                title: "Artistes",
                artists_with_photo: "Artistes avec image(s)",
                artists_with_bio: "Artistes avec biographie(s)",
                biographies_description: "Biographies disponibles en :",
            },
            albums: {
                title: "Albums",
                canonical_albums: "Albums canoniques",
                albums_with_cover_art: "Albums avec pochette",
                releases: "Releases",
            },
            recordings: {
                title: "Recordings",
                unique_recording: "Recording unique",
            },
        },
    },
};

import React, { useState, useEffect, useCallback } from "react";
import ReviewGallery from "./ReviewGallery";
import { useParams } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";

const defaultState = {
    review: [],
    isLoading: true,
};

const EnhancedReviewGallery = ({ setNoData, ...props }) => {
    const { api } = useAPI();
    const { albumId } = useIntParams();
    const { locale } = useParams();
    const [state, setState] = useState(defaultState);

    const getReviewGallery = useCallback(() => {
        setState((prev) => ({ ...prev, isLoading: true }));
        return api
            .get(`album/${albumId}/review`, { locale })
            .then((response) => {
                setState((prev) => ({
                    ...prev,
                    review: response,
                }));
                if (response.length === 0) {
                    setNoData("reviewGallery");
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [albumId, locale]);

    useEffect(() => {
        const init = async () => {
            await Promise.all([getReviewGallery()]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getReviewGallery]);

    return <ReviewGallery {...props} {...state} locale={locale} />;
};

export default EnhancedReviewGallery;

import React, { useState } from "react";
import { useLocation } from "react-router";
import { useForm } from "react-hook-form";
import DiscardButton from "./DiscardButton";

const EnhancedDiscardButton = ({ comment, onDiscard, ...props }) => {
    const location = useLocation();
    const [show, setShow] = useState(false);

    const {
        handleSubmit,
        register,
        reset,
        formState: { isSubmitting },
    } = useForm({
        defaultValues: {
            comment: "",
        },
    });

    const onShow = () => {
        reset({ comment });
        setShow(true);
    };
    const onHide = () => setShow(false);

    return (
        <DiscardButton
            comment={comment}
            register={register}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit(({ comment }) =>
                onDiscard(comment).then(onHide)
            )}
            show={show}
            onShow={onShow}
            onHide={onHide}
            location={location}
            {...props}
        />
    );
};

export default EnhancedDiscardButton;

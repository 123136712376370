import React, { useState, useCallback, useEffect } from "react";
import I18n from "shared/lib/I18n";
import { useParams } from "react-router-dom";
import useIntParams from "shared/hooks/useIntParams";
import Review from "./Review";
import { LANGS } from "./Review.constants";
import FlagIcon from "shared/functions/FlagIcon";
import useAPI from "shared/hooks/useApi";

const EnhancedReview = (props) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const { albumId } = useIntParams();
    const [covers, setCovers] = useState({
        musicstory: {
            url: "http://images.music-story.com/img/jaquette-defaut-album-400.jpg",
            origin: "Musicstory",
        },
        partners: [],
    });

    const otherLangs = ["PT", "ES"];
    const langs = {
        ja: ["JA", "EN", "FR", "DE", ...otherLangs],
        en: ["EN", "FR", "JA", "DE", ...otherLangs],
        fr: ["FR", "EN", "JA", "DE", ...otherLangs],
        de: ["DE", "EN", "FR", "JA", ...otherLangs],
        ko: ["EN", "FR", "JA", "DE", ...otherLangs],
    };

    const [langOptions, setLangOptions] = useState([]);

    const fetchReviewLangOptions = () => {
        api.get(`album/${albumId}/review/lang_options`, {
            langs: langs[locale].join(";"),
        })
            .then((response) => {
                setLangOptions(response);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        if (albumId && locale) {
            fetchReviewLangOptions();
        }
    }, [albumId, locale]);

    const reviewLangOptions = useCallback(
        (lang) => {
            if (!langOptions[lang]) {
                return [];
            }

            return langOptions[lang].map((value) => ({
                label: (
                    <>
                        <FlagIcon code={LANGS[value]} className="mr-1" />
                        <I18n t={`artist.management.bio.head.${value}`} />
                    </>
                ),
                value,
            }));
        },
        [langOptions]
    );

    return (
        <Review
            {...props}
            locale={locale}
            albumId={albumId}
            langs={langs}
            covers={covers}
            setCovers={setCovers}
            reviewLangOptions={reviewLangOptions}
            fetchReviewLangOptions={fetchReviewLangOptions}
        />
    );
};

export default EnhancedReview;

import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import { Tabs, Tab } from "react-bootstrap";
import Data from "./Data";
import "./ReviewGallery.css";

const ReviewGallery = ({ review, isLoading, locale }) => {
    return (
        <Widget
            className="artist-view-biography-gallery py-0"
            isLoading={isLoading}
        >
            <Widget.Body className="widget-table-overflow mb-0">
                {review?.length > 0 ? (
                    <Tabs
                        defaultActiveKey={
                            review.some(
                                (rev) => rev.id_lang === locale.toUpperCase()
                            )
                                ? locale.toUpperCase()
                                : review[0].id_lang
                        }
                        mountOnEnter
                    >
                        {review.map((rev) => (
                            <Tab
                                key={rev.id_lang}
                                eventKey={rev.id_lang}
                                title={<I18n t={`utils.lang.${rev.id_lang}`} />}
                            >
                                <Data review={rev} id_lang={rev.id_lang} />
                            </Tab>
                        ))}
                    </Tabs>
                ) : (
                    <h2 className="text-center py-4">
                        <I18n t="album.view.reviews.empty" />
                    </h2>
                )}
            </Widget.Body>
        </Widget>
    );
};

export default ReviewGallery;

import React from "react";
import Rev from "./Rev";
import Album from "./Albums";

const Review = ({
    langs,
    locale,
    albumId,
    reviewLangOptions,
    fetchReviewLangOptions,
}) => {
    return (
        <div className="row">
            <div className="col">
                <Album />
            </div>
            <div className="col">
                {albumId &&
                    langs[locale].map((lang) => (
                        <Rev
                            key={lang}
                            lang={lang}
                            albumId={albumId}
                            reviewLangOptions={reviewLangOptions}
                            fetchReviewLangOptions={fetchReviewLangOptions}
                        />
                    ))}
            </div>
        </div>
    );
};

export default Review;

import React, { useState, useEffect, useCallback } from "react";
import useAPI from "shared/hooks/useApi";
import { VARIOUS_ARTIST_ID } from "shared/constants/cms/artist";
import Albums from "./Albums";
import { toast } from "react-toastify";
import { useLocation, useParams, useNavigate } from "react-router";
import I18n from "shared/lib/I18n";
import {
    onColumnMatchNormalized,
    normalizeInteger,
    normalizeTitle,
} from "shared/functions/normalize";

const EnhancedAlbums = (props) => {
    const [albums, setAlbums] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { api } = useAPI();
    const { selectedTab, artistId, albumId, locale } = useParams();
    const isVarious = artistId == VARIOUS_ARTIST_ID;
    const [currentSearch, setCurrentSearch] = useState();

    const getAlbums = useCallback(
        (searchText = "") => {
            if (artistId) {
                setIsLoading(true);
                api.get(`artist/${artistId}/albums`, {
                    locale,
                    search: searchText,
                })
                    .then((response) => {
                        setIsLoading(false);
                        setAlbums(response);
                    })
                    .catch((error) => {
                        console.error(error);
                        toast.error(error.message);
                        setIsLoading(false);
                    });
            }
        },
        [artistId, locale]
    );
    useEffect(getAlbums, [getAlbums]);

    const handleSearch = useCallback(
        (type, { searchText }) => {
            if (
                type === "search" &&
                searchText &&
                searchText !== currentSearch
            ) {
                setCurrentSearch(searchText);
                getAlbums(searchText);
            }
        },
        [currentSearch]
    );

    const columns = [
        {
            dataField: "has_review",
            sort: true,
            text: "",
            headerStyle: { width: "calc(25px + 1rem)" },
            formatter: (cell) => (
                <div
                    className={`mx-auto my-auto badge badge-${
                        cell ? "success" : "secondary"
                    }`}
                    style={{
                        display: "block",
                        width: "10px",
                        height: "10px",
                        borderRadius: "10px",
                    }}
                ></div>
            ),
        },
        {
            dataField: "id",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.albums.id"
            ),
            sort: true,
            title: true,
            headerStyle: { width: "4.7em" },
            filterValue: normalizeInteger,
        },
        {
            dataField: "cover",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.albums.cover.head"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "artist.management.disco.albums.cover.title"
                ),
            searchable: false,
            formatter: (cell) => (
                <div>
                    {cell ? (
                        <a href={cell} target="_blank">
                            <img
                                src={cell}
                                style={{
                                    width: "40px",
                                    height: "40px",
                                    objectFit: "contain",
                                }}
                            />
                        </a>
                    ) : (
                        "-"
                    )}
                </div>
            ),
            style: {
                height: "calc(40px + .8rem)",
            },
            headerStyle: { width: "calc(40px + .8rem)" },
        },
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.albums.title"
            ),
            sort: true,
            title: true,
            headerStyle: { width: "100%" },
            filterValue: normalizeTitle,
        },
        {
            dataField: "type",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.albums.type.head"
            ),
            formatter: (cell) =>
                I18n.getTranslation(
                    location,
                    `artist.management.disco.albums.type.${cell?.toLowerCase()}`
                ) ?? cell,
            searchable: false,
            sort: true,
            title: true,
            headerStyle: { width: "5em" },
        },
        {
            dataField: "format",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.albums.format.head"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "artist.management.disco.albums.format.title"
                ),
            formatter: (cell) =>
                I18n.getTranslation(
                    location,
                    `artist.management.disco.albums.format.${cell?.toLowerCase()}`
                ) ?? cell,
            searchable: false,
            sort: true,
            title: true,
            headerStyle: { width: "4.5em" },
        },
        {
            dataField: "genre",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.albums.genre"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "artist.management.disco.albums.genre"
                ),
            formatter: (cell) => {
                return cell ?? "-";
            },
            searchable: false,
            sort: true,
            title: true,
            headerStyle: { width: "50%" },
        },
        {
            dataField: "release_date",
            text: I18n.getTranslation(
                location,
                "artist.management.disco.albums.release_date.head"
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "artist.management.disco.albums.release_date.title"
                ),
            searchable: false,
            formatter: (cell) => {
                return cell ?? "-";
            },
            sort: true,
            title: true,
            headerStyle: { width: "6em" },
        },
    ];

    const selectRow = {
        mode: "radio",
        clickToSelect: true,
        classes: "table-info",
        hideSelectColumn: true,
        onSelect: (row, isSelect) => {
            if (isSelect) {
                navigate(
                    `/${locale}/artist/${artistId}/edit/${selectedTab}/album/${row.id}`
                );
            }
        },
        selected: [parseInt(albumId, 10)],
    };

    const defaultSorted = [
        {
            dataField: "release_date",
            order: "desc",
        },
    ];

    return (
        <Albums
            {...props}
            albums={albums}
            isVarious={isVarious}
            handleSearch={handleSearch}
            columns={columns}
            isLoading={isLoading}
            selectRow={selectRow}
            defaultSorted={defaultSorted}
            onColumnMatch={onColumnMatchNormalized}
        />
    );
};

export default EnhancedAlbums;
